@import '../../../theme/newVariables';

.footerBottomContainer {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: $az-spacing-s $az-spacing-zero;

  @media #{$extra-large-and-down} {
    margin-bottom: $az-spacing-100;
  }

  @media #{$small-and-down} {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    margin-top: $az-spacing-zero;
  }
}

.labelLinksContainer {
  display: flex;
  flex-wrap: wrap;

  @media #{$small-and-down} {
    display: inline;
    text-align: center;
    width: 100%;
    margin-top: $az-spacing-xs;
    margin-bottom: $az-spacing-xs;

    a {
      margin-top: $az-spacing-4xs;
    }
  }
}

.copyrightContainer {
  @extend .labelLinkItem;

  @media #{$small-and-down} {
    text-align: center;
    align-self: center;
  }
}

.smallScreenDigicertContainer {
  display: flex;
  justify-content: center;
}

.mediumAndUpDigicertContainer {
  align-self: center;
  margin-right: $az-spacing-s;

  div {
    width: $az-spacing-50;
  }

  @media #{$medium-and-down} {
    align-self: flex-start;
  }
}

.labelLinkItem {
  font-family: $font-az-helvetica;
  font-size: $font-size-12;
  line-height: $az-spacing-s;
  color: $az-grey-3;
  margin-right: $az-spacing-xxxs;

  @media #{$small-and-down} {
    margin-right: $az-spacing-xxs;
  }
}

.appStoreContainer {
  justify-self: end;
  align-self: center;

  @media #{$medium-only} {
    align-self: flex-start;
  }
}

.appStoreStyle {
  margin-left: $az-spacing-xxxs;

  &:lang(en) {
    @media #{$small-and-down} {
      margin-left: $az-spacing-zero;
    }
  }
}

.mobileSocialIconsContainer {
  display: none;

  @media #{$small-and-down} {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    margin-top: $az-spacing-s;
    margin-bottom: 0;
  }
}

.mobileSocialIconList {
  margin-right: $az-spacing-m;

  &:last-child {
    margin-right: $az-spacing-zero;
  }
}

.mobileSocialIcon {
  img {
    width: $az-spacing-25;
    height: $az-spacing-25;
  }
}

.middleSection {
  display: flex;
  flex-direction: column;

  @media #{$medium-and-up} {
    flex: 1;
  }

  @media #{$small-and-down} {
    align-items: center;
    justify-content: center;
    width: 100%;
  }
}

.legalCopy {
  text-align: end;
  color: $az-grey-3;
  line-height: $az-spacing-m;
}

.footerIconsContainer {
  display: flex;

  &:lang(en) {
    @media #{$small-and-down} {
      margin-top: $az-spacing-xs;
      margin-bottom: $az-spacing-xs;
      gap: $az-spacing-xs;
    }
  }
}

.flex {
  display: flex;
  flex-direction: column;
  align-self: center;
}

.content {
  display: flex;

  @media #{$small-and-down} {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }
}
