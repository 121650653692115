@import '../../../theme/newVariables.scss';

.logoContainer {
  display: flex;
  align-items: center;

  @media #{$medium-and-up} {
    line-height: $az-spacing-4xs;
    min-height: 42px;
    min-width: 42px;
    justify-content: flex-start;
  }

  @media #{$small-and-down} {
    width: 160px;
    height: 42px;
  }
}

.azLogo {
  height: auto;
}

.azLogoMedium {
  height: $az-spacing-18;
}
