@import '@/theme/newVariables';

.cartIcon {
  display: flex;
  width: $az-spacing-xs;
  height: $az-spacing-xs;

  vertical-align: middle;
}

.storeLocatorSkeletonTop {
  width: 115px;
  height: 21px !important;
  border-radius: 0px !important;
  margin: -6px 0px !important;

  @media #{$small-and-down} {
    width: 81px;
    height: 15px !important;
    margin: -4px 0px !important;
  }
}

.storeLocatorSkeletonBottom {
  @extend .storeLocatorSkeletonTop;
  width: 95px;

  @media #{$small-and-down} {
    width: 61px;
    height: 15px !important;
    margin: -4px 0 !important;
  }
}

.badgeItemCount {
  border: solid;
  border-width: thin;
  min-width: $az-spacing-22;
  border-radius: $az-percent-50;
  background: $az-medium-orange;
  color: $az-white;
  padding: $az-spacing-2;
  position: absolute;
  top: -22%;
  left: $az-percent-57;
  font-weight: bold;
  text-align: center;

  @media #{$small-and-down} {
    left: $az-percent-61;
  }

  @media #{$extra-large-and-up} {
    top: $az-percent-neg-4;
  }
}

.fixedBadgeItemCount {
  @media #{$small-and-down} {
    left: $az-percent-61;
    top: -12%;
  }
}

.azCaptionOverNinetyNine {
  font-size: $font-size-11;
  left: $az-percent-59;
}

.cartIconLink {
  display: flex;
  flex-direction: column;
  align-items: $cdirection;
  position: relative;
  padding: $az-spacing-zero $az-spacing-5;

  @media #{$small-and-down} {
    padding: $az-spacing-zero;
    justify-content: space-around;
    height: 48px;
  }

  @media #{$extra-large-and-up} {
    padding: $az-spacing-4xs $az-spacing-4xs $az-spacing-zero $az-spacing-4xs;

    &:hover {
      background: $az-grey-2;
      border-radius: $az-spacing-5xs;
    }
  }

  &.cartIconLinkSticky {
    @media #{$extra-large-and-up} {
      padding: 8px 16px;
    }

    @media #{$small-and-down} {
      height: 48px;
      justify-content: space-evenly;
    }
  }
}

.hideText {
  @media #{$small-and-down} {
    display: none;
  }
}

.showText {
  display: block;
}

.cartButtonLabel {
  color: $az-black;
  text-align: $cdirection;
  letter-spacing: 0.25px;
  text-transform: capitalize;

  @media #{$medium-only} {
    font-size: $font-size-10;
    height: $az-spacing-17;
  }

  @media #{$small-and-down} {
    font-family: $font-az-helvetica;
    font-size: $font-size-12;
    color: $az-black;
  }

  &.cartButtonLabelSticky {
    @media #{$extra-large-and-up} {
      font-size: 12px;
      line-height: 12px;
    }
  }
}

.vehicleButton {
  background: none;
  border: none;

  @media #{$small-and-down} {
    width: 100%;
    padding: 0 $az-spacing-5xs;
  }
}

.vehicleButtonSticky {
  @media #{$extra-large-and-up} {
    padding: 0;
  }
}

.vehicleAlign,
.storeAlign,
.savedVehicleAlign,
.savedStoreAlign {
  display: flex;
  align-items: $cdirection;
  justify-content: center;
  cursor: pointer;
  padding: $az-spacing-xxs;
  height: $az-spacing-xl;
  width: $az-percent-100;

  @media #{$medium-only} {
    padding: $az-spacing-xxs $az-spacing-4xs;
  }

  @media #{$small-and-down} {
    .addVehicleText,
    .storeText {
      font-size: $font-size-14;
    }
  }
}

.vehicleAlign,
.storeAlign {
  @media #{$small-and-down} {
    padding: $az-spacing-xxxs $az-spacing-xxs;
  }
}

.vehicleAlignSticky {
  padding: 14px 16px;
}

.savedVehicleAlign,
.savedStoreAlign {
  @media #{$small-and-down} {
    padding: $az-spacing-zero $az-spacing-4xs;
  }
}

.savedVehicleAlignSticky {
  padding: 8px 16px;
}

.savedVehicleAlign,
.vehicleAlign,
.storeAlign,
.savedStoreAlign {
  .vehicleDetails,
  .storeDetails,
  .addVehicleText,
  .storeText {
    font-family: $font-az-helvetica-medium;
    letter-spacing: $az-spacing-half;
    line-height: 1.333;
    text-transform: none;
    font-weight: normal;
    margin: $az-spacing-zero $az-spacing-14 $az-spacing-zero $az-spacing-xxs;

    @media #{$medium-only} {
      margin: $az-spacing-zero $az-spacing-4xs;
    }

    @media #{$small-and-down} {
      margin: $az-spacing-zero $az-spacing-4xs;
    }
  }

  .addVehicleText {
    @media #{$small-and-down} {
      letter-spacing: normal;
    }
  }
}

.vehicleDetails {
  flex: 2;
  font-size: $font-size-12;
  color: $az-grey-6;
  max-width: $az-spacing-124;
  max-height: $az-spacing-30;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: $ldirection;
  line-height: normal;
  letter-spacing: normal;
  visibility: visible;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  @media #{$extra-large-and-up} {
    max-width: $az-spacing-160;
  }

  @media #{$small-and-down} {
    max-height: $az-spacing-27;
    font-size: $font-size-10;
  }
}

.storeDetails {
  font-size: $font-size-12;
  color: $az-grey-6;
  max-height: $az-spacing-30;

  span {
    display: block;
    white-space: nowrap;
    line-height: normal;
    letter-spacing: normal;
    text-transform: capitalize;

    @media #{$small-and-down} {
      white-space: unset;
    }
  }

  span.storeAddress {
    @media #{$small-and-down} {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  span.storeOpen {
    color: $az-color-success;
    white-space: normal;

    &.storeOpenSticky {
      white-space: nowrap;
    }
  }

  span.storeClosed {
    color: $az-color-error;
    white-space: normal;

    &.storeClosedSticky {
      white-space: nowrap;
    }
  }

  @media #{$medium-only} {
    max-width: none;
  }

  @media #{$small-and-down} {
    font-size: $font-size-10;
    max-height: none;
    max-width: $az-spacing-124;
  }
}

.storeAlignSticky,
.savedVehicleAlignSticky,
.vehicleAlignSticky {
  .addVehicleTextSticky,
  .vehicleDetailsSticky,
  .storeTextSticky {
    @media #{$medium-and-up} {
      max-height: 32px;
    }

    @media #{$extra-large-and-up} {
      font-family: $font-az-helvetica;
      font-size: 12px;
      line-height: 16px;
      padding: 0 8px;
      color: $az-grey-6;
      margin: 0;
      max-width: 120px;
    }
  }

  .storeDetailsSticky {
    font-size: 12px;
    margin: 0;
    padding: 0 8px;
    max-width: 150px;
    color: $az-grey-6;
    font-family: $font-az-helvetica;
    max-height: 32px;

    span {
      white-space: nowrap;
      line-height: 16px;
      text-transform: capitalize;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .storeAlignSticky {
      height: auto;
      padding: 8px 16px;
    }
  }
}

.storeIconContainer {
  display: flex;

  @media #{$medium-and-up} {
    width: 25px;
    height: 23px;
  }

  @media #{$small-and-down} {
    width: 20px;
    height: 19px;
  }
}

.storeIconContainerSticky {
  display: flex;
  width: 20px;
  height: 20px;
}

.storeArrowContainer {
  display: flex;
  width: 8px;
  height: 12px;
}

.storeArrowContainerSticky {
  display: flex;
  width: 6px;
  height: 11px;
}

.addVehicleText {
  text-transform: capitalize !important;
  flex: none;

  @media #{$small-and-down} {
    font-size: $font-size-14;
    letter-spacing: normal;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.storeIcon {
  @media #{$small-and-down} {
    width: $az-spacing-xs;
  }
}

.vehicleIconContainer {
  display: flex;
  width: 21px;
  height: 19px;

  @media #{$medium-and-up} {
    width: 27px;
    height: 23px;
  }
}

.vehicleIconContainerSticky {
  width: 20px;
  height: 20px;
}

.addVehicleIconContainer {
  display: flex;
  width: 20px;
  height: 15px;

  @media #{$extra-large-and-up} {
    width: 22px;
    height: 16px;
  }
}

.addVehicleIconContainerSticky {
  width: 18px;
  height: 14px;
}

.vehicleArrowContainer {
  display: flex;
  width: 8px;
  height: 12px;
}

.vehicleArrowContainerSticky {
  width: 6px;
  height: 11px;
}

.header_arrow {
  @media #{$small-and-down} {
    width: $az-spacing-4xs !important;
    height: $az-spacing-xxxs !important;
  }
}

.unsetStore {
  @media #{$small-and-down} {
    width: $az-spacing-xs;
  }

  @media #{$medium-and-up} {
    width: $az-spacing-22;
  }
}

@media #{$extra-large-and-up} {
  .addVehicleIcon {
    float: left;
    padding: $az-spacing-4xs $font-size-10 $az-spacing-zero $az-spacing-zero;
  }
}

@media #{$extra-large-and-down} {
  .showInDesktopOnly {
    display: none;
  }
}

.navMenuIcon {
  color: $az-grey-4;
  font-size: $font-size-10;
  text-transform: lowercase;
}

.scrollFixedHeader {
  .headerWrapperTopNav {
    height: $az-spacing-35;

    .addInfoItems {
      padding: $az-spacing-5xs $az-spacing-4xs $az-spacing-zero $az-spacing-zero;
    }
  }

  .storeLocatorWrap {
    &:hover {
      background: $az-grey-2;
      border-radius: $az-spacing-5xs;
    }
  }
}

.popperHeaderText {
  padding-right: 0px !important;
}

.ymmeTooltipHeader {
  display: flex;
  align-items: center;

  .addVehicleWarning {
    width: $font-size-16;
    align-self: center;
  }

  .addVehicleText {
    font-size: $font-size-16;
    font-weight: 800;
    margin-left: $az-spacing-xxxs;
  }
}

.ymmeTooltipRoot.ymmeTooltip {
  z-index: 1502;
  opacity: 100%;

  .popperHeaderText {
    display: flex;
  }

  .popperTitleWithoutBorder {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  @media #{$medium-and-up} {
    left: $az-spacing-neg-2 !important; // override tooltip element level styles

    .ymmeTooltipPaper {
      margin-left: $az-spacing-neg-16;
      margin-right: $az-spacing-xxs;
    }

    &[data-popper-placement*='bottom'] {
      margin-top: $az-spacing-18 !important; // override tooltip important styles
    }
  }

  @media #{$small-and-down} {
    z-index: 1501;
    width: 100%;
    padding: $az-spacing-zero $az-spacing-9;

    &[data-popper-placement*='bottom'] {
      margin-top: $az-spacing-9 !important; // override tooltip important styles
    }
  }

  .ymmeTooltipBody {
    color: $az-grey-14;
    font-size: $font-size-13;

    .openYmmeButton {
      color: $az-grey-14;
      font-size: $font-size-13;
      font-weight: normal;
      display: inline-block;
    }
  }
}

.fixedHeaderMargin {
  margin-top: $az-spacing-65 !important;
}
