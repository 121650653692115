@import '../../theme/newVariables.scss';

$largeDsk: $az-spacing-914;
$mediumDsk: $az-spacing-728;
$smallDsk: $az-spacing-542;
$smallTab: $az-spacing-512;

.headerNav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: $az-spacing-18 $az-spacing-xxs;
  color: $az-dark-black;
  line-height: $az-spacing-xs;

  @media #{$medium-and-down} {
    background-color: $az-black;
  }

  .headerNavTitle,
  h2 {
    font-weight: 500;
    font-size: $font-size-26;

    @media #{$medium-and-down} {
      color: $az-white;
      font-size: $font-size-14;
    }
  }

  .uppercase {
    text-transform: uppercase;
  }

  .headerCloseButton {
    color: $az-dark-black;
    padding: $az-spacing-zero;

    @media #{$medium-and-down} {
      color: $az-white;
    }
  }

  .icon {
    height: $az-spacing-xs;
    width: $az-spacing-xs;
  }

  .backButton {
    border: none;
    background: none;
    padding: $az-spacing-zero;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    line-height: $az-spacing-xs;
    font-weight: 500;
    font-size: $font-size-14;

    @media #{$medium-and-down} {
      color: $az-white;
    }
  }

  .backIcon {
    width: $az-spacing-xxs;
    height: $az-spacing-xxs;
    margin-right: $az-spacing-6;

    @media #{$small-and-down} {
      color: $az-white;
    }
  }

  .orangeBackIcon {
    width: $az-spacing-xxs;
    height: $az-spacing-xxs;
    margin-right: $az-spacing-6;
    color: $az-medium-orange;
  }

  .whiteBackIcon {
    width: $az-spacing-xxs;
    height: $az-spacing-xxs;
    margin-right: $az-spacing-6;
    color: $az-white;
  }
}

.rounded {
  border-radius: 4px 4px 0 0;
}

.flexEnd {
  justify-content: flex-end;
}

.darkHeader {
  background-color: $az-black;
  color: $az-white;

  .headerCloseButton {
    color: $az-white;
  }

  .backBtnTabFullscreen {
    color: $az-white;
  }
}

.modal {
  .fullWidth {
    width: 100%;
    max-width: 100vw;
  }

  .largeDskSmallTab {
    width: $az-percent-100;
    max-width: $largeDsk;

    @media #{$extra-large-and-down} {
      max-width: $smallTab;
    }

    @media #{$small-and-down} {
      max-width: $az-percent-100;
      height: $az-percent-100;
      margin: $az-spacing-zero;
    }
  }

  .mediumDskSmallTab {
    width: $az-percent-100;
    max-width: $mediumDsk;

    @media #{$extra-large-and-down} {
      max-width: $smallTab;
      max-height: $az-percent-100;
    }

    @media #{$small-and-down} {
      max-width: $az-percent-100;
      height: $az-percent-100;
      margin: $az-spacing-zero;
    }
  }

  .smallDskSmallTab {
    width: $az-percent-100;
    max-width: $smallDsk;

    @media #{$extra-large-and-down} {
      max-width: $smallTab;
    }

    @media #{$small-and-down} {
      max-width: $az-percent-100;
      height: $az-percent-100;
      margin: $az-spacing-zero;
    }
  }

  .largeDskFullscreenTab {
    width: $az-percent-100;
    max-width: $largeDsk;

    @media #{$extra-large-and-down} {
      max-width: $az-percent-100;
      height: $az-percent-100;
    }

    @media #{$extra-large-and-up} {
      max-width: 984px;
    }
  }

  .xlargeDskFullscreenTab {
    width: $az-percent-100;
    max-width: $az-percent-100;
    height: $az-percent-100;

    @media #{$extra-large-and-up} {
      max-width: $az-percent-95;
      height: calc(100vh - 62px);
    }
  }

  .xlargeDialog {
    @media #{$extra-large-and-down} {
      height: $az-percent-100;
    }
  }

  .mediumDskFullscreenTab {
    width: $az-percent-100;
    max-width: $mediumDsk;

    @media #{$extra-large-and-down} {
      max-width: $az-percent-100;
      height: $az-percent-100;
      margin: $az-spacing-zero;
    }
  }

  .smallDskFullscreenTab {
    width: $az-percent-100;
    max-width: $smallDsk;

    @media #{$extra-large-and-down} {
      max-width: $az-percent-100;
      height: $az-percent-100;
      margin: $az-spacing-zero;
    }
  }

  .headerText {
    padding: $az-spacing-4xs $az-spacing-zero $az-spacing-xxs;
  }

  .modalContent,
  .modalContentInset {
    background-color: $az-white;
  }

  .modalContent {
    padding: $az-spacing-zero $az-spacing-l;
    margin-bottom: $az-spacing-l;

    @media #{$small-and-down} {
      padding: $az-spacing-zero $az-spacing-xxs;
      margin: $az-spacing-xxs $az-spacing-zero;
    }
  }

  .modalContentInset {
    padding: $az-spacing-zero $az-spacing-88;
    margin-bottom: $az-spacing-l;

    @media #{$small-and-down} {
      margin: $az-spacing-xxs $az-spacing-zero $az-spacing-zero;
      padding: $az-spacing-zero $az-spacing-xxs $az-spacing-l;
    }
  }

  .noGap {
    padding: $az-spacing-zero;
  }

  .mediumGap {
    padding: $az-spacing-zero $az-spacing-xxs;
  }

  .noMargin {
    margin: $az-spacing-zero;
  }
}

.modalAlert {
  z-index: 2000;
}

.confirmation {
  height: auto;
  padding: 44px;
}

.confirmationButtons {
  display: flex;
  padding: 20px;
  justify-content: space-around;
}
