@import '../../../theme/newVariables';

.footerTopContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-bottom: $az-spacing-14;

  hr {
    display: none;
  }

  @media #{$medium-and-down} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: $az-spacing-zero;

    hr {
      display: block;
      justify-self: center;
      width: $az-percent-100;
    }
  }

  @media #{$small-and-down} {
    display: flex;
    flex-direction: column;

    hr {
      display: none;
    }
  }
}

.topButtons {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex: 0.8 1 auto;
  width: 100%;

  @media #{$medium-only} {
    flex-direction: row;
    justify-content: center;
    margin-top: $az-spacing-10;
    margin-bottom: $az-spacing-xs;
    width: 100%;
  }

  @media #{$small-and-down} {
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }
}

.footerTopIconLabelButton {
  flex: 1 1 auto;
}

.footerTopItem {
  display: flex;
  flex-wrap: nowrap;
  flex: 1 1 auto;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  cursor: pointer;

  @media #{$medium-and-down} {
    justify-content: center;
  }

  @media #{$small-and-down} {
    padding-left: $az-spacing-rem-1-point-5;
    justify-content: flex-start;
  }

  @media #{$xsmall-only} {
    padding-left: $az-spacing-rem-1;
  }
}

.footerTopItemLabel {
  font-size: $font-size-xs;
  font-weight: 500;
  line-height: 1.5;
  color: $az-white;
  text-transform: capitalize;

  @media #{$medium-and-down} {
    font-size: $font-size-xs;
  }

  @media #{$small-and-down} {
    font-family: $font-az-cond-heavy;
    font-size: $font-size-sm;
    text-transform: uppercase;
  }
}

.mxFooterTopItemLabel {
  font-size: $font-size-16;
  font-weight: 500;
  line-height: 1.5;
  color: $az-white;
  text-transform: capitalize;

  @media #{$medium-and-down} {
    font-size: $font-size-base;
  }

  @media #{$small-and-down} {
    font-family: $font-az-cond-heavy;
    font-size: $font-size-base;
    text-transform: uppercase;
  }
}

.footerTopItemLogo {
  margin-right: $az-spacing-s;
  justify-content: center;
  display: flex;
}

.footerTopSocialIcon {
  width: $az-spacing-25;
  height: $az-spacing-25;
}

.footerTopSocialIconContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-width: 250px;
  flex: 0;

  @media #{$medium-and-down} {
    justify-content: center;
    align-items: center;
    padding-top: $az-spacing-xs;
    padding-bottom: $az-spacing-xs;

    a:not(:last-child) {
      margin-right: $az-spacing-xxxl;
    }
  }

  @media #{$small-and-down} {
    display: none;
  }
}

.rightArrow {
  width: $az-spacing-s;
  height: $az-spacing-s;
  margin-left: $az-spacing-10;
  margin-right: $az-spacing-zero;
  display: none;

  @media #{$medium-and-down} {
    display: block;
  }

  @media #{$small-and-down} {
    display: none;
  }
}

.mobileRectangle {
  float: left;

  @media #{$small-and-down} {
    background-color: $az-grey-6;
    width: $az-percent-100;
    margin: $az-spacing-4xs $az-spacing-zero;
    padding: $az-spacing-xxs $az-spacing-zero;
    font-size: $font-size-sm;
  }
}
