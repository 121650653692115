@import '../../../theme/newVariables.scss';

.simple,
.outlined,
.shadowed,
.simpleFluid,
.outlinedFluid,
.shadowedFluid {
  background-color: $az-white;
  height: auto;
}

.bgTransparent {
  background-color: transparent;
}

.outlined,
.outlinedFluid {
  border: $az-spacing-1 solid $az-grey-2;
}

.shadowed,
.shadowedFluid {
  box-shadow: $az-box-shadow-gray-1;
}

.simpleFluid,
.outlinedFluid,
.shadowedFluid {
  margin-left: -$az-spacing-xs;
  margin-right: -$az-spacing-xs;

  @media #{$extra-large-and-down} {
    margin-left: -$az-spacing-xxs;
    margin-right: -$az-spacing-xxs;
  }
}

.withPadding {
  padding: $az-spacing-xs;
}

.withMargin {
  margin-bottom: $az-spacing-xs;
}

@media #{$extra-large-and-down} {
  .withMargin {
    margin-bottom: $az-spacing-xxs;
  }

  .withPadding {
    padding: $az-spacing-xxs;
  }
}

@media #{$small-and-down} {
  .withMargin {
    margin-bottom: $az-spacing-zero;
  }
}
