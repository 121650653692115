@import '../../theme/newVariables';

.container {
  padding: $az-spacing-zero $az-spacing-5xs;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  animation-name: rotate;
  animation-timing-function: ease;
  animation-duration: 200ms;
  animation-iteration-count: 1;
  position: relative;
  z-index: 2;

  span {
    animation-name: bounce;
    animation-timing-function: ease;
    animation-duration: 1s;
    animation-iteration-count: infinite;
  }

  span:nth-child(2) {
    animation-delay: 200ms;
  }

  span:nth-child(3) {
    animation-delay: 400ms;
  }
}

@keyframes rotate {
  0% {
    transform: rotateY(90deg);
  }
  100% {
    transform: rotateX(0);
  }
}

@keyframes bounce {
  50% {
    transform: scale(0.6);
  }
}
