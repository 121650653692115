@import '../../theme/newVariables.scss';

.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box; // Prevent padding issue with the Modal and fixed positioned AppBar.
  z-index: 1100; // TODO: make sense out of z-index
  flex-shrink: 0;
  // NOTE: the !important is needed here to override the styles of Paper
  // A follow-up ticket should be made to address this
  background-color: $az-black !important;
  color: $az-white !important;
}

/* Styles applied to the root element if `position="fixed"`. */
.positionFixed {
  position: fixed;
  top: 0;
  left: auto;
  right: 0;

  @media print {
    // Prevent the app bar to be visible on each printed page.
    position: absolute;
  }
}

/* Styles applied to the root element if `position="absolute"`. */
.positionAbsolute {
  position: absolute;
  top: 0;
  left: auto;
  right: 0;
}

/* Styles applied to the root element if `position="sticky"`. */
.positionSticky {
  // ⚠️ sticky is not supported by IE 11.
  position: sticky;
  top: 0;
  left: auto;
  right: 0;
}

/* Styles applied to the root element if `position="static"`. */
.positionStatic {
  position: static;
}

/* Styles applied to the root element if `position="relative"`. */
.positionRelative {
  position: relative;
}
