@import '../../../../theme/newVariables';

.textField {
  .label,
  .outlineLabel {
    font-family: $font-az-helvetica;
    font-size: $font-size-15-75;
    color: $az-grey-5 !important;
    left: $az-spacing-xxs;
    z-index: 2;

    span:first-of-type {
      color: $az-color-error !important;
    }
  }

  .label {
    top: $az-spacing-neg-4;
  }

  .outlineLabel {
    top: $az-spacing-neg-9;
  }
  .error {
    color: $az-grey-14 !important;

    .inputAsterisk {
      color: $az-color-error !important;
    }
  }

  .labelDisabled {
    text-decoration: line-through;
  }

  .labelFocused {
    color: $az-grey-14 !important;
    top: $az-spacing-4xs;
  }

  .labelShrink {
    top: $az-spacing-4xs;
    font-family: $font-az-helvetica;
    font-size: $font-size-12;
    color: $az-grey-14;
    transform: translate(0, 1px);

    &.labelMultiline {
      background-color: #fff;
      left: 2px;
      right: 18px;
      top: 0;
      padding: 6px 14px;

      &.labelFocused {
        top: 1px;
      }
    }
  }

  .labelOutlined {
    top: -6px;
    font-family: $font-az-helvetica;
    font-size: 11.25px;
    color: #6e6e6e;
    transform: translate(0, 1px);
    background: #fff;
    padding-left: 4.5px;
    padding-right: 4.5px;

    &.labelMultiline {
      background-color: #fff;
      left: 2px;
      right: 18px;
      top: 0;
      padding: 6px 14px;

      &.labelFocused {
        top: 1px;
      }
    }
  }

  .labelLeftAligned {
    font-family: $font-az-helvetica;
    font-size: $font-size-14;
    color: $az-grey-14;
    z-index: 2;
  }

  .inputRoot,
  .inputRootWithOutlined {
    color: $az-grey-4;
    background-color: $az-white;
    width: auto;
  }

  .inputRoot {
    height: $az-spacing-xxl;
  }

  .inputRootWithOutlined {
    height: $az-spacing-45;
  }

  .inputError {
    height: $az-spacing-xxl;
  }

  .inputErrorWithOutlined {
    height: $az-spacing-45;
  }

  .inputError,
  .inputErrorWithOutlined {
    background-color: $az-white;
    box-shadow: inset $az-spacing-zero $az-spacing-neg-3 $az-spacing-zero $az-spacing-zero
      $az-color-error;
    width: auto;
  }

  ::-moz-placeholder {
    /* Firefox 19+ */
    opacity: 1 !important;
  }

  ::-webkit-input-placeholder {
    /* Chrome */
    opacity: 1 !important;
  }

  :-moz-placeholder {
    /* Firefox 18- */
    opacity: 1 !important;
  }

  .input,
  .inputOutlined {
    box-sizing: border-box;
    border: $az-spacing-1 solid $az-grey-14;
    padding: $az-spacing-zero $az-spacing-xxs;
    font-family: $font-az-helvetica;
    line-height: 1.5;
    font-size: $font-size-16;
    color: $az-grey-14 !important;

    &:focus {
      border: $az-spacing-2 solid $az-black;
    }

    &:hover {
      border: $az-spacing-2 solid $az-black;
    }
  }

  .input {
    height: $az-spacing-xxl;
  }

  .inputOutlined {
    height: $az-spacing-45;
  }

  .inputWithLabel,
  .inputWithLabelOutlined {
    color: $az-grey-14;
    box-sizing: border-box;
    border: $az-spacing-1 solid $az-grey-14;
    word-break: break-all;
    font-family: $font-az-helvetica;
    line-height: 1.5;
    font-size: $font-size-16;

    &:focus {
      border: $az-spacing-2 solid $az-black;
    }

    &:hover {
      border: $az-spacing-2 solid $az-black;
    }
  }

  .inputWithLabel {
    height: $az-spacing-xxl;
    padding: $az-spacing-s $az-spacing-xxs $az-spacing-zero $az-spacing-xxs;
  }

  .inputWithLabelOutlined {
    height: $az-spacing-45;
    padding: $az-spacing-6 $az-spacing-xxs $az-spacing-zero $az-spacing-xxs;
  }

  .inputBorderBottom {
    color: $az-grey-4;
    box-sizing: border-box;
    border-bottom: $az-spacing-1 solid $az-grey-3;
    height: $az-spacing-xxl;
    padding: $az-spacing-s $az-spacing-xxs $az-spacing-zero $az-spacing-zero;
    font-family: $font-az-helvetica;
    line-height: 1.5;
    font-size: $font-size-14;

    &:focus {
      border-bottom: $az-spacing-1 solid $az-black;
    }

    &:hover {
      border-bottom: $az-spacing-1 solid $az-black;
    }
  }

  .formControl {
    margin-top: $az-spacing-zero;
    background: $az-white;
  }

  .disabledFormControl {
    margin-top: $az-spacing-zero;
    background-color: $az-grey-1;
    z-index: -1;
  }

  .errorText {
    color: $az-color-error !important;
  }

  .srOnly {
    position: absolute;
    width: $az-spacing-1;
    height: $az-spacing-1;
    padding: $az-spacing-zero;
    margin: $az-spacing-neg-1;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: $az-spacing-zero;
  }

  &:hover {
    .labelShrink.labelMultiline {
      top: 1px;
    }

    .labelOutlined.labelMultiline {
      top: 1px;
    }
  }
}

.appBorderRadius {
  border-radius: $az-spacing-5xs;
}
