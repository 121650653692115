@import '../../theme/newVariables.scss';

.dialogRoot {
  @media print {
    // Use !important to override the Modal inline-style.
    position: absolute !important;
  }
}

.dialogContainer {
  min-height: 100%;
  // We disable the focus ring for mouse, touch and keyboard users.
  outline: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  @media #{$medium-and-down} {
    height: 100%;
  }
}

.dialogActionsRoot {
  display: flex;
  align-items: center;
  padding: 8px;
  justify-content: flex-end;
  flex: 0 0 auto;
}

.dialogActionsSpacing {
  &:not(:first-child) {
    margin-left: 8;
  }
}

.dialogContentRoot {
  flex: 1 1 auto;
  -webkit-overflow-scrolling: touch; // Add iOS momentum scrolling.
  padding: 8px 24px;

  &:first-child {
    padding-top: 20px;
  }
}

.dialogContentDividers {
  padding: 16px 24px;
  border-top: 1px solid rgba(255, 255, 255, 0.12);
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}

.dialogTitleRoot {
  padding: 16px 24px 12px;
  flex: 0 0 auto;
}

.paper {
  display: flex;
  flex-direction: column;

  @media #{$extra-large-and-up} {
    margin: 32px 0;
  }
}
