@import '../../theme/newVariables.scss';
@import '../../theme/mixins.scss';

.button {
  background-color: $az-black;
  color: $az-white;
  border: none;
  font-weight: bold;
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-width: 64px;
  width: unset;
  cursor: pointer;
  font-size: 16px;
  margin: 1px;
  line-height: 17px;
}

.default {
  font-family: AutoZoneCond, Arial, sans-serif;
}

.system-font {
  font-family: none;
}

.primary,
.secondary,
.super,
.danger {
  position: relative;
  overflow: hidden;
  transition: background-color 300ms ease;
}
.primary,
.secondary,
.super {
  text-transform: uppercase;
}

.super {
  @include rippleEffect($az-medium-orange-2);
}
.primary {
  @include rippleEffect($az-black);
}
.secondary {
  @include rippleEffect($az-grey-16);
}
.danger {
  @include rippleEffect($az-medium-red);
}

.primary,
.secondary {
  font-weight: normal;
  font-family: $font-az-cond-medium;
  letter-spacing: 0.02em;
  &.small {
    min-height: $az-spacing-45;
    @media #{$medium-and-down} {
      min-height: $az-spacing-l;
    }
  }
}

.primary {
  min-height: $az-spacing-54;
  background-color: $az-black;
  font-size: 15.8px;
  color: $az-white;
  border: none;
  padding: $az-spacing-18 $az-spacing-36;

  @media #{$medium-and-down} {
    font-size: $az-spacing-14;
    min-height: $az-spacing-xxl;
    padding: $az-spacing-17 $az-spacing-36;
  }

  &:focus-visible {
    outline: 2px solid #015fcc;
  }
  &:focus:enabled {
    background-color: $az-black;
  }
  &:hover:enabled {
    background-color: $az-grey-14;
  }
  &:disabled {
    background-color: $az-grey-1;
    color: $az-grey-14;
    cursor: not-allowed;
  }
  &.loading:hover {
    background-color: $az-black;
    cursor: unset;
  }
}

a.primary,
a.primary:active {
  color: $az-white !important;
  width: unset !important;
}

.secondary {
  min-height: $az-spacing-54;
  color: $az-black;
  font-size: 15.8px;
  background-color: $az-white;
  border: 1px solid $az-black;
  font-family: $font-az-cond-medium;
  font-weight: normal;
  text-transform: uppercase;
  letter-spacing: 0.02em;
  padding: 17.5px $az-spacing-36;
  @media #{$medium-and-down} {
    padding: $az-spacing-xxs $az-spacing-36;
    font-size: $az-spacing-14;
    min-height: $az-spacing-xxl;
  }

  &:hover:enabled {
    background-color: $az-grey-1;
  }

  &:focus-visible {
    outline: 2px solid #015fcc;
  }
  &:focus:enabled {
    background-color: $az-white;
  }

  &:hover:disabled {
    cursor: not-allowed;
  }
  &:disabled {
    color: $az-grey-14;
    cursor: not-allowed;
    border: 1px solid $az-grey-14;
  }
  &:hover:enabled {
    background-color: $az-grey-1;
  }
  &.loading:hover {
    background-color: $az-white;
    cursor: unset;
  }
}

.super {
  background-color: $az-medium-orange-2;
  box-shadow: 0 3px 1px -2px rgba(32, 33, 36, 0.2), 0 2px 2px 0 rgba(32, 33, 36, 0.14),
    0 1px 5px 0 rgba(32, 33, 36, 0.12);
  border: none;
  font-size: $az-spacing-21;
  font-family: $font-az-cond-heavy;
  letter-spacing: normal;
  text-transform: uppercase;
  padding: $az-spacing-18;
  min-height: $az-spacing-59;

  @media #{$medium-and-down} {
    font-size: $az-spacing-19;
    padding: $az-spacing-17 $az-spacing-18;
    min-height: $az-spacing-60;
  }
  &:focus-visible {
    outline: 2px solid #015fcc;
  }
  &:focus:enabled {
    background-color: $az-medium-orange-2;
  }
  &:hover:enabled {
    background-color: $az-light-orange-2;
  }
  &:disabled {
    background-color: $az-grey-2;
    color: $az-grey-14;
    cursor: not-allowed;
  }
  &.loading:hover {
    background-color: $az-medium-orange-2;
    cursor: unset;
  }
}

.danger {
  background-color: $az-medium-red;
  border: none;
  &:focus-visible {
    outline: 2px solid #015fcc;
  }
  &:focus:enabled {
    background-color: $az-medium-red;
  }
  &:hover:enabled {
    background-color: $az-light-red;
  }
  &.loading:hover {
    background-color: $az-medium-red;
    cursor: unset;
  }
}

.link {
  border: 0;
  margin: 0;
  padding: 0;
  background: transparent;
  text-decoration: $az-black underline;
  font-family: inherit;
  color: $az-black;
}

.disabled {
  color: $az-grey-3;
}

.rounded {
  border-radius: 4px;
}
.pill {
  text-transform: unset;
  font-family: $font-az-helvetica;
  font-weight: bold;
  font-size: 13.5px;
  padding: 9px 18px;
  @media #{$medium-and-down} {
    font-size: 12px;
    min-height: $az-spacing-28;
  }
}
.small {
  &.primary,
  &.secondary,
  &.danger {
    min-height: $az-spacing-45;
    padding: $az-spacing-9 $az-spacing-18;
  }

  &.pill {
    padding: 0px 13.5px;
    min-height: $az-spacing-23;
    border-radius: $az-spacing-xxs;
    white-space: nowrap;
  }
  @media #{$medium-and-down} {
    &.primary,
    &.secondary,
    &.danger {
      min-height: $az-spacing-l;
    }
    &.pill {
      padding: 4px 16px;
      min-height: $az-spacing-23;
    }
  }
}

.large {
  &.primary,
  &.secondary,
  &.danger {
    min-height: $az-spacing-54;
  }

  &.pill {
    border-radius: $az-spacing-25;
  }
  &.super {
    min-height: $az-spacing-59;
  }
}

.leftIcon {
  height: 16px;
  width: 16px;
  margin-left: 0;
  margin-right: 8px;
}

.rightIcon {
  height: 16px;
  width: 16px;
  margin-left: 8px;
  margin-right: 0;
}

.buttonText {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 2;
}
.removeZIndex {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
