@import '../../../../theme/newVariables.scss';

.storeLocatorWrap,
.addVehicleWrap {
  margin-right: $az-spacing-s;

  &:hover {
    background: $az-grey-2;
    border-radius: $az-spacing-5xs;
  }

  @media #{$small-and-down} {
    margin: $az-spacing-zero;
    width: $az-percent-50;
  }
}

.mobileOnly {
  @media #{$medium-and-up} {
    display: none;
  }
}

.menuContainer {
  @media #{$medium-and-up} {
    display: none;
  }

  @media #{$small-and-down} {
    padding: $az-spacing-zero;
    height: $az-spacing-42;
    display: flex;
    align-items: center;
  }
}

.addInfoItems {
  padding: $az-spacing-zero;

  @media #{$small-and-down} {
    min-width: 44px;
    height: 44px;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.notMobile {
  @media #{$small-and-down} {
    display: none;
  }
}

.separator {
  @media #{$large-and-up} {
    position: absolute;
    left: $az-spacing-320;
    top: $az-spacing-m;

    @media (min-width: 1120px) and (max-width: 1380px) {
      max-width: 600px;
    }

    @media (max-width: 1185px) {
      max-width: 520px;
    }

    &::before,
    &::after {
      content: '';
      position: absolute;
      height: $az-spacing-35;
      font-size: $font-size-40;
      top: $az-spacing-zero;
      line-height: $az-spacing-l;
      background-color: $az-grey-2;
      width: $az-spacing-1;
      transform: rotate(25deg);
    }

    &::before {
      left: $az-spacing-zero;
    }
  }
}

.headerPromoBlock {
  @media #{$medium-and-down} {
    background-color: $az-grey-1;
  }

  // To override the grid spacing, used the important
  @media #{$medium-only} {
    padding-bottom: $az-spacing-zero !important;
  }

  // To override the grid spacing, used the important
  @media #{$small-and-down} {
    box-shadow: inset $az-spacing-zero - $az-spacing-1 $az-spacing-zero $az-spacing-zero
      rgba(32, 33, 36, 0.12);
    padding-bottom: $az-spacing-zero !important;
  }
}

.headerNavBar {
  @media #{$medium-and-up} {
    box-shadow: $az-spacing-zero $az-spacing-1 $az-spacing-5 $az-spacing-zero rgba(32, 33, 36, 0.12),
      $az-spacing-zero $az-spacing-2 $az-spacing-2 $az-spacing-zero rgba(32, 33, 36, 0.14),
      $az-spacing-zero $az-spacing-3 $az-spacing-1 $az-spacing-neg-2 rgba(32, 33, 36, 0.2);
    background: $az-grey-1;
    padding-left: $az-spacing-xs;
    padding-right: $az-spacing-xs;
  }

  @media #{$medium-only} {
    padding-left: $az-spacing-xxs;
    padding-right: $az-spacing-xxs;
  }
}

.headerNavBarHidden {
  visibility: hidden;
}

.headerNavBarAd {
  background: $az-grey-1;
}

.addInfo {
  display: flex;
  align-items: $cdirection;
  justify-content: flex-end;
  padding-right: $az-spacing-xxxs;

  @media #{$small-and-down} {
    padding-right: $az-spacing-zero;
    width: $az-percent-100;
  }

  @media #{$medium-only} {
    .addInfoItems {
      padding-bottom: $az-spacing-5xs;
      padding-top: $az-spacing-4xs;
    }
  }
}

.addInfoItemsBorder {
  background: $az-grey-2;
  width: $az-spacing-1;
}

.mobileAddInfoItems {
  @media #{$small-and-down} {
    border-left: $az-spacing-1 solid $az-grey-2;
    flex-grow: $az-spacing-zero;
    flex-basis: $az-percent-50;
  }
}

.desktopNavBar {
  width: $az-spacing-88;

  @media #{$medium-only} {
    width: $az-spacing-71;
  }
}

.desktopAccountWrapper {
  @media #{$medium-only} {
    padding-top: $az-spacing-zero;
  }
}

.desktopCartWrapper {
  @media #{$extra-large-and-up} {
    padding: $az-spacing-4xs;
    margin: $az-spacing-zero;
    box-sizing: border-box;
  }
}

.flexStartInfo {
  justify-content: space-between;
  align-items: $cdirection;
  width: $az-percent-100;
  height: $az-spacing-xxxl;

  @media #{$extra-large-and-up} {
    padding-right: $az-spacing-zero;
  }

  @media #{$medium-only} {
    padding-right: $az-spacing-zero;

    .addInfoItems {
      padding: $az-spacing-zero;
    }

    .storeLocatorWrap {
      margin-left: $az-spacing-xxs;
      margin-right: $az-spacing-zero;
    }

    .addVehicleWrap {
      margin-right: $az-spacing-xxs;
      margin-left: $az-spacing-zero;
    }
  }

  @media #{$small-and-down} {
    flex-wrap: wrap;
    height: auto;
  }
}

.autoZoneLogo {
  @media #{$small-and-down} {
    min-height: 42px;
    // Next Image overrides
    max-width: $az-spacing-248 !important;
    width: $az-percent-100 !important;
    object-fit: contain !important;
  }

  @media #{$medium-only} {
    min-width: calc(#{$az-spacing-165} + #{$az-spacing-2}) !important;
    margin: $az-spacing-zero !important;
  }
}

.getVehicleBlock {
  margin-right: $az-spacing-m;
}

.getStoreBlock {
  margin-left: $az-spacing-m;
}

// FIXME: move this media query into those classes
@media #{$medium-only} {
  .tabletStyle {
    padding-left: $az-spacing-m;
  }

  .getVehicleBlock {
    margin-right: $az-spacing-xxs;
  }

  .getStoreBlock {
    margin-left: $az-spacing-xxs;
  }
}

.searchDesktopField {
  @media #{$medium-and-up} {
    margin: auto;
    flex: 2;
  }

  @media #{$medium-only} {
    position: relative;
    left: 0;
    right: 0;
    width: 400px;
    transition: all 0.3s ease-out;
  }

  @media #{$small-and-down} {
    flex: 1;
    width: $az-percent-100;
    flex-basis: $az-percent-100;
  }

  .searchItem {
    @media #{$small-and-down} {
      margin: $az-spacing-zero;
      padding: $az-spacing-2 $az-spacing-xxs $az-spacing-xxs;
    }
  }
}

.searchDesktopField.searchDesktopFocused {
  @media #{$medium-only} {
    width: 96%;
    position: absolute;
  }
}

.hidden {
  @media #{$medium-only} {
    transition: opacity 0.2s ease-out;
    opacity: 0;
  }
}

.topHeaderWrapper {
  padding: $az-spacing-xs;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: $az-white;

  @media #{$medium-only} {
    padding: $az-spacing-7 $az-spacing-xxs $az-spacing-7 $az-spacing-26;
  }

  @media #{$small-and-down} {
    align-items: $cdirection;
    margin: auto;
    justify-content: space-between;
    padding: $az-spacing-10 $az-spacing-xxs;
  }

  @media #{$extra-large-and-up} {
    padding-left: $az-spacing-28;

    .addInfoItems {
      margin-left: $az-spacing-xxs;
      padding: $az-spacing-4xs $az-spacing-xxs $az-spacing-5;

      &:first-child {
        padding: $az-spacing-4xs $az-spacing-10 $az-spacing-5;
      }

      &.desktopCartWrapper {
        margin: $az-spacing-zero;
        padding: $az-spacing-zero;
      }
    }
  }

  .headerRightIcons {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.headerWrapper {
  position: relative;
  z-index: calc(var(--st-z-index-fixed) + 1);
}

.focusedHeader {
  z-index: 100;
}

.hoverInfoItems:hover {
  background: $az-grey-2;
  border-radius: $az-spacing-5xs;
}

.desktopWrapper {
  @media #{$small-and-down} {
    min-width: 46px;
  }

  @media #{$medium-and-up} {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: $az-spacing-xxxs;
  }

  @media #{$extra-large-and-up} {
    a {
      padding-bottom: $az-spacing-4xs;
    }
  }
}

.topNavTagline {
  @media #{$small-and-down} {
    max-width: 160px;
  }

  @media #{$medium-and-up} {
    display: flex;
    max-width: $az-percent-33;
    align-items: center;
  }
}

.mobileHeaderWrapperTopNav {
  @media #{$small-and-down} {
    border-top: $az-spacing-1 solid $az-grey-2;
    border-bottom: $az-spacing-1 solid $az-grey-2;
  }
}

.addVehicleStoreWrap {
  @media #{$small-and-down} {
    display: flex;
    width: $az-percent-100;
  }
}

.fullWidth {
  width: $az-percent-100;
}

.skipToMainContentButton {
  width: 194px;
  position: absolute !important;
  z-index: 999;
  margin: 32px 0 0 -99999px;
}

.skipToMainContentButton:focus {
  margin-left: 32px;
}

.myAccountCartSpacing {
  margin-right: $az-spacing-xxs;
  @media #{$extra-large-and-up} {
    margin-right: 24px;
  }
}
