@import '../../../theme/newVariables.scss';

.checkoutFooter {
  background-color: $az-black;

  .footerBox {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: $az-spacing-zero auto;
    max-width: $az-spacing-1600;
    min-height: $az-spacing-72;
    max-width: $az-spacing-1440;
    width: $az-percent-100;
    padding-left: $az-spacing-4xs;
    padding-right: $az-spacing-4xs;
    .copyright {
      color: $az-grey-1;
    }

    .digicert {
      line-height: $az-spacing-10;
    }
  }
}
