.root {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1600;
  width: 100%;
  pointer-events: none;
}

.open {
  pointer-events: initial;
}

.paper {
  top: 0;
  flex: 1 0 auto;
  height: 100%;
  display: flex;
  outline: 0;
  z-index: 1200;
  position: fixed;
  overflow-y: auto;
  flex-direction: column;
  -webkit-overflow-scrolling: touch;
}

.paperAnchorLeft {
  right: auto;
  transition: transform 200ms;
}

.paperOpenLeft {
  animation: slideInLeft 200ms cubic-bezier(0, 0, 0.2, 1);

  @keyframes slideInLeft {
    0% {
      transform: translateX(-100%);
    }

    100% {
      transform: translateX(0);
    }
  }
}

.paperClosingLeft {
  transform: translateX(-100%);
}

.paperAnchorRight {
  transform: translateX(100%);
  left: auto;
  right: 0;

  &.stickyContainerShowing {
    padding-bottom: 92px;
  }
}

.paperOpenRight,
.stickyBtnContainerOpenRight {
  transform: translateX(0);
  animation: slideInRight 200ms cubic-bezier(0, 0, 0.2, 1);

  @keyframes slideInRight {
    0% {
      transform: translateX(100%);
    }

    100% {
      transform: translateX(0);
    }
  }
}

.paperClosingRight,
.stickyBtnContainerClosingRight {
  animation: slideOutRight 200ms cubic-bezier(0, 0, 0.2, 1);

  @keyframes slideOutRight {
    0% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(100%);
    }
  }
}
