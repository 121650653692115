@import '@/theme/newVariables';
$paper-drawer-z-index: 1600;
$header-height: 98px;

.headerContainer {
  background-color: white;
  width: 100%;
  top: 0;
  z-index: var(--st-z-index-fixed);

  @media #{$medium-and-up} {
    z-index: $paper-drawer-z-index;
  }

  .menuButton {
    width: min-content;
    padding: 4px 12px;

    @media #{$medium-and-up} {
      padding: 4px 16px;
    }
  }

  .logo {
    width: 188px;
    height: 50px;

    @media #{$medium-and-up} {
      width: 219px;
      height: 58px;
    }
  }

  .hamburgerMenu {
    line-height: var(--st-unit-4);
    font-size: calc(var(--st-unit-1) * 2.5);

    @media #{$medium-and-up} {
      line-height: var(--st-unit-5);
      font-size: var(--st-unit-3);
    }
  }
}

.drawerContent {
  left: 0;

  @media #{$medium-and-up} {
    top: $header-height;
    left: auto;
    height: max-content;
  }

  .menuTitle {
    padding: var(--st-unit-2);
    line-height: 24px;
  }

  .closeButton {
    padding: 0;
    min-width: max-content;
  }
}

.drawerWrapper,
.backdrop {
  @media #{$medium-and-up} {
    top: $header-height;
  }
}
