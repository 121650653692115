@import '../../../theme/newVariables.scss';

.modalBodyContainer {
  text-align: center;
}

.separator {
  width: $az-spacing-84;
  height: $az-spacing-5xs;
  background-color: $az-medium-orange;
  transform: skewX(-30deg);
  margin: auto;
}
