@import '../../theme/newVariables.scss';

.toastContainer {
  z-index: 101;
  margin-top: $az-spacing-xxs;
  top: $az-spacing-xxs;
  padding-left: $az-spacing-xxs;
  padding-right: $az-spacing-xxs;
}

.fixedToastContainer {
  position: fixed;
  z-index: 101;
  margin: $az-spacing-xxs;
  top: $az-spacing-60;
  width: calc(#{$az-percent-100} - #{$az-spacing-m});

  @media #{$extra-large-and-up} {
    top: $az-spacing-4xl;
  }
}
