.navLinkContainer {
  display: block;
  padding: var(--st-unit-2) var(--st-unit-5);
  min-height: var(--st-unit-11);

  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
}

.navLinkContainerNested {
  padding-left: var(--st-unit-8);
}
