.modalRoot {
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  z-index: 1600;
  overflow: auto;

  ~ div[role='tooltip'] {
    z-index: 1599;
  }
}

div[role='tooltip'].modalTooltip {
  z-index: 1601;
}
