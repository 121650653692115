@import '../../../theme/newVariables';

.needHelpTab {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $az-medium-red;
  color: $az-white;
  padding: 6px 4px;
  border-radius: 4px;
  position: fixed;
  right: 0px;
  bottom: 20px;
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  cursor: pointer;
  user-select: none;
  z-index: 3;

  .needHelpTabIcon {
    transform: rotate(90deg);
  }

  .needHelpTabLabel {
    margin-top: 5px;
    font-size: 12px;
    text-transform: uppercase;
    font-family: $font-az-cond-medium;
  }
}

.needHelpTabBrands {
  bottom: 99px;
}

.needHelpTabInCart {
  &:lang(en) {
    @media #{$medium-only} {
      bottom: 198px;
    }

    @media #{$small-and-down} {
      bottom: 203px;
    }
  }

  &:lang(es) {
    @media #{$medium-only} {
      bottom: 152px;
    }

    @media #{$small-and-down} {
      bottom: 144px;
    }
  }
}

.needHelpTabInCartWithSTSForMX {
  &:lang(es) {
    @media #{$medium-only} {
      bottom: 122px;
    }

    @media #{$small-and-down} {
      bottom: 138px;
    }
  }
}

.qualtricsIframe {
  border: 0;
  width: 100%;
  height: 80vh;
}

.contactUsModalBody {
  display: flex;
  flex-direction: column;
  max-width: 362px;
  margin: 0 auto;

  .azLogoWrapper {
    max-width: 196px; // wrapper to match the design
  }

  .modalHeader {
    font-family: $font-az-cond-heavy;
    text-transform: uppercase;
    font-size: 28px;
    margin: 16px 0px 24px 0px;
    white-space: nowrap;

    @media #{$medium-and-down} {
      font-size: 18px;
    }
  }

  .contactUsOption {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid $az-black;
    margin-bottom: 16px;
    padding: 14px;
    cursor: pointer;

    &.isDisabled {
      display: none;
    }

    .iconAndTitle {
      display: flex;
      align-items: center;
      margin-bottom: 4px;

      .title {
        margin-left: 6px;
        font-family: $font-az-cond-medium;
        font-size: 14px;
        text-transform: uppercase;
        color: $az-black;
      }
    }

    .subtext {
      font-size: 12px;
      font-family: $font-az-helvetica;
      color: $az-black;
      text-align: center;
    }
  }
}
