.root {
  display: block;
  background-color: #e6e6e6;
  height: 1.2em;
}

.text {
  margin-top: 0;
  margin-bottom: 0;
  height: auto;
  transform-origin: 0 60%;
  transform: scale(1, 0.6);
  border-radius: 4px;

  &:empty::before {
    content: '';
  }
}

.pulse {
  animation: pulse 1.5s ease-in-out 0.5s infinite;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.4;
  }

  100% {
    opacity: 1;
  }
}

.wave {
  position: relative;
  overflow: hidden;

  &::after {
    animation: wave 1.6s linear 0.5s infinite;
    background: linear-gradient(90deg, transparent, #d6d6d6, transparent);
    content: '';
    position: absolute;
    transform: translateX(-100%); // Avoid flash during server-side hydration
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
  }
}

@keyframes wave {
  0% {
    transform: translateX(-100%);
  }

  60% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(100%);
  }
}
