@import '../../theme/breakPoints.scss';
@import '../../theme/spacingSizes.scss';
@import '../../theme/percentages.scss';

/** Styles applied to the root element. */
.root {
  z-index: 5000;
  position: fixed;
  display: flex;
  left: $az-spacing-4xs;
  right: $az-spacing-4xs;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;

  /** Styles applied to the root element if `anchorOrigin.vertical === 'top'`. */
  &.anchorOriginTop {
    top: $az-spacing-s;
  }

  /** Styles applied to the root element if `anchorOrigin.vertical === 'bottom'`. */
  &.anchorOriginBottom {
    bottom: $az-spacing-s;
  }

  /** Styles applied to the root element if `anchorOrigin.horisontal === 'left'`. */
  &.anchorOriginLeft {
    justify-content: flex-start;
    left: $az-spacing-s;
    right: auto;
  }

  /** Styles applied to the root element if `anchorOrigin.horizontal === 'right'`. */
  &.anchorOriginRight {
    justify-content: flex-end;
    right: $az-spacing-s;
    left: auto;
  }

  /** Styles applied to the root element if `anchorOrigin.horizontal === 'center'`. */
  &.anchorOriginCenter {
    left: $az-percent-50;
    right: 'auto';
    transform: translateX(-50%);
  }

  .contentRoot {
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    color: #fff;
    background-color: rgb(50, 50, 50);
    display: flex;
    align-items: center;
    padding: 6px 16px;
    border-radius: 4px;
    flex-grow: initial;
    min-width: 288px;

    @media #{$small-and-down} {
      flex-wrap: nowrap;
    }
  }

  /* Styles applied to the message wrapper element. */
  .contentMessage {
    padding: 8px 0;
  }

  /* Styles applied to the action wrapper element if `action` is provided. */
  .contentAction {
    display: flex;
    align-items: center;
    margin-left: auto;
    padding-left: 16px;
    margin-right: -8px;
  }
}
