@import '../../theme/newVariables.scss';
@import '../../theme/variables.scss';

// stylelint-disable-next-line block-no-empty
@-webkit-keyframes mui-auto-fill {
}

// stylelint-disable-next-line block-no-empty
@-webkit-keyframes mui-auto-fill-cancel {
}

.root {
  color: rgba(0, 0, 0, 0.87);
  cursor: text;
  display: inline-flex;
  position: relative;
  font-size: 1rem;
  box-sizing: border-box;
  align-items: center;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1.1876em;
  letter-spacing: 0.00938em;
}

.disabled {
  color: rgba(0, 0, 0, 0.38);
  cursor: default;
}

.fullWidth {
  width: 100%;
}

.input {
  font: inherit;
  color: currentColor;
  width: 100%;
  border: 0;
  height: 1.1876em;
  margin: 0;
  display: block;
  padding: 6px 0 7px;
  min-width: 0;
  background: none;
  box-sizing: content-box;
  animation-name: mui-auto-fill-cancel;
  letter-spacing: inherit;
  animation-duration: 10ms;
  -webkit-tap-highlight-color: transparent;

  .disabled {
    opacity: 1;
  }

  &::placeholder {
    color: currentColor;
    opacity: 0.42;
    transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }

  &::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  &:focus {
    outline: 0;
  }

  &:invalid {
    box-shadow: none;
  }

  &:-webkit-autofill {
    animation-name: mui-auto-fill;
    animation-duration: 5000s;
  }
}

label[data-shrink='false'] + .formControl .input::placeholder {
  opacity: 0 !important;
}

label[data-shrink='false'] + .formControl .input:focus::placeholder {
  opacity: 0.42;
}

.inputMarginDense {
  padding-top: 3px;
}

.inputMultiline {
  height: auto;
  resize: none;
  padding: 0;
}

.inputTypeSearch {
  -moz-appearance: textfield;
  -webkit-appearance: textfield;
}

.inputTypeDate {
  color: transparent !important;
  -webkit-appearance: none;

  &:focus {
    color: $az-grey-14 !important;
  }

  /* stylelint-disable-next-line -- required as it conflicts with :focus order */
  &::-webkit-calendar-picker-indicator {
    color: $az-grey-5;
    width: 30px;
    height: 30px;
    position: relative;
    top: -13px;
    right: -10px;
    cursor: pointer;
    background-image: url('/images/nativeCalendar.svg');

    &:hover {
      background-color: $az-lighter-gray;
      border-radius: 30px;
    }
  }
}

.value {
  color: $az-grey-14 !important;
}

.error {
  box-shadow: inset 0 -3px 0 0 $az-color-error;
}
