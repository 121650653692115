.defaultIconStyle {
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  flex-shrink: 0;
  font-size: 1.5rem;
}

.fontSizeInherit {
  font-size: inherit;
}

.fontSizeSmall {
  font-size: 1.25rem;
}

.fontSizeLarge {
  font-size: 2.1875rem;
}
