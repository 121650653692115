@import './nav.module.scss';

@media print {
  .appNavigation {
    display: none;
  }
}

.customDrawer {
  .toolbarIconButton {
    margin-right: -12px; // 12px is IconButton padding
  }
}
.menuSection {
  margin-top: $az-spacing-s;
  @media #{$small-and-down} {
    margin-top: $az-spacing-xxs;
  }
}
