.root {
  color: rgba(0, 0, 0, 0.54);
  padding: 0;
  font-size: 1rem;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.00938em;

  .focused {
    color: #3f51b5;
  }

  .disabled {
    color: rgba(0, 0, 0, 0.38);
  }

  .error {
    color: #f44336;
  }
}

.colorSecondary.focused {
  color: #f50057;
}

.asterisk.error {
  color: #f44336;
}
