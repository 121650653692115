/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

/*
 * Manual overrides for ConfigureTech styling for customizeable skus
 */

/* Generate an import for a font from this path /Users/danny.thompson/Desktop/web-ui/*/

/* Import the font from the path above */


#productContainer.BHTJ{
  padding: 0 16px;
}

.CLRB .cofig-tech .panel-body-content {
  padding: 10px 16px 0 !important;
}

.cofig-tech {
  line-height: .5 !important;
}

.cofig-tech .conf-apply-emb-blk-item {
  margin: 0 10px;
  padding: 15px 0 10px;
}

.cofig-tech .conf-apply-emb-blk-item input[type=checkbox] {
  position: relative !important;
  vertical-align: -webkit-baseline-middle !important;
  accent-color: #F26D0D !important;
  margin-top: -7px !important;
}

.cofig-tech .conf-apply-emb-blk-item span.ng-binding {
  padding-left: 0px !important;
}


.cofig-tech .conf-form-select.active, .cofig-tech .conf-form-select:focus, .cofig-tech .conf-form-select {
  border-radius: 8px !important;
  padding: 6px 12px;
}

#ng-controller .cofig-tech .conf-form-select.conf-marb-10.active {
  color: #202124 !important;

}

#navigatorForm select {
  font-size: 14px !important;
  line-height: 22px !important;
  margin-top: 0px !important;
  color: #202124 !important;
  height: 40px !important;
  font-family: "Helvetica Neue", "Roboto", "Arial", sans-serif !important;
  font-weight: 400 !important;
  letter-spacing: 0.00938em !important;
  background-color: #fff !important;
  background-image: url('/images/downChevron.svg') !important;
  background-repeat: no-repeat !important;
  background-position: 97% 50% !important;
  background-size: 16.5px 9px;
  color: #202124 !important;
  cursor: hand !important;
  display: inline-flex !important;
  position: relative !important;
  box-sizing: border-box !important;
  align-items: center !important;
  border: 1px solid #D6D6D6;
}

.BHTJ .cofig-tech .conf-marb-10, .CLRB .cofig-tech .conf-marb-10{
  margin-bottom: 16px !important;
  margin-top: 29px !important;
}

.cofig-tech select[disabled="disabled"]:disabled {
  color: #949494 !important;
  background: #F2F2F2 !important;
   cursor: hand !important;
  display: inline-flex !important;
  position: relative !important;
  box-sizing: border-box !important;
  align-items: center !important;
   border: 1px solid #D6D6D6 !important;
   background-image: url('/images/configTechDownChevron.svg') !important;
  background-repeat: no-repeat !important;
  background-position: 96% 51% !important;
  background-size: 16.5px 9px !important;
  padding: 6px 16px !important;
}

.CLRB .cofig-tech select[disabled="disabled"]:disabled {
  padding: 6px 14px !important;
}

.cofig-tech button[disabled="disabled"]:disabled {
  background: #F2F2F2 !important;
  border: none !important;
  color: #949494 !important;
}

.cofig-tech .btn-select label{
  color: #202124 !important;
  font-weight: unset !important;
  border: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2px !important;
}

.cofig-tech .btn-select {
  border: none !important;
  height: 47px !important;
}

.CLRB .cofig-tech p {
  margin-bottom: 10px !important;
}
.cofig-tech p {
  margin-bottom: 14px !important; /* might seem an odd number to put but the space between text and dropdown got to be 48px */
}

.conf-product-blk {
  border-top: 1px solid #D6D6D6 !important;
}

#ng-controller h4.conf-title-3 {
  font-size: 16px !important;
  font-weight: 700;
  line-height: 24px;
  font-family: Arial, Helvetica, sans-serif;
  color: #202124 !important;
  margin: 0 0 8px !important;
}

.cofig-tech {
  color: #202124 !important;
}

#ng-controller .config-product-gallery {
  border: 1px solid #D6D6D6;
}

.conf-title.mar-b0.ng-binding {
  color: #202124 !important;
}
.cofig-tech .conf-btn-primary:active{
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.BHTJ .cofig-tech.btn.active,.cofig-tech.btn:active {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.cofig-tech .btn-select span+span {
  color: unset !important;
  font-weight: unset !important;
  color: unset !important;
  line-height: 19.6px !important;
  width: 52px !important;
}

@media(min-width: 360px) {
  .cofig-tech .btn-select span+span {
    line-height: 19.6px !important;
  }
}

.con-mob-right-pnl {
  padding-left: 97px !important;
  display: flex !important;
  align-items: center !important;
}

@media (max-width: 767px){
  .BHTJ .con-mob-right-pnl {
      padding-left: 84px !important;
  }
}

@media(min-width: 900px) {
  .con-mob-right-pnl {
    padding-left: 145px !important;
  }
}
.conf-header-box-title {
  font-size: 18px;
  line-height: 48px;
  padding: 0 15px;
  color: #202124 !important;
  font-weight: 700;
  background: #E6E6E6 !important;
  font-family: "Helvetica Neue", "Arial", sans-serif !important;
  font-style: normal;
}

.conf-header-box {
  background: #fff !important;
  border: 1px solid #878686;
}

.panel-body-content {
  background-color: #fff;
}

.conf-mat-type-content {
  background-color: #fff !important;
  color: #202124 !important;
}

.conf-mat-type-footer {
  background-color: transparent !important;
}

.btn-select {
  width: auto !important;

  border: 1px solid #6E6E6E !important;
  border-radius: 4px !important;
  background-color: #fff;
}

.cofig-tech .btn-select span.icon-checkbox {
  display: flex !important;
  justify-content: center;
  align-items: center;
  height: 45px !important;
  border-radius: 50% !important;
  background: white !important;
  width: 45px !important;
  border-right: none !important;
  margin: 0 4px;
  position: relative;
  transition: all .4s ease-in-out;
}

@media(max-width: 700px) {
  .cofig-tech .btn-select span.icon-checkbox {
    margin: 0;
  }
}
@media(min-width: 300px) {
  .cofig-tech .btn-select span.icon-checkbox {
    margin: 0;
  }
}

.CLRB .cofig-tech .btn-select span.icon-checkbox {
  margin: 0;
}
@media(min-width: 900px) {
  .cofig-tech .btn-select:hover span.icon-checkbox {
  background: #D6D6D6 !important;
}
}



.cofig-tech .btn-select span.icon-checkbox input {
  position: absolute !important;
  top: 10.25px !important;
  bottom: 0;
  left: 10px !important;
  right: 0;
  z-index: 10;
}

.icon-checkbox input {
  height: 24px !important;
  width: 24px !important;
  cursor: pointer !important;
  accent-color: #F26D0D;
  outline: none;
  border: 2px solid #949494;
  border-radius: 4px;
  appearance: auto;
}
.cofig-tech input[type=checkbox], .cofig-tech input[type=radio] {
  margin: 0 !important;
}

#navigatorForm  select.active[ng-model="year"]  {
  border: 1px solid #D6D6D6 !important;
  border-bottom: 2px solid #F26D0D !important;
}
#navigatorForm select[ng-model="year"] {
  border-bottom: 2px solid #F26D0D !important;
  border: 1px solid #202124;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
}

#navigatorForm select.active[ng-model="make"] {
  border: 1px solid #D6D6D6 !important;
  border-bottom: 2px solid #F26D0D !important;
}

#navigatorForm  select.active[ng-model="model"] {
  border: 1px solid #D6D6D6 !important;
  border-bottom: 2px solid #F26D0D !important;
}

#navigatorForm select.active[ng-model="vehicle_unique_1"] {
  border: 1px solid #D6D6D6 !important;
  border-bottom: 2px solid #F26D0D !important;
}
#navigatorForm select[ng-model="vehicle_unique_1"] {
  border-bottom: 2px solid #F26D0D !important;
}



 .conf-header-box-content {
  padding: 16px 10px 0 !important;
}

.BHTJ .cofig-tech p{
  margin-bottom: 0 !important;
}

#optionGroupPlaceHolder {
  margin-bottom: 20px;
}


@media(min-width: 700px) {
  #optionGroupPlaceHolder {
    margin-bottom: 32px;
  }
}

#optionGroup {
  font-size: 14px !important;
  line-height: 22px !important;
  font-weight: 400 !important;
  color: #202124 !important;
  border: 1px solid #d6d6d6 !important;
  background-image: url('/images/configTechDownChevron.svg') !important;
  background-repeat: no-repeat !important;
  background-position: 96% 51% !important;
  background-size: 16.5px 9px;
  border-radius: 8px !important;
  padding: 6px 16px !important;
}

#optionGroup option[value="? undefined:undefined ?"] {
  display: none;
}

.col-sm-7.col-sm-offset-5.conf-product-blk-footer.text-left.clearfix {
  bottom: -10px !important
}


.cofig-tech .conf-font-dark {
  color: #202124 !important;
}
.cofig-tech .conf-prod-fil-items h5 {
  font-size: 14px;
  line-height: 36px;
  font-weight: 700;
  margin: 0;
}

.cofig-tech .conf-change-vehicle a {
  color: #202124 !important;
}

.cofig-tech .btn {
  color: #202124;
  border-radius: 4px !important;
  font-family: 'AutoZoneCond', 'Helvetica Neue', Helvetica, Arial, Roboto, sans-serif !important;
  font-size: 16px !important;
  height: 44px !important;
  text-transform: uppercase !important;
  font-weight: 500 !important;
  line-height: 14px !important;
  padding: 0;
  border: none;
  background: none;
}

 .cofig-tech .btn-showmore {
  border: none !important;
  background: none !important;
  padding: 2px 10px 0 40px !important;
  font-size: 10px !important;
  border: none !important;
  font-family: Arial, Roboto, san-serif !important;
}
.icon-plus {
  background: url('/images/configTechDownChevron.svg') 0px 16px no-repeat !important;
  border-right: none !important;
  width: 36px;
  height: 36px;
}

.cofig-tech .conf-product-blk.active .btn-showmore .icon-plus {
  background: url('/images/configTechUpChevron.svg') 0px 16px no-repeat !important;

}

.cofig-tech .btn.active {
  color: #ffffff !important;
  font-family: 'AutoZoneCond', 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif !important;
  font-size: 16px !important;
  height: 44px !important;
  text-transform: uppercase !important;
  border: none !important;
}

.CLRB .cofig-tech .btn.active {
  background: #202124 !important;
  color: white;
  font-family: 'AutoZoneCond', 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif !important;
  font-size: 16px !important;
  height: 44px !important;
  text-transform: uppercase !important;
  border: none !important;
}
.conf-header-box-footer {
  padding: 16px !important;
}
.cofig-tech .conf-btn-primary {
  background: #202124 !important;
  color: #ffffff !important;
  border-radius: 4px !important;
  font-family: 'AutoZoneCond-Medium',' Helvetica Neue', Helvetica, Roboto, Arial, sans-serif !important;
  font-size: 16px;
  height: 51px !important;
  width: 142px !important;
  text-transform: uppercase !important;
}

.cofig-tech .panel-body .conf-header-box-footer {
  padding: 16px 0 !important;
}

.conf-price {
  top: -5px !important;
  vertical-align: middle !important;
  margin-bottom: 0px !important;
  font-size: 16px !important;
  left: 11px !important;
  color:#202124 !important;
  height: unset !important;
  margin-top: 12px !important;
  margin-right: 8px;
}

@media(max-width: 760px) {
  .conf-price {
    margin-right: 1px;
    font-size: 16px !important;
  }
}

.conf-mat-type-item .conf-mat-type-footer {
  display: flex;
  align-items: center;
}

@media(max-width: 760px) {
 .conf-mat-type-item .conf-mat-type-footer {
    justify-content: right;
  }
}

#ng-controller [type='radio'] {
  width: 19px;
  accent-color: #202124;
  opacity: 1;
}

.conf-color-blk:hover {
  border-color: #c9c4bf !important;
}

.conf-list-normal li {
   font-size: 12px;
   font-family: Arial, Helvetica, sans-serif;
   font-weight: 400;
   line-height: 24px;
}

.conf-inner-con {
  background: #fff !important;
}
.CLRB .conf-color-blk-selection span.select {
  padding-left: 3px !important;
}
@media(max-width: 760px) {
  .CLRB .conf-color-blk-selection span.select {
    margin-right: 0 !important;
  }
}
.conf-color-blk-selection label span.select {
  display: flex !important;
  align-items: center;
}

.conf-color-blk-selection.conf-radio-center label span.select {
  justify-content: center;
}

.conf-color-blk-selection:hover, .conf-color-blk-selection:hover label span{
  background-color: #F26D0D !important;
  color: #fff !important;
}

.conf-color-blk-selection label:hover {
  color: unset !important;
  font-weight: 700 !important;
}

.conf-color-blk-selection label span:hover {
  background-color: #F26D0D !important;
  color: #fff !important;
}

.conf-color-blk-selection .conf-label-value, .conf-embroid-head{
  color: #202124 !important;
}

input[type="checkbox"]#checkbox1:checked + span {
  border-color: red !important;
  background-color:red !important;
}

.cofig-tech .btn-showmore:hover {
  color: #202124 !important;
}

.cofig-tech .conf-btn-normal, .BHTJ .cofig-tech.conf-btn-normal  {
  color: #202124 !important;
  background-color: #fff !important;
  border: 1px solid #202124 !important;
}

.CLRB .conf-embroid-one {
  border: 2px solid #D3CDC8 !important;
}

.CLRB .conf-embroid-one:hover {
  border: 2px solid #F26D0D !important;
}

.CLRB .conf-embroid-one:hover:has(div.conf-color-blk-selection) {
  border: 2px solid #D3CDC8 !important;
}

.btn .conf-btn-primary {
  background: #202124 !important;
  color: #ffffff !important;
  border-radius: 4px !important;
  font-family: 'AutoZoneCond', 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif !important;
  font-size: 16px;
  height: 52px;
  text-transform: uppercase !important;
}
.btn .conf-btn-primary:active{
  border-style: outset !important;
}
.modal-open .modal {
  z-index: 10500 !important;
}

/*
  remove the long string with info that the BE wants but customer doesn't care about
  doing this also fixes the issue on mobile that makes the screen too big since a long
  continous string doesn't word wrap properly.
*/
.pad-t2 {
  display: none !important;
}

.CLRB .conf-prod-fil-header, .BHTJ .conf-prod-fil-header {
  background: #FFFFFF !important;
  border: 1px solid #6E6E6E !important;
  border-radius: 4px;
  padding: 5px 10px;
}
.CLRB .conf-prod-fil-header p {
  margin-bottom: 0 !important;
}

.CLRB .row.clearfix, .BHTJ .row.clearfix{
  display: block;
}

@media(max-width: 720px) {
  .CLRB .conf-prod-fil-header, .BHTJ .conf-prod-fil-header {
    border-radius: 0px;
  }
}

.CLRB h5 .ng-binding, .BHTJ h5 .ng-binding {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 36px;
  margin: 0 !important;
}

.CLRB .cofig-tech a.conf-underline, .BHTJ .cofig-tech a.conf-underline {
  line-height: 36px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 700;
  font-size: 12px;
  text-decoration: underline !important;
}

.CLRB .row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: flex-start;
}

.BHTJ .conf-prod-fil-header .conf-prod-fil-items, .CLRB .conf-prod-fil-header .conf-prod-fil-items {
  flex: auto;
  padding-bottom: 0 !important;
}
.conf-prod-fil-header .conf-prod-fil-items h5 {
  margin: 0 !important;
}

.CLRB .cofig-tech .config-tabbed .panel-heading {
  background: white;
  color:#202124 !important;
}

.panel-title {
  color:#202124 !important;
}
.cofig-tech .conf-text-border {
  color:#202124 !important;
}
.cofig-tech .conf-change-vehicle a, .cofig-tech .conf-edit {
  color: #202124 !important;
}

.CLRB .cofig-tech .config-tabbed .panel-title {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #141414;
}

.CLRB .conf-apply-emb-blk-header {
  background: #E6E6E6 !important;
}
.expanded {
  background: #E6E6E6 !important;
}

.BHTJ .cofig-tech p{
  margin-bottom: 0 !important;
}

.BHTJ .conf-product-more-content {
  padding: 12px 0 0 !important;
}

.BHTJ.cofig-tech ul {
  margin-top: 0 !important;
}

.BHTJ .cofig-tech .conf-text-btm-border {
  border-bottom: none !important;
}
.BHTJ .conf-list-normal {
  margin-bottom: 20px !important;
}
.BHTJ .conf-list-normal ul {
  padding-left: 15px !important;
  margin-top: 0 !important;
}

.BHTJ .conf-product-more-content .conf-list-normal li {
  list-style-type: disc;
  font-size: 14px;
  margin-left: 16px;
  line-height: 24px;
}

.CLRB .cofig-tech .conf-form-select.active {
  padding-right: 30px !important;
}

  .CLRB .conf-color-blk-header.imageRectangle .conf-img-res-container .img-contain div img {
    min-height: 80px
  }

  .CLRB .conf-color-blk-header.imageRectangle {
    min-height: 80px
  }

@media (max-width: 760px) {
  .CLRB .conf-color-blk-header.imageRectangle .conf-img-res-container .img-contain div img {
    min-height: 57px;
  }
  .CLRB .conf-color-blk-header.imageRectangle {
    min-height: 57px !important;
  }
  .CLRB .cofig-tech .conf-form-select.active {
    min-height: 57px !important;
  }
}


.CLRB .cofig-tech .conf-form-select {
  background-image: url('/images/downChevron.svg') !important;
  background-repeat: no-repeat !important;
  background-position: 98% 50% !important;
  background-size: 16.5px 9px;
}

.CLRB .cofig-tech .conf-form-select.active {
  padding-right: 27px !important;
}


.BHTJ .conf-btm-margin {
  margin: 35px 0 0 0 !important;
}

.BHTJ .cofig-tech .container-fluid {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

@font-face {
  font-family: 'AutoZoneCond-Medium';
  src: url('/fonts/AutoZoneCond-Medium.eot');
  src: url('/fonts/AutoZoneCond-Medium.eot?iefix') format('embedded-opentype'),
    url('/fonts/AutoZoneCond-Medium.woff2') format('woff2'),
    url('/fonts/AutoZoneCond-Medium.woff') format('woff'),
    url('/fonts/AutoZoneCond-Medium.ttf') format('truetype'),
    url('/fonts/AutoZoneCond-Medium.svg#AutoZoneCond-Medium') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
