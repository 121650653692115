@import '../../../theme/newVariables';

.modalSpinner {
  width: $az-spacing-100;
  z-index: 99999;
  opacity: 1;
  position: fixed;
  top: $az-percent-40;
  left: calc(#{$az-percent-50} - #{$az-spacing-50});

  @media #{$extra-large-and-down} {
    width: $az-spacing-100;
    left: calc(#{$az-percent-50} - #{$az-spacing-50});
  }
}

.dialog {
  display: block;
  z-index: 9999;
  background-color: $az-grey-5;
  opacity: 0.2;
  margin: $az-spacing-zero auto;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: $az-spacing-zero;
  left: $az-spacing-zero;
}
