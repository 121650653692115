@import '../../theme/spacingSizes.scss';

.root {
  display: flex;
  padding: $az-spacing-6 $az-spacing-xxs;
  font-size: 0.875rem;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1.43;
  border-radius: $az-spacing-5xs;
  letter-spacing: 0.01071em;
  background-color: transparent;

  .icon {
    display: flex;
    opacity: 0.9;
    padding: $az-spacing-7 $az-spacing-zero;
    font-size: 22px;
    margin-right: $az-spacing-xxxs;
  }

  .message {
    padding: $az-spacing-4xs $az-spacing-zero;
  }

  .action {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: -8px;
    padding-left: $az-spacing-xxs;
  }

  &.standardSuccess {
    color: rgb(30, 70, 32);
    background-color: rgb(237, 247, 237);

    .icon {
      color: #4caf50;
    }
  }

  &.standardInfo {
    color: rgb(13, 60, 97);
    background-color: rgb(232, 244, 253);

    .icon {
      color: #2196f3;
    }
  }

  &.standardWarning {
    color: #202124;
    box-shadow: inset 4px 0 0 0 #ffc600, 0 3px 1px -2px rgba(0, 0, 0, 0.2),
      0 2px 2px 0 rgba(32, 33, 36, 0.14), 0 1px 5px 0 rgba(32, 33, 36, 0.12);
    border-radius: $az-spacing-zero;
    background-color: #fff;

    .icon {
      color: #ff9800;
    }
  }

  &.standardError {
    color: rgb(97, 26, 21);
    background-color: rgb(253, 236, 234);

    .icon {
      color: #f44336;
    }
  }

  &.outlinedSuccess {
    color: rgb(30, 70, 32);
    border: 1px solid #4caf50;

    .icon {
      color: #4caf50;
    }
  }

  &.outlinedInfo {
    color: rgb(13, 60, 97);
    border: 1px solid #2196f3;

    .icon {
      color: #2196f3;
    }
  }

  &.outlinedWarning {
    color: rgb(102, 60, 0);
    border: 1px solid #ff9800;

    .icon {
      color: #ff9800;
    }
  }

  &.outlinedError {
    color: rgb(97, 26, 21);
    border: 1px solid #f44336;

    .icon {
      color: #f44336;
    }
  }

  &.filledSuccess {
    color: #fff;
    font-weight: 500;
    background-color: #4caf50;
  }

  &.filledInfo {
    color: #fff;
    font-weight: 500;
    background-color: #2196f3;
  }

  &.filledWarning {
    color: #fff;
    font-weight: 500;
    background-color: #ff9800;
  }

  &.filledError {
    color: #fff;
    font-weight: 500;
    background-color: #f44336;
  }
}
