@import '../../../theme/newVariables.scss';

.headerContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-height: $az-spacing-xxl;
  padding-top: $az-spacing-xs;
  padding-left: $az-spacing-m;
  padding-right: $az-spacing-xxs;

  @media #{$small-and-down} {
    background-color: $az-black;
    padding-top: $az-spacing-zero;
    padding-left: $az-spacing-xxs;
    padding-right: $az-spacing-zero;
  }

  .headerTitle {
    font-family: $font-az-cond-medium;
    font-weight: unset;
    text-transform: uppercase;
    color: $az-black;
    font-size: $font-size-20;
    letter-spacing: $az-spacing-three-fourths;
    line-height: $az-spacing-25;

    @media #{$small-and-down} {
      color: $az-white;
      font-size: $font-size-16;
      letter-spacing: 0.14px;
      line-height: $az-spacing-xs;
    }
  }
}

.backButton {
  border: none;
  background: none;
  padding: $az-spacing-zero;
  cursor: pointer;
  display: flex;
  line-height: $az-spacing-18;
  font-size: $font-size-16;
  font-family: $font-az-cond-medium;
  letter-spacing: $az-spacing-three-fourths;
  text-transform: uppercase;

  @media #{$small-and-down} {
    color: $az-white;
  }
}

.backIcon {
  font-size: $font-size-18 !important;
  margin-right: $az-spacing-5;
  color: $az-medium-orange;

  @media #{$small-and-down} {
    color: $az-white;
  }
}

.closeIcon {
  @media #{$small-and-down} {
    color: $az-white;
  }
}
