@import '../../../theme/newVariables';

.mainMenuWrapper {
  .navBarHeaders {
    display: inline-block;
    color: $az-black;
    font-size: $font-size-16;

    @media #{$large-and-down} {
      color: $az-black;
      padding: $az-spacing-xxs $az-spacing-xxs $az-spacing-4xs;
    }

    @media #{$small-and-down} {
      &.navBarHeadersShopAll {
        padding: $az-spacing-5xs $az-spacing-xxs $az-spacing-4xs;
      }

      &.navBarHeadersShopDeals {
        padding: $az-spacing-4xs $az-spacing-xxs $az-spacing-4xs;
        outline: none;
      }
    }

    @media #{$extra-large-and-up} {
      padding: $az-spacing-xxs $az-spacing-xs $az-spacing-4xs;

      &.navBarHeadersShopAll {
        padding: $az-spacing-4xs $az-spacing-xs $az-spacing-4xs;
      }
    }
  }
}

.navBarSubheader {
  text-decoration: underline;
  width: $az-percent-100;
}

.navBarMenuShopAll {
  padding: $az-spacing-4xs $az-spacing-xs $az-spacing-4xs !important;
  width: $az-percent-100 !important;

  &:hover,
  &:focus {
    font-family: $font-az-helvetica-medium;
    font-weight: bold;
  }
}

li.navBarMenuItem {
  padding: $az-spacing-4xs $az-spacing-xxs;
  width: $az-percent-100;

  &:hover,
  &:focus {
    font-family: $font-az-helvetica-medium;
    font-weight: bold;
  }

  &:focus {
    outline: $az-spacing-5 auto -webkit-focus-ring-color;
  }

  @media #{$extra-large-and-up} {
    padding: $az-spacing-4xs $az-spacing-xs;
  }
}

.navBarMenuItem {
  display: flex;
  align-items: $cdirection;
  text-transform: capitalize;
  width: $az-percent-100;
  padding: $az-spacing-5xs $az-spacing-zero $az-spacing-5xs $az-spacing-zero;
}

.backButtonSpacing {
  padding-top: $az-spacing-4xs;
  margin-left: $az-spacing-s;
}

.backButton {
  padding: $az-spacing-4xs $az-spacing-xxs;
  text-transform: uppercase;
  font-family: $font-az-cond-medium;
  font-size: $font-size-16;
  letter-spacing: $az-spacing-three-fourths;
  font-weight: 500;
  font-stretch: condensed;
  color: $az-black;
  border: none;
  background-color: $az-white;
  text-align: $ldirection;
  display: flex;
  align-items: $cdirection;
  cursor: pointer;
  width: $az-spacing-100;

  img {
    margin-right: $az-spacing-5xs;
    width: $az-spacing-xxs;
    height: $az-spacing-xxs;
  }

  @media #{$medium-only} {
    padding: $az-spacing-4xs $az-spacing-xxs $az-spacing-zero;
  }

  @media #{$extra-large-and-up} {
    padding: $az-spacing-4xs $az-spacing-xs;
  }
}

.closeButton {
  width: $az-spacing-s !important;
  height: $az-spacing-s !important;
  padding: $az-spacing-zero !important;
}

.backArrow {
  margin-right: $az-spacing-4xs;
  position: relative;
  top: $az-spacing-neg-2;
}

.listItem {
  cursor: pointer;
  padding: $az-spacing-5 $az-spacing-14;
  display: block;
  width: $az-percent-100;
  color: $az-black !important;
}

.menuImgOpen {
  @media #{$medium-and-up} {
    margin: $az-spacing-5xs $az-spacing-zero;
    outline: none;
  }

  &.menuImgOpenSticky {
    @media #{$extra-large-and-up} {
      margin-top: 0;
      padding-bottom: 2px;
    }
  }
}

.menuImgClose {
  margin: $az-spacing-3 $az-spacing-zero;
}

.menuText {
  font-size: $font-size-12;
  text-align: $cdirection;
  margin-top: $az-spacing-neg-13;
  display: block;
  font-family: $font-az-helvetica-light;
  letter-spacing: 0.25px;
  text-transform: capitalize;
  color: $az-grey-5;

  &.menuTextSticky {
    color: $az-black;
  }

  @media #{$medium-only} {
    font-size: $font-size-10;
  }

  @media #{$small-and-down} {
    font-size: $font-size-12;
    color: $az-black;
    font-weight: normal;
    padding-top: $az-spacing-4xs;
    margin-bottom: -$az-spacing-4xs;
  }

  @media #{$medium-and-up} {
    position: relative;
    top: $az-spacing-3;
  }
}

@media #{$extra-large-and-down} {
  .backIcon {
    margin-top: $az-spacing-neg-10 !important;
  }

  .backText {
    display: inline-block;
    color: $az-white !important;
    font-size: $font-size-16 !important;
    line-height: $az-spacing-l !important;
  }

  .sideMenuItemHeading {
    font-size: $font-size-12;
    color: $az-grey-4;
    margin: $az-spacing-10 $az-spacing-zero $az-spacing-10 $az-spacing-25;
  }

  .showAll {
    display: block;
    color: $az-grey-5 !important;
    text-decoration: underline;
    margin-left: $az-spacing-27;
    padding-bottom: $az-spacing-4xs;
  }
}

.navHi {
  float: $ldirection;
  color: $az-grey-4;
  font-family: $font-az-helvetica-light;
  text-transform: capitalize;
  letter-spacing: $az-spacing-one-point-five;
  font-size: $font-size-12;
  width: auto;
  text-decoration: underline;
}

.navBarUserIcon {
  float: $ldirection;
  width: $az-spacing-xxs;
  height: $az-spacing-xxs;
}
.navBarUserIcon::before {
  left: 50%;
  top: 6%;
  min-width: 3px;
  border-radius: 50%;
  background: #f26100;
  color: $az-white;
  padding: 3px;
  position: absolute;
  content: "'' ";
}

.navBarUserSpan {
  display: flex;
  justify-content: space-between;
  align-items: $cdirection;
  width: $az-percent-33;

  @media #{$small-and-down} {
    width: auto;
    height: $az-spacing-22;
    margin-top: $az-spacing-neg-2;
    justify-content: flex-start;
    align-items: flex-end;
  }
}

.navBarRewards {
  font-family: $font-az-helvetica-light;
  font-size: $font-size-14;
  font-weight: bold;
  align-self: flex-end;
  float: $rdirection;
  color: $az-color-success;

  @media #{$small-and-down} {
    display: flex;
    justify-content: flex-end;
  }
}

.handleMyAccountActions {
  display: flex;
  justify-content: space-between;
  width: 95vw;
}

.storeLocator {
  @media #{$small-and-down} {
    padding: $az-spacing-6 $az-spacing-xxs;
  }
}

.navMenuContainer {
  li {
    @media #{$small-and-down} {
      width: $az-percent-100 !important;
    }
  }

  a,
  a:active {
    color: $az-black;

    @media #{$medium-and-down} {
      color: $az-black;
    }

    @media #{$small-and-down} {
      padding: $az-spacing-zero;
      width: $az-percent-100;
    }
  }

  .signinWrapper {
    @media #{$small-and-down} {
      display: flex;
      justify-content: space-between;
      padding: $az-spacing-10 $az-spacing-xxs;
    }
  }

  @media #{$extra-large-and-up} {
    .navBarShopDeals {
      margin-bottom: $az-spacing-3;

      * {
        outline: none;
      }
    }

    .navBarRepair {
      margin-top: $az-spacing-5;
      margin-bottom: $az-spacing-neg-8;
    }
  }
}

.navBarOrderStoreContainer {
  display: flex;

  .navBarOrderStoreItemWrap {
    flex-grow: 1;
  }

  .navBarOrderStoreItemWrap:first-of-type {
    border-right: $az-spacing-1 solid rgba(0, 0, 0, 0.12);
  }

  .navBarOrderStore {
    justify-content: space-between !important;
    width: $az-percent-100;
    font-family: $font-az-helvetica;
    font-size: $font-size-12;
    color: $az-grey-6;
    text-decoration: none;

    .nav_item {
      color: $az-grey-6;
      margin-left: $az-spacing-4xs;
    }

    @media #{$small-and-down} {
      padding: $az-spacing-xxxs $az-spacing-xxs;
    }
  }
}

.navMenuContainerShopDeals {
  margin-top: $az-spacing-neg-4;
  margin-bottom: $az-spacing-neg-10;
}

.navMenuContainerShopAll {
  margin-bottom: $az-spacing-neg-5;
}

.navMenuContainerMostPopular {
  margin-bottom: $az-spacing-4xs;
}

.navMenuMostPopularMobile {
  margin-top: $az-spacing-neg-4;
  margin-bottom: $az-spacing-3;
}

.navMenuShopDealsMobile {
  margin-top: $az-spacing-neg-4;
  margin-bottom: $az-spacing-neg-11;
}

.navBarShow {
  font-family: $font-az-helvetica-light;
  font-size: $font-size-14;
  color: $az-grey-5;
  text-decoration: underline;
  border: none;
  background: none;
  padding-left: $az-spacing-zero;

  @media #{$small-and-down} {
    padding: $az-spacing-zero $az-spacing-15 $az-spacing-zero $az-spacing-zero;
  }

  @media #{$medium-and-up} {
    padding-bottom: $az-spacing-4xs;
  }
}

@media #{$medium-only} {
  .paperRoot {
    width: $az-spacing-352;
  }
}

.root {
  // In production builds, the default z-index of the drawer overrides this, hence the need for !important
  z-index: 1503 !important;
}

@media #{$extra-large-and-up} {
  .root {
    padding: $az-spacing-zero;
  }

  .paperRoot {
    overflow: auto;
    width: $az-spacing-376;
  }
}

.paperRootScrolled {
  @media #{$medium-and-up} {
    height: calc(#{$az-percent-100} - #{$az-spacing-xxxl}) !important;
    width: $az-spacing-352;
    top: $az-spacing-67 !important;
    padding-top: $az-spacing-xxs;
    overflow-y: auto !important;
    overflow-x: hidden;
  }

  @media #{$small-and-down} {
    width: $az-percent-100;
  }
}

.paperRoot {
  @media #{$medium-and-up} {
    overflow-x: hidden !important;
    position: initial !important;
  }

  @media #{$small-and-down} {
    width: $az-percent-100;
  }

  @media #{$medium-only} {
    width: $az-spacing-352;
  }
}

.navBarNav {
  @media #{$medium-and-up} {
    height: auto !important;
    background-color: $az-grey-1;
    box-shadow: none;
    margin-right: $az-spacing-xxs;
  }
  &.navBarNavSticky {
    @media #{$medium-and-up} {
      margin-right: 0;
      padding-right: 8px;
    }
  }
}

.navBarMyAccount {
  font-size: $font-size-12;
  float: left;
  color: $az-grey-4;
  font-family: $font-az-helvetica-light;
  text-decoration: underline;
  text-transform: uppercase;
  letter-spacing: $az-spacing-one-point-five;
  width: $az-percent-100;
}

.community {
  display: none;
}

.loadingImage {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: $az-percent-25;
  animation-name: spinner;
  animation-duration: 0.7s;
  animation-iteration-count: infinite;
}

.menuBackMobile {
  text-transform: uppercase;
}

.menuBackButton {
  margin-left: -8px; // 8px is BackButton padding
}

.rootScrolled {
  @media #{$medium-and-up} {
    top: $az-spacing-83 !important;
    padding: $az-spacing-zero;

    .rootBackdropScrolled {
      top: $az-spacing-67 !important;
    }
  }
}

.rootBackdrop {
  position: absolute !important;
}

.menuNavButton {
  border: none;
  border-radius: $az-spacing-5xs;
  background: $az-grey-1;

  @media #{$medium-and-up} {
    padding: $az-spacing-4xs;
    width: $az-spacing-68;
    height: $az-spacing-xl;
  }

  &:hover {
    background: $az-grey-2;
  }

  @media #{$medium-only} {
    width: $az-spacing-52;
  }

  @media #{$small-and-down} {
    background: none;
    min-height: $az-spacing-zero !important;
    padding: $az-spacing-10 !important;
    margin: $az-spacing-neg-3 $az-spacing-zero $az-spacing-zero $az-spacing-neg-10;
  }

  &.menuNavButtonSticky {
    @media #{$medium-and-up} {
      padding: 10px 16px 6px;
      width: auto;
    }

    @media #{$small-and-down} {
      padding: unset !important;
      margin: unset;
    }
  }
}

.trackOrderRightCarat {
  position: relative;
  top: $az-spacing-2;
}

.storeRightCarat {
  position: relative;
  top: $az-spacing-2;
}

.svgCategoryIcons {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: $font-size-24;
  width: $font-size-24;

  img {
    width: $az-spacing-18;
  }
}

@keyframes spinner {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.contentContainer {
  position: relative;
  z-index: 102;
  overflow: auto;
}

.firstHeader {
  margin-top: $az-spacing-s;
}

.sectionContainer {
  padding-bottom: $az-spacing-30;
}

.sectionTitle {
  padding-left: $az-spacing-m;
  padding-right: $az-spacing-m;
  line-height: $az-spacing-s;
  padding-bottom: $az-spacing-6;
  font-size: $az-spacing-xxs;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media #{$small-and-down} {
    padding-left: $az-spacing-xxs;
    padding-right: $az-spacing-xxs;
  }
}

.rawItem {
  padding: $az-spacing-10 $az-spacing-36 !important;
  line-height: $az-spacing-xs !important;
  width: $az-percent-100 !important;
  justify-content: flex-start !important;
  display: flex;

  @media #{$small-and-down} {
    padding: $az-spacing-10 $az-spacing-xs !important;
  }
}

.noStyle {
  padding: 0 !important;
}

.noHover {
  &:hover {
    background-color: unset !important;
  }
}

.itemWithIcon {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
}

.nonHeaderSection {
  padding-bottom: $az-spacing-m;
}

.navBarDivide {
  margin: $az-spacing-s $az-spacing-xs !important;

  @media #{$small-and-down} {
    margin: $az-spacing-s $az-spacing-xxs !important;
  }
}

.topSpacing {
  margin-top: $az-spacing-s;

  @media #{$small-and-down} {
    margin-top: $az-spacing-xxs;
  }
}

.modalCloseBtn {
  display: flex;
  align-self: flex-end;
  width: $az-percent-100;
  padding: $az-spacing-10 $az-spacing-36 !important;
  line-height: $az-spacing-xs !important;
  border: none;
}

.shopAllBtn {
  flex-shrink: 0;
}
