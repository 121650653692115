@import '../../../../theme/newVariables.scss';

.checkoutHeader {
  box-shadow: $az-box-shadow-gray-1;

  .headerBox {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: $az-spacing-zero auto $az-spacing-4xs;
    max-width: $az-spacing-1600;

    @media #{$small-and-down} {
      padding-bottom: $az-spacing-4xs;
      padding-top: $az-spacing-4xs;
      margin-bottom: $az-spacing-xxs;
    }
  }

  .headerBoxV3 {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: $az-spacing-zero auto $az-spacing-1;
    padding: $az-spacing-4xs;
    height: $az-spacing-50;
    max-width: $az-spacing-1440;
    @media #{$large-mid-and-up} {
      padding-right: $az-spacing-zero;
    }
  }

  .azLogoFullV3 {
    max-width: 70%;
  }

  .checkoutTitle {
    font-family: $font-az-cond;
    font-style: normal;
    font-weight: 800;
    font-size: $font-size-18;
    line-height: 24px;
    text-transform: uppercase;
    color: $az-black;
    display: inline;
  }

  .lockIcon {
    top: $az-spacing-2 !important;
  }

  .backButton {
    margin-left: -$az-spacing-4xs;
  }

  .azLogoMedium {
    height: $az-spacing-18;
  }
}
