@import '@/theme/newVariables';
@import '@/theme/fontFamilies';

.suggestionParentCont {
  width: inherit;
  overflow-y: auto;
  overflow-x: hidden;
  &.hiddenOverflow {
    overflow-y: hidden;
  }
}

.searchDrawerContainer {
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  top: $az-spacing-50;
  margin: 0 auto;
  overflow-y: auto;
}

.searchDrawerContainerSticky {
  top: $az-spacing-37;
}

.searchDrawerScrolledHeight {
  max-height: calc(100vh - 75px);
}

.searchDrawerHeight {
  max-height: calc(100vh - 175px);
}

.search,
.searchDrawer {
  display: flex;
  overflow: hidden;
  width: $az-percent-100;
}

.openSearch {
  display: flex;
  position: relative;
  overflow: hidden;
  width: $az-percent-100;
  border-radius: $az-spacing-4xs $az-spacing-4xs $az-spacing-zero $az-spacing-zero;
}

.popHeading {
  display: block;
  width: $az-percent-100;
}

.popupSubText {
  border-bottom: $az-spacing-1 solid $az-grey-2;
}

.popLabel {
  color: $az-grey-4;
}

.navSignIn {
  font-family: $font-az-helvetica;
  font-size: $font-size-12;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 1.5px;
  color: $az-grey-4;
  text-decoration: underline;
}

.navHi {
  color: $az-grey-4;
  font-family: $font-az-helvetica;
  text-transform: capitalize;
  letter-spacing: 1.5px;
  width: auto;
}

.navBarRewards {
  font-weight: bold;
  float: $rdirection;
  color: $az-grey-5;
}

.searchBoxButtons {
  display: flex;
  align-items: center;
  position: absolute;
  top: $az-spacing-zero;
  right: $az-spacing-zero;
  height: $az-percent-100;
  padding-right: $az-spacing-xs;

  @media #{$small-and-down} {
    padding-right: $az-spacing-xxxs;
  }
}

.verticalDivider {
  width: $az-spacing-1;
  height: $az-spacing-xs;
  background-color: #c4c4c4;
  margin: $az-spacing-zero $az-spacing-4xs;

  @media #{$small-and-down} {
    height: $az-spacing-17;
  }
}

.clearButton {
  padding: $az-spacing-4xs;
}

.searchImgButton {
  border: none;
  background: transparent;
  line-height: $az-spacing-xxs;
  background: none;
  border: none;
  padding: $az-spacing-zero $az-spacing-xxs;
  position: absolute;
  height: $az-percent-100;

  @media #{$medium-and-down} {
    padding: $az-spacing-zero $az-spacing-xxxs;
  }
}

.searchImgButtonSticky {
  @media #{$extra-large-and-up} {
    padding: 0 8px;
  }
}

.clearButtonImg {
  display: flex;
  align-self: center;
  height: $az-spacing-17;
  width: $az-spacing-17;
}

.noOutline:focus {
  outline: none;
}
.noMobileCursor:focus {
  @media #{$small-and-down} {
    caret-color: transparent;
  }
}
.mobileInput {
  @media #{$small-and-down} {
    user-select: text;
  }
}
.searchBtn {
  padding: $az-spacing-10 $az-spacing-4xs;
}

.fullSearch {
  @media #{$small-and-down} {
    .searchInput {
      input {
        background: $az-grey-1;
        border-radius: $az-spacing-5xs;
        padding-left: $az-spacing-l;
        padding-right: $az-spacing-130;

        &::placeholder {
          font-family: $font-az-helvetica-medium;
          font-size: $font-size-14;
          letter-spacing: 0.6px;
          color: $az-grey-6;
          opacity: 1;
          position: relative;
        }

        ::-webkit-input-placeholder {
          /* Edge */
          font-family: $font-az-helvetica-medium;
          font-size: $font-size-14;
          letter-spacing: 0.6px;
          color: $az-grey-6;
          opacity: 1;
          position: relative;
          left: $az-spacing-neg-6;
        }

        :-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          font-family: $font-az-helvetica-medium;
          font-size: $font-size-14;
          letter-spacing: 0.6px;
          color: $az-grey-6;
          opacity: 1;
          position: relative;
          left: $az-spacing-neg-6;
        }
      }

      input.fixedNavsearchInput {
        background: $az-white;

        @media #{$small-and-down} {
          padding-right: $az-spacing-4xs;
          right: $az-spacing-zero;
          min-width: $az-spacing-100;
        }

        @media #{$xsmall-mid-and-up} {
          min-width: calc(100% - 16px);
        }

        &::placeholder {
          font-size: $font-size-12;
          letter-spacing: 0.4px;
          opacity: 1;
          position: relative;
          left: $az-spacing-neg-7;
        }

        ::-webkit-input-placeholder {
          /* Edge */
          font-size: $font-size-12;
          letter-spacing: 0.4px;
          opacity: 1;
          position: relative;
          left: $az-spacing-neg-7;
        }

        :-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          font-size: $font-size-12;
          letter-spacing: 0.4px;
          opacity: 1;
          position: relative;
          left: $az-spacing-neg-7;
        }
      }
    }

    .searchButton::before {
      background: $az-grey-1;
      height: $az-spacing-zero;
      width: $az-spacing-zero;
      left: $az-spacing-zero;
    }
  }
}

.searchDrawer {
  padding: $az-spacing-xxs;

  @media #{$small-and-down} {
    .searchInputDrawer {
      border-radius: $az-spacing-5xs;
      border: $az-spacing-zero;
      padding-left: $az-spacing-zero;

      input {
        border-radius: $az-spacing-4xs;
        padding-left: $az-spacing-l;
        &.hasText {
          padding-right: $az-spacing-130;
        }
      }

      &:focus {
        border: $az-spacing-1 solid $az-black;
      }
    }

    .searchButton {
      background: transparent;
      left: $az-spacing-zero;
      border-top-left-radius: $az-spacing-4xs;
      border-bottom-left-radius: $az-spacing-4xs;
      padding-left: $az-spacing-xxs;
    }

    .searchButton::before {
      background: $az-grey-1;
      height: $az-spacing-zero;
      width: $az-spacing-zero;
      left: $az-spacing-zero;
    }
  }
}

.searchInput,
.searchInputDrawer {
  z-index: 1;
  width: $az-percent-100;

  @media #{$small-and-down} {
    position: relative;

    .scrolled {
      margin: $az-spacing-15 $az-spacing-25;
    }
  }

  label {
    width: $az-percent-100;
  }

  input {
    width: $az-percent-100;
    padding: 14.21px $az-spacing-135 14.21px $az-spacing-l;
    outline: none;
    margin: $az-spacing-zero;
    border: none;
    box-sizing: border-box;
    font-size: $font-size-14;
    line-height: 19.6px;
    border-radius: $az-spacing-4xs;
    font-family: $font-az-helvetica-medium;
    color: $az-black;
    text-overflow: ellipsis;

    &::placeholder {
      font-family: $font-az-helvetica-medium;
      font-size: $font-size-14;
      letter-spacing: 0.6px;
      color: $az-grey-5;
      opacity: 1;
    }

    ::-webkit-input-placeholder {
      /* Edge */
      font-family: $font-az-helvetica-medium;
      font-size: $font-size-14;
      letter-spacing: 0.6px;
      color: $az-grey-6;
      opacity: 1;
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      font-family: $font-az-helvetica-medium;
      font-size: $font-size-14;
      letter-spacing: 0.6px;
      color: $az-grey-5;
      opacity: 1;
    }

    &.withNoRightPadding {
      padding-right: $az-spacing-10;
      @media #{$small-and-down} {
        cursor: none;
      }
    }

    @media #{$small-and-down} {
      height: $az-spacing-xl;
      background-color: $az-grey-1;
      color: $az-grey-6;
      padding: $az-spacing-zero;
    }

    @media #{$medium-only} {
      &::placeholder {
        font-family: $font-az-helvetica-medium;
        font-size: $font-size-12;
        letter-spacing: 0.4px;
        color: $az-grey-6;
        opacity: 1;
        position: relative;
      }

      ::-webkit-input-placeholder {
        /* Edge */
        font-family: $font-az-helvetica-medium;
        font-size: $font-size-12;
        letter-spacing: 0.4px;
        color: $az-grey-6;
        opacity: 1;
        position: relative;
        right: $az-spacing-5;
      }

      :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        font-family: $font-az-helvetica-medium;
        font-size: $font-size-12;
        letter-spacing: 0.4px;
        color: $az-grey-6;
        opacity: 1;
        position: relative;
        right: $az-spacing-5;
      }
    }

    @media #{$extra-large-and-up} {
      padding-left: $az-spacing-44;
      padding-right: $az-spacing-140;
    }

    &:focus {
      + .clearButton {
        opacity: 1;
      }
    }
  }

  &.searchInputScrolled {
    background: $az-white;

    input {
      background: $az-white;
    }
  }

  input:not([type]):focus:not([readonly]) {
    box-shadow: none !important;
  }
}

.searchInputSticky {
  input {
    padding-top: 8px;
    padding-bottom: 8px;
    &::placeholder {
      font-size: 12px;
      letter-spacing: 0px;
    }
    &::-webkit-input-placeholder {
      font-size: 12px;
      letter-spacing: 0px;
    }
    @media #{$extra-large-and-up} {
      padding-left: 32px;
    }
  }
}

.inputBorder {
  @media #{$medium-and-up} {
    &:focus {
      border-bottom-left-radius: $az-spacing-zero;
      border-bottom-right-radius: $az-spacing-zero;
    }
  }
}

.searchButton::before,
.searchButtonDrawer::before {
  @media #{$medium-and-up} {
    content: ' ';
    position: absolute;
    display: block;
    width: $az-spacing-30;
    height: $az-spacing-xl;
    left: $az-spacing-neg-30;
    top: 0.1px;
    background: $az-white;
  }
}

.searchButton,
.searchButtonDrawer {
  outline: none;
  border: none;
  background: $az-white;
  position: absolute;
  width: $az-spacing-41;
  padding-left: $az-spacing-14;
  margin-left: $az-spacing-zero;
  height: $az-spacing-xl;
  right: $az-spacing-4xs;
  align-items: $cdirection;

  @media #{$small-and-down} {
    height: $az-spacing-m;
    width: $az-spacing-34;
    position: relative;
    background: none;
    padding: $az-spacing-zero;
  }
}

.suggestionContainer {
  background-color: $az-white;
  z-index: 7;
  width: $az-percent-100;
  border-bottom-right-radius: $az-spacing-4xs;
  border-bottom-left-radius: $az-spacing-4xs;

  @media #{$small-and-down} {
    width: 100vw;
  }
  @media #{$medium-only} {
    padding: 0 16px;
  }
}

.headerWrapperTopNav {
  display: flex;
  justify-content: space-evenly;
  border-bottom: $az-spacing-1 solid $az-grey-2;
  font-family: $font-az-helvetica;
  align-items: $cdirection;
  border-top: $az-spacing-1 solid $az-grey-2;
  min-height: $az-spacing-l;
}

.vehicleTopNav {
  border-right: $az-spacing-1 solid rgba(0, 0, 0, 0.12);
}

.show {
  display: block;
}

.hide {
  display: none;
}

.narrowSearchPopup {
  background: $az-white;
  position: absolute;
  top: $az-spacing-l;
  left: $az-spacing-zero;
  z-index: 2100;
  padding: $az-spacing-15 $az-spacing-zero $az-spacing-15 $az-spacing-zero;
  border: $az-spacing-1 solid #d4d4d5;
  box-shadow: $az-spacing-zero $az-spacing-2 $az-spacing-5xs $az-spacing-zero rgba(34, 36, 38, 0.12),
    $az-spacing-zero $az-spacing-2 $az-spacing-10 $az-spacing-zero rgba(34, 36, 38, 0.15);

  &::before {
    position: absolute;
    content: '';
    top: $az-spacing-neg-5;
    left: $az-percent-49;
    width: 0.71428571em;
    height: 0.71428571em;
    background: $az-white;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    z-index: 2;
    -webkit-box-shadow: $az-spacing-neg-1 $az-spacing-neg-1 $az-spacing-zero $az-spacing-zero
      #b3b3b3;
    box-shadow: $az-spacing-neg-1 $az-spacing-neg-1 $az-spacing-zero $az-spacing-zero #b3b3b3;
  }

  .popHeading {
    .heading {
      width: $az-percent-94;
      margin-left: $az-spacing-10;
      font-size: $font-size-22;
    }
  }

  @media #{$extra-large-and-down} {
    display: none;
  }
}

@media print {
  .searchButton {
    border-top: $az-spacing-1 solid $az-grey-3;
    border-bottom: $az-spacing-1 solid $az-grey-3;
    border-right: $az-spacing-1 solid $az-grey-3;
    border-left: none;
    padding: $az-spacing-9;
    background: $az-white;
  }

  .searchInput {
    border-left: $az-spacing-1 solid $az-grey-3;
    border-top: $az-spacing-1 solid $az-grey-3;
    border-bottom: $az-spacing-1 solid $az-grey-3;
    border-right: $az-spacing-1 solid $az-grey-3;
    width: $az-spacing-420;
  }
}

.rootScrolled {
  z-index: 6 !important;
  top: $az-spacing-67 !important;

  @media #{$small-and-down} {
    display: none;
  }
}

.suggestionList {
  width: $az-percent-100;
}

.signInMenu {
  margin-top: $az-spacing-xxs;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-grow: 1;
}

.rewardBalance {
  font-size: $font-size-14;
  color: $az-color-success;
  height: $az-spacing-xs;
  font-family: $font-az-helvetica;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
}

.rewardText {
  color: $az-grey-7;
  text-decoration: underline;
  margin-right: $az-spacing-4xs;
  height: $az-spacing-xs;
  font-family: $font-az-helvetica;
  font-size: $font-size-14;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: right;
}

.searchInputContainer {
  width: 100%;
  position: relative;
  border: none;
  outline: none;
  background-color: transparent;
  padding: 0;

  &::before {
    content: '';
    background: url('/images/icon-search-16x16.svg') no-repeat;
    position: absolute;
    left: $az-spacing-4xs;
    top: $az-spacing-4xs;
    width: $az-spacing-xxs;
    height: $az-spacing-xxs;
  }
}

.relative {
  position: relative;
}

.backdrop {
  top: 79px !important;
}

.backdropMx {
  top: 67px !important;
}

.noScroll {
  position: fixed;
  width: $az-percent-100;
}
