@import '../../../theme/newVariables';

.footerContainer {
  background-color: $az-black;
  color: $az-white;
  padding: $az-spacing-s $az-spacing-21;

  hr {
    border: $az-spacing-1 solid $az-grey-5;
  }

  @media #{$extra-large-and-up-max} {
    padding: $az-spacing-s $az-spacing-rem-14;
  }
}

.topHrTag {
  @media #{$small-and-down} {
    margin: $az-spacing-s $az-spacing-zero $az-spacing-zero;
  }
}

.bottomHrTag {
  @media #{$small-and-down} {
    display: none;
  }
}

.linksAndCookieBtn {
  display: flex;
}
