@import './alignments';
@import './mixins';

@mixin utils {
  .az-disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  @include spacingStyles('padding');
  @include spacingStyles('margin');

  .two_split_container {
    margin: auto;
  }

  .box {
    display: flex;
  }

  .right_skew {
    @include skew($bg: red, $deg: -10deg, $right: -15px, $width: 35px);
  }

  .left_skew {
    @include skew($side: left, $bg: red, $deg: 20deg, $left: -28px, $width: 55px);
  }

  .az-align-right {
    text-align: $rdirection;
  }

  .az-align-left {
    text-align: $ldirection;
  }

  .az-align-center {
    text-align: $cdirection;
  }

  .az-align-justify {
    text-align: $jdirection;
  }

  .az-display-block {
    display: block;
  }

  .az-color-inherit {
    color: inherit;
  }
}
