@import '@/theme/newVariables';

.resetPwdMainContainer {
  .resetPwdContainer {
    max-width: $az-spacing-356;
    margin: $az-spacing-xl auto;
  }

  .autoZoneLogo {
    margin-bottom: $az-spacing-4xl;
  }

  .securityWarning {
    margin: $az-spacing-xxxs $az-spacing-zero $az-spacing-m $az-spacing-zero;
  }

  .forgotContent {
    font-stretch: condensed;
    margin-bottom: $az-spacing-10;
  }

  .resetPwdClose {
    padding: $az-spacing-xxxs $az-spacing-xs;
    height: $az-spacing-l;
    margin-top: $az-spacing-4xs;
  }

  .resetPwdCloseLink {
    float: $rdirection;
  }

  .passwordHolder {
    position: relative;

    .showPwd {
      position: absolute;
      right: $az-spacing-11;
      top: $az-spacing-xs;
      cursor: pointer;
      color: $az-grey-5;
    }
  }

  .resetPwdBannerSection {
    background-image: url('/images/sign-in-background-img.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    color: $az-white;

    .resetPwdBannerWrapper {
      width: $az-spacing-449;
      margin: $az-spacing-zero auto;
      padding: $az-spacing-6xl $az-spacing-zero;

      .resetPwdBannerHeading {
        margin-bottom: $az-spacing-l;
      }

      .resetPwdBannerInfoList {
        margin-left: $az-spacing-xxxs;

        .resetPwdBannerInfo {
          margin-bottom: $az-spacing-l;
          display: flex;
        }
      }

      .resetPwdInfoIcon {
        width: $az-spacing-45;
        height: $az-spacing-45;
        margin-right: $az-spacing-xs;
      }
    }
  }

  .buttonSection {
    margin-top: $az-spacing-m;
  }

  @media #{$medium-only} {
    .resetPwdContainer {
      padding: $az-spacing-5xl $az-spacing-zero;
    }

    .securityWarning {
      margin: $az-spacing-xxxs $az-spacing-zero $az-spacing-xs $az-spacing-zero;
    }

    .buttonSection {
      margin-top: $az-spacing-s;
    }

    .autoZoneLogo {
      margin-bottom: $az-spacing-l;
    }
  }

  @media #{$small-and-down} {
    .resetPwdContainer {
      max-width: $az-percent-100;
      margin: $az-spacing-xs $az-spacing-xxs;
    }

    .buttonSection {
      position: fixed;
      bottom: $az-spacing-xs;
      left: $az-spacing-xxs;
      right: $az-spacing-xxs;
    }

    .resetPwdClose {
      margin-top: $az-spacing-zero;
      background-color: $az-grey-6;
      padding: $az-spacing-xxxs $az-spacing-xs;
    }

    .resetPwdCloseLink {
      svg path {
        fill: $az-white;
      }
    }

    .securityWarning {
      margin: $az-spacing-xxxs $az-spacing-zero $az-spacing-xs $az-spacing-zero;
    }

    .autoZoneLogo {
      margin-bottom: $az-spacing-xxxs;
    }
  }
}

.displaySubMessage {
  display: inline-block;
}
.errorMessageContainer {
  padding: $az-spacing-4xl;
}

.userName {
  font-size: 14px;
  line-height: 20px;
  @media #{$large-only} {
    font-size: 16px;
    line-height: 24px;
  }
}

.offsetNotificationMargin {
  margin-bottom: 10px;
  font-size: 12px !important;
}
