.root {
  display: inline-flex;
  flex-direction: column;
  position: relative;
  // Reset fieldset default style.
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
  vertical-align: top; // Fix alignment issue on Safari.
}

.marginNormal {
  margin-top: 16px;
  margin-bottom: 8px;
}

.marginDense {
  margin-top: 8px;
  margin-bottom: 4px;
}

.fullWidth {
  width: 100%;
}
