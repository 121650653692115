@import './newVariables';

@mixin layout {
  .mainContentContainer {
    padding: 0 20px;

    @media #{$small-and-down} {
      padding: 0 16px;
    }
  }

  .mainContent {
    max-width: 1600px;
    margin: 0 auto;

    &.fullWidth {
      max-width: 100%;
    }

    @media print {
      padding-top: 0;
    }
  }
}

@media #{$small-and-down} {
  .circular-title {
    font-size: $font-size-22 !important;
  }
}
