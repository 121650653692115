@import '@/theme/newVariables';

.dynamicContent {
  display: inline-block;
  padding: $az-spacing-zero $az-spacing-m;

  @media #{$medium-only} {
    display: flex;
    padding: $az-spacing-xxs;
  }

  @media only screen and (max-width: 730px) {
    min-height: 42px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: $az-spacing-5xs $az-spacing-xxs;
  }

  p:first-child {
    font-family: $font-az-cond-medium;
    font-size: $font-size-14;
    line-height: 18px;
    letter-spacing: 1.25px;
    color: $az-grey-7;
    text-transform: uppercase;

    @media #{$medium-only} {
      padding-right: $az-spacing-25;
      height: auto;
      letter-spacing: 0.4px;
      color: $az-grey-6;
    }

    @media #{$small-and-down} {
      font-size: $font-size-12;
      line-height: 16px;
      letter-spacing: normal;
      color: $az-grey-6;
    }
  }

  p:nth-child(2) {
    height: $az-spacing-xxs;
    font-family: $font-az-helvetica;
    font-size: $font-size-12;
    line-height: 1.33;
    letter-spacing: 0.4px;
    color: $az-grey-5;

    @media #{$medium-only} {
      height: auto;
    }

    @media #{$small-and-down} {
      font-size: $font-size-10;
      letter-spacing: 0.33px;
      color: $az-grey-5;
    }
  }
}
