.root {
  display: block;
  transform-origin: top left;
}

.formControl {
  top: 0;
  left: 0;
  position: absolute;
  transform: translate(0, 24px) scale(1);
  pointer-events: none;
}

.marginDense {
  transform: translate(0, 21px) scale(1);
}

.shrink {
  transform: translate(0, 1.5px) scale(0.75);
  transform-origin: top left;
}

.animated {
  transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.filled {
  z-index: 1;
  transform: translate(12px, 20px) scale(1);
  pointer-events: none;
}

.filled.marginDense {
  transform: translate(12px, 17px) scale(1);
}

.filled.shrink {
  transform: translate(12px, 10px) scale(0.75);
}

.filled.shrink.marginDense {
  transform: translate(12px, 7px) scale(0.75);
}

.outlined {
  z-index: 1;
  transform: translate(14px, 20px) scale(1);
  pointer-events: none;
}

.outlined.marginDense {
  transform: translate(14px, 12px) scale(1);
}

.outlined.shrink {
  transform: translate(14px, -6px) scale(0.75);
}
