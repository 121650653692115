@import '../../theme/newVariables';

.errorOuter {
  margin: $az-spacing-zero;
  padding: $az-spacing-zero;
}

.errorHeader {
  text-align: center;
  font-size: $font-size-30;
  font-weight: bold;
  font-family: $font-az-helvetica;
  top: $az-spacing-zero;
  line-height: 1.1;
  margin: $az-spacing-zero;
}

.errorContent {
  width: $az-percent-70;
  margin: auto;
  font-size: $font-size-13;
  background-color: rgb(51, 3, 3);
  color: rgb(255, 133, 133);
  font-family: $font-az-helvetica;
  padding: $az-spacing-5 $az-spacing-5 $az-spacing-xs $az-spacing-5;
  border: $az-spacing-1 solid rgb(209, 18, 18);
  border-radius: $az-spacing-5;
}

.errorMessage {
  text-align: center;
  font-size: $font-size-20;
  color: $az-medium-red;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  margin: $az-spacing-xs;
}

.acknowledge {
  text-align: center;
  margin-bottom: $az-spacing-10;
  font-size: $font-size-16;
  font-style: italic;
}

.boundaryWrapper {
  background-color: $az-grey-1;
  width: $az-percent-100;
  padding-top: $az-spacing-130;
  padding-bottom: $az-spacing-50;
}

.errorParagraph {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: auto;
  text-align: center;
  font-family: $font-az-helvetica;
  font-size: $font-size-20;
  font-style: normal;
  color: $az-grey-5;
  padding: $az-spacing-100 20px $az-spacing-100;
}

.engineImage {
  margin-bottom: $az-spacing-l;
}

.errorPre {
  overflow: auto;
}
