@import '../../theme/newVariables';
@import '../../theme/mixins.scss';

$skewAngle: -20deg;
$skewTextAngle: 20deg;
// Common styles
.ghost,
.contained,
.outlined,
.super,
.outlined,
.transparent,
.chevron,
.gray {
  position: relative;
  text-transform: uppercase;
  font-family: $font-az-cond-medium;
  font-size: $font-size-16;
  transition: background-color 300ms ease;
}

.ghost,
.ghostPureText,
.outlined {
  color: $az-black;
}

.ghost,
.ghostPureText,
.outlined,
.transparent {
  background-color: transparent;
}

.ghost,
.ghostPureText,
.contained,
.super,
.gray {
  border: none;
}
.super,
.contained,
.outlined,
.gray {
  overflow: hidden;
}
.outlined {
  @include rippleEffect($az-grey-16);
}
.contained {
  @include rippleEffect($az-black);
}
.super {
  @include rippleEffect($az-medium-orange-2);
}
.gray {
  @include rippleEffect($az-grey-17);
}

.contained.iconClear::before,
.ghost.iconClear::before {
  content: '';
  background-image: url('/images/close.png');
  display: inline-block;
  width: $az-spacing-4xs;
  height: $az-spacing-4xs;
  margin: $az-spacing-zero $az-spacing-4xs $az-spacing-1 $az-spacing-zero;
  background-size: $az-spacing-4xs;
}

.contained.iconLeft::before,
.ghost.iconLeft::before {
  content: '';
  background-image: url('/images/arrow-left-orange.svg');
  display: inline-block;
  width: $az-spacing-xxxs;
  height: $az-spacing-7;
  margin: $az-spacing-2 $az-spacing-4xs $az-spacing-2 $az-spacing-zero;
}

.contained.iconRight::after,
.ghost.iconRight::after,
.ghostPureText.iconRight::after {
  content: '';
  background-image: url('/images/arrow-right-lightOrange.svg');
  background-repeat: no-repeat;
  display: inline-block;
  width: $az-spacing-xs;
  height: $az-spacing-7;
  margin: $az-spacing-2 $az-spacing-zero $az-spacing-2 $az-spacing-4xs;
  position: relative;
  opacity: 1;
  top: $az-spacing-zero;
  bottom: $az-spacing-zero;
  left: $az-spacing-zero;
  background-color: transparent;
}

.ghost {
  display: flex;
  align-items: center;
  padding: $az-spacing-10 $az-spacing-4xs;
  transition: background-color 300ms, opacity 300ms;

  &:focus-visible {
    outline: 2px solid #015fcc;
  }
  &:focus {
    background-color: transparent;
  }
}

.ghostPureText {
  display: flex;
  align-items: center;
  position: relative;
  text-transform: none;
  padding: $az-spacing-xxxs $az-spacing-zero;
  transition: background-color 300ms, opacity 300ms;

  &:focus-visible {
    outline: 2px solid #015fcc;
  }
  &:focus {
    background-color: transparent;
  }
}

.transparent {
  border: $az-spacing-1 solid $az-white;
  color: $az-white;
  padding: $az-spacing-xxs $az-spacing-m;
  transition: all 300ms;
}
.chevron {
  min-height: $az-spacing-54;
  display: flex;
  align-items: $cdirection;
  justify-content: center;
  font-family: $font-az-helvetica;
  font-size: 13.5px;
  text-align: left;
  text-transform: unset;
  color: $az-black;
  background-color: $az-white;
  border: none;
  padding: 8px 4.5px 8px 9px;
  box-shadow: $az-spacing-zero $az-spacing-1 $az-spacing-3 $az-spacing-zero rgba(0, 0, 0, 0.2),
    $az-spacing-zero $az-spacing-2 $az-spacing-2 $az-spacing-zero rgba(0, 0, 0, 0.12),
    $az-spacing-zero $az-spacing-zero $az-spacing-2 $az-spacing-zero rgba(0, 0, 0, 0.14);
  @media #{$medium-and-down} {
    padding: 6px 4px 6px 8px;
    font-size: $az-spacing-xxxs;
  }
  .chevronIcon {
    margin: 0 15px;
    border-radius: 50%;
    padding: 8px 5px 0px 4px;
    width: 33px;
    height: 33px;
    @media #{$small-and-down} {
      margin: 0 10px;
    }
  }
  &:hover {
    .chevronIcon {
      background-color: $az-grey-16;
    }
  }
  &:disabled {
    background-color: $az-grey-1;
    cursor: not-allowed;
    .chevronIcon {
      background-color: $az-grey-1;
    }
  }
}

.contained {
  background-color: $az-black;
  color: $az-white;
  padding: $az-spacing-18 $az-spacing-36;
  font-size: 15.8px;
  font-family: $font-az-cond-medium;
  letter-spacing: 0.02em;
  border: none;
  min-height: $az-spacing-54;

  @media #{$medium-and-down} {
    padding: $az-spacing-17 $az-spacing-36;
    font-size: $az-spacing-14;
    min-height: $az-spacing-xxl;
  }

  &:focus-visible {
    outline: 2px solid #015fcc;
  }
  &:focus:enabled {
    background-color: $az-black;
  }
  &:hover:enabled {
    background-color: $az-grey-14;
  }
  &:disabled {
    background-color: $az-grey-1;
    color: $az-grey-14;
    cursor: not-allowed;
  }
  &.loading:hover {
    background-color: $az-black;
    cursor: unset;
  }
}

.outlined {
  min-height: $az-spacing-54;
  color: $az-black;
  padding: 17.5px $az-spacing-36;
  background-color: $az-white;
  border: $az-spacing-1 solid $az-black;
  font-size: 15.8px;
  background-color: $az-white;
  font-family: $font-az-cond-medium;
  font-weight: normal;
  letter-spacing: 0.02em;

  @media #{$medium-and-down} {
    padding: $az-spacing-xxs $az-spacing-36;
    font-size: $az-spacing-14;
    min-height: $az-spacing-xxl;
  }

  &:focus-visible {
    outline: 2px solid #015fcc;
  }

  &:focus {
    background-color: $az-white;
  }
  &:disabled {
    color: $az-grey-14;
    cursor: not-allowed;
    border: 1px solid $az-grey-14;
  }
  &:hover:enabled {
    background-color: $az-grey-1;
  }
  &:hover:disabled {
    cursor: not-allowed;
  }
  &.loading:hover {
    background-color: $az-white;
    cursor: unset;
  }
}

.containedSmWhite {
  background-color: $az-white;
  padding-left: $az-spacing-xxs;
  border: $az-spacing-1 solid $az-black;
  transition: all 300ms;

  &:focus-visible {
    outline: 2px solid #015fcc;
  }
  &:focus {
    background-color: $az-white;
  }
}

.super {
  background-color: $az-medium-orange-2;
  color: $az-white;
  font-size: $az-spacing-21;
  font-family: $font-az-cond-heavy;
  letter-spacing: normal;
  padding: $az-spacing-18;
  min-height: $az-spacing-59;

  @media #{$medium-and-down} {
    font-size: $az-spacing-19;
    padding: $az-spacing-17 $az-spacing-18;
    min-height: $az-spacing-60;
  }

  box-shadow: $az-spacing-zero $az-spacing-1 $az-spacing-5 $az-spacing-zero rgba(32, 33, 36, 0.12),
    $az-spacing-zero $az-spacing-2 $az-spacing-2 $az-spacing-zero rgba(32, 33, 36, 0.14),
    $az-spacing-zero $az-spacing-3 $az-spacing-1 $az-spacing-neg-2 rgba(32, 33, 36, 0.2);

  &:focus-visible {
    outline: 2px solid #015fcc;
  }
  &:focus:enabled {
    background-color: $az-medium-orange-2;
  }
  &:hover:enabled {
    background-color: $az-light-orange-2;
  }
  &.loading:hover {
    background-color: $az-medium-orange-2;
    cursor: unset;
  }
}

.contained.angled {
  background: linear-gradient(
    to bottom,
    $az-grey-5 $az-percent-zero,
    $az-grey-5 $az-percent-50,
    $az-black $az-percent-50,
    $az-black $az-percent-100
  );
  background-color: transparent;
  transform: skewX($skewAngle);
  padding: $az-spacing-14 $az-spacing-28;
}

.angledText {
  display: inline-block;
  transform: skewX($skewTextAngle);
}

.discreet {
  color: $az-grey-14;
}

// shapes
.rounded {
  border-radius: 4px;
  isolation: isolate;
}
.pill {
  text-transform: unset;
  font-family: $font-az-helvetica;
  font-weight: bold;
  font-size: 13.5px;
  @media #{$medium-and-down} {
    font-size: 12px;
    min-height: $az-spacing-28;
  }
}

// button sizes

.small {
  &.contained,
  &.outlined {
    min-height: $az-spacing-45;
    padding: $az-spacing-9 $az-spacing-18;
  }
  &.pill {
    padding: 0px 13.5px;
    min-height: $az-spacing-23;
    border-radius: $az-spacing-xxs;
    isolation: isolate;
  }
  @media #{$medium-and-down} {
    &.contained,
    &.outlined {
      min-height: $az-spacing-l;
    }
    &.pill {
      padding: 4px 16px;
      min-height: $az-spacing-23;
      &.outlined {
        padding: 3px 16px;
      }
    }
  }
}

.large {
  &.contained,
  &.outlined {
    min-height: $az-spacing-54;
  }

  &.pill {
    border-radius: $az-spacing-25;
    isolation: isolate;
  }
  &.super {
    min-height: $az-spacing-59;
  }
}
.fullWidth {
  width: $az-percent-100;
}

// Disabled states

.contained:disabled {
  cursor: not-allowed;
  color: $az-grey-14;
  background-color: $az-grey-2;
}

.outlined:disabled {
  cursor: not-allowed;
  color: $az-grey-14;
  border: $az-spacing-1 solid $az-grey-2;
}
.contained.angled:disabled {
  background: $az-grey-2;
}

.super:disabled {
  background-color: $az-grey-2;
  color: $az-grey-14;
  cursor: not-allowed;
}

.ghost:disabled {
  color: $az-grey-3;
}

.outlined:disabled {
  cursor: not-allowed;
  color: $az-grey-3;
  border-color: $az-grey-3;
  opacity: 0.4;
}

.transparent:disabled {
  cursor: not-allowed;
  color: $az-grey-3;
  border-color: $az-grey-3;
  opacity: 0.6;
  text-decoration: line-through;
}

.contained.lowEmphasis,
.outlined.lowEmphasis,
.contained.angled.lowEmphasis,
.super.lowEmphasis {
  padding: calc(#{$az-spacing-4xs} + #{$az-spacing-2}) $az-spacing-xxs;
}

.link {
  border: 0;
  margin: 0;
  padding: 0;
  background: transparent;
  text-decoration: $az-black underline;
  font-family: inherit;
  color: $az-black;
}

.gray {
  color: $az-grey-6;
  font-size: $az-spacing-10;
  font-family: $font-az-helvetica;
  font-style: normal;
  font-weight: 500;
  line-height: $az-spacing-xxxs;
  letter-spacing: 0.005em;
  border-style: none;
  background-color: transparent;
}

@media (hover: hover) and (pointer: fine) {
  .contained.angled:hover {
    box-shadow: $az-spacing-zero $az-spacing-1 $az-spacing-5 $az-spacing-zero rgba(32, 33, 36, 0.12),
      $az-spacing-zero $az-spacing-2 $az-spacing-2 $az-spacing-zero rgba(32, 33, 36, 0.14),
      $az-spacing-zero $az-spacing-3 $az-spacing-1 $az-spacing-neg-2 rgba(32, 33, 36, 0.2);
  }

  .transparent:hover {
    background-color: rgba(83, 85, 92, 0.2);
  }

  .outlined:hover:enabled {
    background-color: rgba($az-black, 0.1);
  }

  .ghost:hover:enabled {
    background-color: rgba($az-grey-2, 0.2);
  }

  .outlined:hover:disabled {
    cursor: not-allowed;
  }

  .transparent:disabled:hover {
    background-color: transparent;
  }

  .gray:hover {
    background-color: $az-grey-1;

    @media #{$small-and-down} {
      background-color: $az-grey-2;
    }
  }
}

.buttonText {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 2;
}
