@import '../../theme/newVariables.scss';

.root {
  position: relative;
}

// stylelint-disable-next-line selector-max-type
label + .formControl {
  margin-top: 16px;
}

.colorSecondary.underline::after {
  border-bottom-color: #f50057;
}

.underline::after {
  left: 0;
  right: 0;
  bottom: 0;
  content: '';
  position: absolute;
  transform: scaleX(0);
  transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  border-bottom: 2px solid #3f51b5;
  pointer-events: none;
}

.underline.focused::after {
  transform: scaleX(1);
}

.underline.error::after {
  transform: scaleX(1);
  border-bottom-color: #f44336;
}

.underline::before {
  left: 0;
  right: 0;
  bottom: 0;
  content: '';
  position: absolute;
  transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  pointer-events: none;
}

.underline:hover:not(.disabled)::before {
  border-bottom: 2px solid rgba(0, 0, 0, 0.87);
}

.underline.disabled::before {
  border-bottom-style: dotted;
}

@media (hover: none) {
  .underline:hover:not(.disabled)::before {
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  }
}

.disabled {
  background-color: $az-grey-1;
  z-index: -1;
}
