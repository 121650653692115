.fadeIn {
  opacity: 1;
  animation: fadeIn;
  animation-duration: 0.2s;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}

.overrideZindex {
  z-index: 99997 !important;
}

.fadeOut {
  opacity: 0;
  animation: fadeOut;
  animation-duration: 0.2s;

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
}
