@import '@/theme/newVariables.scss';

.signInMobileIconAndLabel {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2px 6px;
  position: relative;

  @media #{$small-and-down} {
    padding: unset;
    justify-content: space-evenly;
    height: 48px;
  }
  .signInMobileLabel {
    font-size: 12px;
    white-space: nowrap;
    color: $az-black;
    letter-spacing: 0.25px;
    text-transform: capitalize;
    display: block;
  }
}

.fixedSignInMobileIconAndLabel {
  @media #{$small-and-down} {
    min-width: 54px;
  }
}

.animationWrapper {
  width: $az-spacing-xs;
  height: $az-spacing-xs;
  transition: all 1.5s ease-in-out;

  @media #{$medium-and-up} {
    width: $az-spacing-s;
    height: $az-spacing-s;
  }
}

.signInIcon {
  transition: all 0.3s ease-in-out;

  &:hover {
    opacity: 0.8;
    transform: scale(1.05);
  }

  @media #{$medium-and-up} {
    width: $az-spacing-s;
    height: $az-spacing-s;
  }
}
