@import '@/theme/newVariables.scss';

.signInMobileFeature {
  min-width: 54px;
  box-sizing: border-box;

  @media #{$small-and-down} {
    justify-content: center;
  }

  .signInAuthenticated {
    border: none;
    background-color: transparent;
    padding-right: 6px;
    padding-left: 2px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none !important;
    color: $az-black !important;

    @media #{$small-and-down} {
      padding-right: 4px;
      width: 100%;
    }
  }

  .signInUnAuthenticated {
    position: relative;
    border: none;
    background-color: transparent;
    padding-right: 2px;
    padding-left: 2px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none !important;
    color: $az-black !important;

    @media #{$small-and-down} {
      height: 48px;
      justify-content: space-evenly;
      padding: unset;
    }
  }

  @media #{$medium-and-up} {
    display: none;
  }
}

.ctaButton {
  margin-bottom: 16px;
}

.rewardsLogoAndLabel {
  display: flex;
  align-items: center;
  padding: 18px 0;

  .rewardsLogo {
    width: 30px;
    height: 30px;
    margin-right: 12px;
  }

  .rewardsLabel {
    font-size: 14px;
  }
}

.linksSection {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  font-size: 14px;

  .link {
    color: #484848;
    margin-bottom: 16px;
  }
}

.spinnerWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.modalContent > div {
  @media #{$small-and-down} {
    padding: 0px !important;
  }
}
