@import '@/theme/newVariables';

.recentSearchesContainer {
  background-color: $az-white;
  line-height: $az-spacing-s;
}

.header {
  color: #767676;
  font-family: $font-az-helvetica;
  font-size: $font-size-12;
  line-height: 24px;
  letter-spacing: 2.4px;
  text-transform: uppercase;
  padding: $az-spacing-4xs 0;
}

.recentSearch {
  font-family: $font-az-helvetica;
  font-size: $font-size-14;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 2px;
  overflow-wrap: anywhere;

  @media #{$small-and-down} {
    font-size: $font-size-14;
  }
}

.matchedText {
  font-weight: 400;
}

.text {
  flex-grow: 1;
}

.bottomDivider {
  display: block;
  border-bottom: $az-grey-1 1px solid;

  &:hover {
    background-color: $az-grey-1;
    cursor: pointer;
  }
}

.delete {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  right: $az-spacing-xxs;
  margin: auto;
  aspect-ratio: 1/1;
  height: 50%;
  border-radius: 2px;
  width: $az-spacing-22-5;
  &:hover {
    background-color: $az-grey-1;
  }
}

.recentSearchItem {
  position: relative;
}

.active {
  background-color: $az-grey-1;
}
