@import '../../theme/newVariables.scss';

.xl-up {
  @media #{$extra-large-and-up-max} {
    display: none;
  }
}

.xl-down {
  display: none;
}

.lg-up {
  @media #{$extra-large-and-up} {
    display: none;
  }
}

.lg-down {
  @media #{$large-and-down} {
    display: none;
  }

  @media #{'only screen and (min-width : #{$large-screen-max})'} {
    display: block;
  }
}

.md-up {
  @media #{$medium-and-up} {
    display: none;
  }
}

.md-down {
  @media #{$extra-large-and-down} {
    display: none;
  }
}

.sm-up {
  display: none;
}

.sm-down {
  @media #{$small-and-down} {
    display: none;
  }
}

.sm-only {
  @media #{'only screen and (max-width : #{$small-screen})'} {
    display: none;
  }
}

.md-only {
  @media #{'only screen and (min-width : #{$small-screen-up}) and (max-width : #{$large-screen-up})'} {
    display: none;
  }
}

.lg-only {
  @media #{'only screen and (min-width : #{$large-screen-up}) and (max-width : #{$large-screen-max})'} {
    display: none;
  }
}

.xl-only {
  @media #{$extra-large-and-up-max} {
    display: none;
  }
}
