@import '../../theme/newVariables.scss';

.root {
  color: rgba(0, 0, 0, 0.54);
  margin: $az-spacing-4xs $az-spacing-xxs $az-spacing-zero;
  font-size: $font-size-12;
  text-align: left;
  font-family: $font-az-helvetica;
  font-weight: normal;
  text-transform: none;
  line-height: $az-spacing-xxs;
  letter-spacing: 0.4px;
}

.disabled {
  color: rgba(0, 0, 0, 0.38);
}

.error {
  color: $az-color-error;
}

.marginDense {
  margin-top: 4px;
}

.contained {
  margin-left: 14px;
  margin-right: 14px;
}
