@import '../../../theme/newVariables';

.customDrawer {
  .backHeading {
    cursor: pointer;
    color: $az-white;
    letter-spacing: $az-spacing-three-fourths;
    text-transform: uppercase;
    font-family: $font-az-cond-medium;
    font-size: $font-size-16;
    font-weight: 500;
    font-stretch: condensed;
    margin-left: $az-spacing-5xs;
  }

  .horizontalPadding {
    padding: $az-spacing-xxxs $az-spacing-zero;
  }

  .backIcon {
    width: $az-spacing-xxs;
    height: $az-spacing-xxs;
  }

  .drawerMobile {
    width: $az-percent-100;
  }

  .toolbarIconButton {
    margin-right: -12px; // 12px is IconButton padding
  }

  .searchHeading {
    width: $az-spacing-50;
    height: $az-spacing-15;
    font-family: $font-az-helvetica;
    font-size: $font-size-12;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-transform: uppercase;
  }
}
