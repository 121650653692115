@import './spacingSizes';

// applies styles only for Internet Explorer
@mixin ie {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    @content;
  }
}

@mixin spacingStyles($spacingType) {
  .az-#{$spacingType}-5xs {
    #{$spacingType}: $az-spacing-5xs;
  }

  .az-#{$spacingType}-4xs {
    #{$spacingType}: $az-spacing-4xs;
  }

  .az-#{$spacingType}-xxxs {
    #{$spacingType}: $az-spacing-xxxs;
  }

  .az-#{$spacingType}-xxs {
    #{$spacingType}: $az-spacing-xxs;
  }

  .az-#{$spacingType}-xs {
    #{$spacingType}: $az-spacing-xs;
  }

  .az-#{$spacingType}-s {
    #{$spacingType}: $az-spacing-s;
  }

  .az-#{$spacingType}-m {
    #{$spacingType}: $az-spacing-m;
  }

  .az-#{$spacingType}-l {
    #{$spacingType}: $az-spacing-l;
  }

  .az-#{$spacingType}-xl {
    #{$spacingType}: $az-spacing-xl;
  }

  .az-#{$spacingType}-xxl {
    #{$spacingType}: $az-spacing-xxl;
  }

  .az-#{$spacingType}-xxxl {
    #{$spacingType}: $az-spacing-xxxl;
  }

  .az-#{$spacingType}-4xl {
    #{$spacingType}: $az-spacing-4xl;
  }

  .az-#{$spacingType}-5xl {
    #{$spacingType}: $az-spacing-5xl;
  }

  .az-#{$spacingType}-6xl {
    #{$spacingType}: $az-spacing-6xl;
  }

  .az-#{$spacingType}-left-5xs {
    #{$spacingType}-left: $az-spacing-5xs;
  }

  .az-#{$spacingType}-left-4xs {
    #{$spacingType}-left: $az-spacing-4xs;
  }

  .az-#{$spacingType}-left-xxxs {
    #{$spacingType}-left: $az-spacing-xxxs;
  }

  .az-#{$spacingType}-left-xxs {
    #{$spacingType}-left: $az-spacing-xxs;
  }

  .az-#{$spacingType}-left-xs {
    #{$spacingType}-left: $az-spacing-xs;
  }

  .az-#{$spacingType}-left-s {
    #{$spacingType}-left: $az-spacing-s;
  }

  .az-#{$spacingType}-left-m {
    #{$spacingType}-left: $az-spacing-m;
  }

  .az-#{$spacingType}-left-l {
    #{$spacingType}-left: $az-spacing-l;
  }

  .az-#{$spacingType}-left-xl {
    #{$spacingType}-left: $az-spacing-xl;
  }

  .az-#{$spacingType}-left-xxl {
    #{$spacingType}-left: $az-spacing-xxl;
  }

  .az-#{$spacingType}-left-xxxl {
    #{$spacingType}-left: $az-spacing-xxxl;
  }

  .az-#{$spacingType}-left-4xl {
    #{$spacingType}-left: $az-spacing-4xl;
  }

  .az-#{$spacingType}-left-5xl {
    #{$spacingType}-left: $az-spacing-5xl;
  }

  .az-#{$spacingType}-left-6xl {
    #{$spacingType}-left: $az-spacing-6xl;
  }

  .az-#{$spacingType}-right-5xs {
    #{$spacingType}-right: $az-spacing-5xs;
  }

  .az-#{$spacingType}-right-4xs {
    #{$spacingType}-right: $az-spacing-4xs;
  }

  .az-#{$spacingType}-right-xxxs {
    #{$spacingType}-right: $az-spacing-xxxs;
  }

  .az-#{$spacingType}-right-xxs {
    #{$spacingType}-right: $az-spacing-xxs;
  }

  .az-#{$spacingType}-right-xs {
    #{$spacingType}-right: $az-spacing-xs;
  }

  .az-#{$spacingType}-right-s {
    #{$spacingType}-right: $az-spacing-s;
  }

  .az-#{$spacingType}-right-m {
    #{$spacingType}-right: $az-spacing-m;
  }

  .az-#{$spacingType}-right-l {
    #{$spacingType}-right: $az-spacing-l;
  }

  .az-#{$spacingType}-right-xl {
    #{$spacingType}-right: $az-spacing-xl;
  }

  .az-#{$spacingType}-right-xxl {
    #{$spacingType}-right: $az-spacing-xxl;
  }

  .az-#{$spacingType}-right-xxxl {
    #{$spacingType}-right: $az-spacing-xxxl;
  }

  .az-#{$spacingType}-right-4xl {
    #{$spacingType}-right: $az-spacing-4xl;
  }

  .az-#{$spacingType}-right-5xl {
    #{$spacingType}-right: $az-spacing-5xl;
  }

  .az-#{$spacingType}-right-6xl {
    #{$spacingType}-right: $az-spacing-6xl;
  }

  .az-#{$spacingType}-bottom-5xs {
    #{$spacingType}-bottom: $az-spacing-5xs;
  }

  .az-#{$spacingType}-bottom-4xs {
    #{$spacingType}-bottom: $az-spacing-4xs;
  }

  .az-#{$spacingType}-bottom-xxxs {
    #{$spacingType}-bottom: $az-spacing-xxxs;
  }

  .az-#{$spacingType}-bottom-xxs {
    #{$spacingType}-bottom: $az-spacing-xxs;
  }

  .az-#{$spacingType}-bottom-xs {
    #{$spacingType}-bottom: $az-spacing-xs;
  }

  .az-#{$spacingType}-bottom-s {
    #{$spacingType}-bottom: $az-spacing-s;
  }

  .az-#{$spacingType}-bottom-m {
    #{$spacingType}-bottom: $az-spacing-m;
  }

  .az-#{$spacingType}-bottom-l {
    #{$spacingType}-bottom: $az-spacing-l;
  }

  .az-#{$spacingType}-bottom-xl {
    #{$spacingType}-bottom: $az-spacing-xl;
  }

  .az-#{$spacingType}-bottom-xxl {
    #{$spacingType}-bottom: $az-spacing-xxl;
  }

  .az-#{$spacingType}-bottom-xxxl {
    #{$spacingType}-bottom: $az-spacing-xxxl;
  }

  .az-#{$spacingType}-bottom-4xl {
    #{$spacingType}-bottom: $az-spacing-4xl;
  }

  .az-#{$spacingType}-bottom-5xl {
    #{$spacingType}-bottom: $az-spacing-5xl;
  }

  .az-#{$spacingType}-bottom-6xl {
    #{$spacingType}-bottom: $az-spacing-6xl;
  }

  .az-#{$spacingType}-top-5xs {
    #{$spacingType}-top: $az-spacing-5xs;
  }

  .az-#{$spacingType}-top-4xs {
    #{$spacingType}-top: $az-spacing-4xs;
  }

  .az-#{$spacingType}-top-xxxs {
    #{$spacingType}-top: $az-spacing-xxxs;
  }

  .az-#{$spacingType}-top-xxs {
    #{$spacingType}-top: $az-spacing-xxs;
  }

  .az-#{$spacingType}-top-xs {
    #{$spacingType}-top: $az-spacing-xs;
  }

  .az-#{$spacingType}-top-s {
    #{$spacingType}-top: $az-spacing-s;
  }

  .az-#{$spacingType}-top-m {
    #{$spacingType}-top: $az-spacing-m;
  }

  .az-#{$spacingType}-top-l {
    #{$spacingType}-top: $az-spacing-l;
  }

  .az-#{$spacingType}-top-xl {
    #{$spacingType}-top: $az-spacing-xl;
  }

  .az-#{$spacingType}-top-xxl {
    #{$spacingType}-top: $az-spacing-xxl;
  }

  .az-#{$spacingType}-top-xxxl {
    #{$spacingType}-top: $az-spacing-xxxl;
  }

  .az-#{$spacingType}-top-4xl {
    #{$spacingType}-top: $az-spacing-4xl;
  }

  .az-#{$spacingType}-top-5xl {
    #{$spacingType}-top: $az-spacing-5xl;
  }

  .az-#{$spacingType}-top-6xl {
    #{$spacingType}-top: $az-spacing-6xl;
  }
}

// 2-split on home page with Grid
@mixin skew($side: right, $deg: -20deg, $right: -15px, $left: -15px, $width: 30px, $bg: #fff) {
  position: relative;

  &::before {
    z-index: -1;
    content: ' ';
    position: absolute;
    top: 0;
    bottom: 0;

    @if $side == right {
      right: $right;
    } @else if $side == left {
      left: $left;
    }

    display: block;
    width: $width;
    background: $bg;
    -webkit-transform: skew($deg);
    -ms-transform: skew($deg);
    transform: skew($deg);
  }
}

@mixin rippleEffect($ripple-color) {
  span.ripple {
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    transform: translate(-50%, -50%) scale(0.1);
    transition: transform ease-out 1000ms;
    background-color: $ripple-color;
  }
  span.rippleTransform {
    transform: translate(-50%, -50%) scale(4);
  }
  span.rippleContainer {
    transition: opacity 1000ms;
  }
  span.rippleContainerRemoved {
    opacity: 0;
  }
}
