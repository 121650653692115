.root {
  flex: 0 0 auto;
  color: rgba(0, 0, 0, 0.54);
  padding: 12px;
  overflow: visible;
  font-size: 1.5rem;
  text-align: center;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 50%;

  &:hover,
  &:focus {
    background-color: rgba(0, 0, 0, 0.04);

    @media (hover: none) {
      background-color: transparent;
    }
  }
}

.disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}

.edgeStart {
  margin-left: -12px;
}

.edgeEnd {
  margin-right: -12px;
}

.colorInherit {
  color: inherit;
}

.colorPrimary {
  color: #3f51b5;

  &:hover {
    background-color: rgba(63, 81, 181, 0.04);

    @media (hover: none) {
      background-color: transparent;
    }
  }
}

.colorSecondary {
  color: #f50057;

  &:hover {
    background-color: rgba(245, 0, 87, 0.04);

    @media (hover: none) {
      &:hover {
        background-color: transparent;
      }
    }
  }
}

.sizeSmall {
  padding: 3px;
  font-size: 1.125rem;

  .edgeStart {
    margin-left: -3px;
  }

  .edgeEnd {
    margin-right: -3px;
  }
}

.label {
  width: 100%;
  display: flex;
  align-items: inherit;
  justify-content: inherit;
}
