@import '@/theme/newVariables';

.linkItem {
  font-size: $font-size-xxs !important;
  font-weight: 500 !important;

  @media #{$small-and-down} {
    font-size: $font-size-18 !important;
    margin-top: $az-spacing-s !important;
  }
}

.labelLinkItem {
  font-size: $font-size-12 !important;
  margin-right: $az-spacing-xxxs !important;

  @media #{$small-and-down} {
    margin-right: $az-spacing-xxs !important;
  }
}

.commonCookieBtnStyles {
  line-height: $az-spacing-s !important;
  color: $az-grey-3 !important;
  font-family: $font-az-helvetica !important;
  background-color: transparent !important;
  border: none !important;
  padding: 0 !important;
  margin: 0 !important;
  cursor: pointer;
  text-decoration: none !important;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
}

.cookieButtonElement {
  display: flex;
}

.cookieStick {
  height: $az-spacing-xxs;
  width: $az-spacing-2;
  background-color: $az-grey-5;
  margin-top: $az-spacing-5xs;
  margin-right: $az-spacing-xs;
}
