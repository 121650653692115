/* stylelint-disable selector-max-type */
@import './typography.scss';
@import './utilities.scss';
@import './layout.scss';

html {
  font-size: $font-size-16;
  scroll-behavior: smooth;

  --st-z-index-backdrop: 20000;
  --st-font-size-root: 16px;
}

#password::-ms-reveal,
#password::-ms-clear {
  display: none;
}

@media #{$medium-only} {
  html {
    font-size: $font-size-18;
  }
}

@media #{$extra-large-and-up} {
  html {
    font-size: $font-size-20;
  }
}

body {
  font-family: $font-az-helvetica-light;
  color: $az-black;
  -webkit-font-smoothing: antialiased;
  font-size: 1rem;
  line-height: 20px;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: inherit;
  font-size: 2rem;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
pre,
xmp,
plaintext,
listing,
section,
article,
input {
  margin: 0;
  padding: 0;
}

pre,
xmp,
plaintext,
listing {
  display: block;
  white-space: pre;
}

*,
::after,
::before {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

a[href$='#'] {
  color: $az-grey-6;

  &:active {
    color: $az-blue;
  }

  &:visited {
    color: $az-magenta;
  }
}

/* For Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

/* Webkit browsers like Safari and Chrome */
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/*
  Other globals moved here from CSS module files for the sake of backwards
  compatibility. They should, however, be examined and either removed or inlined
  into specific components.
*/

// moved from YMMESelectComp/AutoComplete
::-ms-clear {
  display: none;
}

// moved from Shelf/ResultsList/ProductInfo
ul {
  display: contents;
}

// moved from Header
button:focus {
  background-color: transparent;
}

// moved from MyOrderDetailsHeader
hr {
  border-color: $az-grey-2;
  border-top: $az-spacing-zero;
  margin: $az-spacing-zero $az-spacing-xxs;

  @media #{$medium-and-up} {
    margin: $az-spacing-zero;
  }
}

// moved from ThirdPartyApis/ProductReview
body.bv-standalone-container {
  background: url('/images/auto-zone-logo-full-rgb.svg') no-repeat $az-spacing-10 $az-spacing-10
    fixed;
}

// moved from TrackOrder
[placeholder]:focus::-webkit-input-placeholder {
  color: transparent;
}

// moved from ChatPage
@media print {
  body {
    background: none;
  }
}

.htmlContentBlock,
.reactContentBlock {
  @media #{$small-and-down} {
    margin-top: $az-spacing-5;
  }
}

// for nuka-carousel pagingDotsContainer
.slider-control-bottomcenter {
  position: relative !important;
}

#checkout-paypal {
  align-items: center;
}

#checkout-paypal #paypal-message {
  width: 100%;
}

select:focus-visible {
  &:focus-visible {
    border-radius: 0;
    background-color: inherit;
    outline: $az-spacing-1 dotted $az-black; // FIREFOX DEFAULT
    outline: $az-spacing-5 auto $az-black;
  }
}

.sr-only:not(:focus):not(:active) {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

apple-pay-button {
  --apple-pay-button-width: 100%;
  --apple-pay-button-height: 40px;
  --apple-pay-button-border-radius: 4px;
  --apple-pay-button-padding: 0px 0px;
  --apple-pay-button-box-sizing: border-box;
}

@keyframes onSignInAutoFillStart {
  to {
    background: transparent;
    color: inherit;
  }
}
@keyframes onSignInAutoFillCancel {
  to {
    background: transparent;
    color: inherit;
  }
}

.signin-autofill-detector {
  input:-webkit-autofill {
    animation-name: onSignInAutoFillStart;
    animation-fill-mode: both;
    animation-duration: 1ms;
  }

  input:not(:-webkit-autofill) {
    animation-name: onSignInAutoFillCancel;
    animation-fill-mode: both;
    animation-duration: 1ms;
  }

  input:-moz-autofill {
    animation-name: onSignInAutoFillStart;
    animation-fill-mode: both;
    animation-duration: 1ms;
  }

  input:not(:-moz-autofill) {
    animation-name: onSignInAutoFillCancel;
    animation-fill-mode: both;
    animation-duration: 1ms;
  }
}
