@import '../../theme/newVariables.scss';

.stickyButtonContainer {
  width: $az-percent-100;
  padding: $az-spacing-xxs;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.2);
  background-color: $az-white;
  position: fixed;
  bottom: $az-spacing-zero;
  transform: translateX(100%);
  z-index: 1200;
}

.applyBtn {
  @media #{$medium-only}, #{xsmall-and-up}, #{$large-and-up} {
    max-width: $az-spacing-288;
  }
}
