@import '../../theme/breakPoints.scss';

.container {
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.item {
  box-sizing: border-box;
  margin: 0; // For instance; it's useful when used with a `figure` element.
}

.zeroMinWidth {
  min-width: 0;
}

.direction-xs-column {
  flex-direction: column;
}

.direction-xs-column-reverse {
  flex-direction: column-reverse;
}

.direction-xs-row-reverse {
  flex-direction: row-reverse;
}

.wrap-xs-nowrap {
  flex-wrap: nowrap;
}

.wrap-xs-wrap-reverse {
  flex-wrap: wrap-reverse;
}

.align-items-xs-center {
  align-items: center;
}

.align-items-xs-flex-start {
  align-items: flex-start;
}

.align-items-xs-flex-end {
  align-items: flex-end;
}

.align-items-xs-baseline {
  align-items: baseline;
}

.align-content-xs-center {
  align-content: center;
}

.align-content-xs-flex-start {
  align-content: flex-start;
}

.align-content-xs-flex-end {
  align-content: flex-end;
}

.align-content-xs-space-between {
  align-content: space-between;
}

.align-content-xs-space-around {
  align-content: space-around;
}

.justify-xs-center {
  justify-content: center;
}

.justify-xs-flex-end {
  justify-content: flex-end;
}

.justify-xs-space-between {
  justify-content: space-between;
}

.justify-xs-space-around {
  justify-content: space-around;
}

.justify-xs-space-evenly {
  justify-content: space-evenly;
}

.spacing-xs-1 {
  margin: -4px;
  width: calc(100% + 8px);

  > .item {
    padding: 4px;
  }
}

.spacing-xs-2 {
  margin: -8px;
  margin-top: 0;
  width: calc(100% + 16px);

  > .item {
    padding: 8px;
  }
}

.spacing-xs-3 {
  margin: -12px;
  width: calc(100% + 24px);

  > .item {
    padding: 12px;
  }
}

.spacing-xs-4 {
  margin: -16px;
  width: calc(100% + 32px);

  > .item {
    padding: 16px;
  }
}

.spacing-xs-5 {
  margin: -20px;
  width: calc(100% + 40px);

  > .item {
    padding: 20px;
  }
}

.spacing-xs-6 {
  margin: -24px;
  width: calc(100% + 48px);

  > .item {
    padding: 24px;
  }
}

.spacing-xs-7 {
  margin: -28px;
  width: calc(100% + 56px);

  > .item {
    padding: 28px;
  }
}

.spacing-xs-8 {
  margin: -32px;
  width: calc(100% + 64px);

  > .item {
    padding: 32px;
  }
}

.spacing-xs-9 {
  margin: -36px;
  width: calc(100% + 72px);

  > .item {
    padding: 36px;
  }
}

.spacing-xs-10 {
  margin: -40px;
  width: calc(100% + 80px);

  > .item {
    padding: 40px;
  }
}

.grid-xs-auto {
  flex-basis: auto;
  flex-grow: 0;
  max-width: none;
}

.grid-xs-true {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.grid-xs-1 {
  flex-basis: 8.333333%;
  flex-grow: 0;
  max-width: 8.333333%;
}

.grid-xs-2 {
  flex-basis: 16.666667%;
  flex-grow: 0;
  max-width: 16.666667%;
}

.grid-xs-3 {
  flex-basis: 25%;
  flex-grow: 0;
  max-width: 25%;
}

.grid-xs-4 {
  flex-basis: 33.333333%;
  flex-grow: 0;
  max-width: 33.333333%;
}

.grid-xs-5 {
  flex-basis: 41.666667%;
  flex-grow: 0;
  max-width: 41.666667%;
}

.grid-xs-6 {
  flex-basis: 50%;
  flex-grow: 0;
  max-width: 50%;
}

.grid-xs-7 {
  flex-basis: 58.333333%;
  flex-grow: 0;
  max-width: 58.333333%;
}

.grid-xs-8 {
  flex-basis: 66.666667%;
  flex-grow: 0;
  max-width: 66.666667%;
}

.grid-xs-9 {
  flex-basis: 75%;
  flex-grow: 0;
  max-width: 75%;
}

.grid-xs-10 {
  flex-basis: 83.333333%;
  flex-grow: 0;
  max-width: 83.333333%;
}

.grid-xs-11 {
  flex-basis: 91.666667%;
  flex-grow: 0;
  max-width: 91.666667%;
}

.grid-xs-12 {
  flex-basis: 100%;
  flex-grow: 0;
  max-width: 100%;
}

@media (min-width: 0) {
  .grid-sm-auto {
    flex-basis: auto;
    flex-grow: 0;
    max-width: none;
  }

  .grid-sm-true {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .grid-sm-1 {
    flex-basis: 8.333333%;
    flex-grow: 0;
    max-width: 8.333333%;
  }

  .grid-sm-2 {
    flex-basis: 16.666667%;
    flex-grow: 0;
    max-width: 16.666667%;
  }

  .grid-sm-3 {
    flex-basis: 25%;
    flex-grow: 0;
    max-width: 25%;
  }

  .grid-sm-4 {
    flex-basis: 33.333333%;
    flex-grow: 0;
    max-width: 33.333333%;
  }

  .grid-sm-5 {
    flex-basis: 41.666667%;
    flex-grow: 0;
    max-width: 41.666667%;
  }

  .grid-sm-6 {
    flex-basis: 50%;
    flex-grow: 0;
    max-width: 50%;
  }

  .grid-sm-7 {
    flex-basis: 58.333333%;
    flex-grow: 0;
    max-width: 58.333333%;
  }

  .grid-sm-8 {
    flex-basis: 66.666667%;
    flex-grow: 0;
    max-width: 66.666667%;
  }

  .grid-sm-9 {
    flex-basis: 75%;
    flex-grow: 0;
    max-width: 75%;
  }

  .grid-sm-10 {
    flex-basis: 83.333333%;
    flex-grow: 0;
    max-width: 83.333333%;
  }

  .grid-sm-11 {
    flex-basis: 91.666667%;
    flex-grow: 0;
    max-width: 91.666667%;
  }

  .grid-sm-12 {
    flex-basis: 100%;
    flex-grow: 0;
    max-width: 100%;
  }
}

@media (min-width: $small-screen-up) {
  .grid-md-auto {
    flex-basis: auto;
    flex-grow: 0;
    max-width: none;
  }

  .grid-md-true {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .grid-md-1 {
    flex-basis: 8.333333%;
    flex-grow: 0;
    max-width: 8.333333%;
  }

  .grid-md-2 {
    flex-basis: 16.666667%;
    flex-grow: 0;
    max-width: 16.666667%;
  }

  .grid-md-3 {
    flex-basis: 25%;
    flex-grow: 0;
    max-width: 25%;
  }

  .grid-md-4 {
    flex-basis: 33.333333%;
    flex-grow: 0;
    max-width: 33.333333%;
  }

  .grid-md-5 {
    flex-basis: 41.666667%;
    flex-grow: 0;
    max-width: 41.666667%;
  }

  .grid-md-6 {
    flex-basis: 50%;
    flex-grow: 0;
    max-width: 50%;
  }

  .grid-md-7 {
    flex-basis: 58.333333%;
    flex-grow: 0;
    max-width: 58.333333%;
  }

  .grid-md-8 {
    flex-basis: 66.666667%;
    flex-grow: 0;
    max-width: 66.666667%;
  }

  .grid-md-9 {
    flex-basis: 75%;
    flex-grow: 0;
    max-width: 75%;
  }

  .grid-md-10 {
    flex-basis: 83.333333%;
    flex-grow: 0;
    max-width: 83.333333%;
  }

  .grid-md-11 {
    flex-basis: 91.666667%;
    flex-grow: 0;
    max-width: 91.666667%;
  }

  .grid-md-12 {
    flex-basis: 100%;
    flex-grow: 0;
    max-width: 100%;
  }
}

@media (min-width: $large-screen-up) {
  .grid-lg-auto {
    flex-basis: auto;
    flex-grow: 0;
    max-width: none;
  }

  .grid-lg-true {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .grid-lg-1 {
    flex-basis: 8.333333%;
    flex-grow: 0;
    max-width: 8.333333%;
  }

  .grid-lg-2 {
    flex-basis: 16.666667%;
    flex-grow: 0;
    max-width: 16.666667%;
  }

  .grid-lg-3 {
    flex-basis: 25%;
    flex-grow: 0;
    max-width: 25%;
  }

  .grid-lg-4 {
    flex-basis: 33.333333%;
    flex-grow: 0;
    max-width: 33.333333%;
  }

  .grid-lg-5 {
    flex-basis: 41.666667%;
    flex-grow: 0;
    max-width: 41.666667%;
  }

  .grid-lg-6 {
    flex-basis: 50%;
    flex-grow: 0;
    max-width: 50%;
  }

  .grid-lg-7 {
    flex-basis: 58.333333%;
    flex-grow: 0;
    max-width: 58.333333%;
  }

  .grid-lg-8 {
    flex-basis: 66.666667%;
    flex-grow: 0;
    max-width: 66.666667%;
  }

  .grid-lg-9 {
    flex-basis: 75%;
    flex-grow: 0;
    max-width: 75%;
  }

  .grid-lg-10 {
    flex-basis: 83.333333%;
    flex-grow: 0;
    max-width: 83.333333%;
  }

  .grid-lg-11 {
    flex-basis: 91.666667%;
    flex-grow: 0;
    max-width: 91.666667%;
  }

  .grid-lg-12 {
    flex-basis: 100%;
    flex-grow: 0;
    max-width: 100%;
  }
}

@media (min-width: $large-screen-max) {
  .grid-xl-auto {
    flex-basis: auto;
    flex-grow: 0;
    max-width: none;
  }

  .grid-xl-true {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .grid-xl-1 {
    flex-basis: 8.333333%;
    flex-grow: 0;
    max-width: 8.333333%;
  }

  .grid-xl-2 {
    flex-basis: 16.666667%;
    flex-grow: 0;
    max-width: 16.666667%;
  }

  .grid-xl-3 {
    flex-basis: 25%;
    flex-grow: 0;
    max-width: 25%;
  }

  .grid-xl-4 {
    flex-basis: 33.333333%;
    flex-grow: 0;
    max-width: 33.333333%;
  }

  .grid-xl-5 {
    flex-basis: 41.666667%;
    flex-grow: 0;
    max-width: 41.666667%;
  }

  .grid-xl-6 {
    flex-basis: 50%;
    flex-grow: 0;
    max-width: 50%;
  }

  .grid-xl-7 {
    flex-basis: 58.333333%;
    flex-grow: 0;
    max-width: 58.333333%;
  }

  .grid-xl-8 {
    flex-basis: 66.666667%;
    flex-grow: 0;
    max-width: 66.666667%;
  }

  .grid-xl-9 {
    flex-basis: 75%;
    flex-grow: 0;
    max-width: 75%;
  }

  .grid-xl-10 {
    flex-basis: 83.333333%;
    flex-grow: 0;
    max-width: 83.333333%;
  }

  .grid-xl-11 {
    flex-basis: 91.666667%;
    flex-grow: 0;
    max-width: 91.666667%;
  }

  .grid-xl-12 {
    flex-basis: 100%;
    flex-grow: 0;
    max-width: 100%;
  }
}
