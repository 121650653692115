@import '@/theme/newVariables.scss';

.footerContainer {
  @media #{$small-and-down} {
    padding-left: 16px;
  }
}

.footerListContainerFirst,
.footerListContainer {
  @media #{$small-and-down} {
    width: 100% !important;
  }
}

.footerListContainer {
  @media #{$small-and-down} {
    margin-top: 0 !important;
  }
}
