@import '../../../theme/newVariables';

.spinnerContainer {
  animation-name: spin;
  animation-duration: 0.7s;
  animation-iteration-count: infinite;
}

// Positions spinner visual in the center of the container
.spinnerWrapper {
  width: 75%;
  height: 75%;

  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

// Creates the spinner visuals
.spinner {
  border-radius: 100%;
  border: solid;
  border-bottom-left-radius: 0%;
  border-color: $az-medium-orange;
  border-bottom-color: transparent;
  border-left-color: transparent;

  width: 100%;
  height: 100%;

  transform: rotate(-45deg);
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
