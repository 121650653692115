@import './newVariables';

// this mixin is only supposed to be used in this file, there are multiple reasons why
// 1. Importing this file also imports all of the css below the mixin, adding to the problem of too much CSS
// 2. If you're adding new mixins, it's not solving the problem because there are more variants out there
// Less lines of code !== better mantainability
@mixin fontStylesDontUseAnywhereElse(
  $fontFamily,
  $fontSize,
  $lineHeight,
  $letterSpacing: normal,
  $textTransform: none,
  $fontWeight: normal,
  $color: null,
  $whiteSpacing: normal
) {
  font-family: $fontFamily;
  font-size: $fontSize;
  letter-spacing: $letterSpacing;
  line-height: $lineHeight;
  text-transform: $textTransform;
  font-weight: $fontWeight;
  white-space: $whiteSpacing;

  @if $color {
    color: $color;
  }
}

@mixin createItlicRules($classNames, $isTitle) {
  $variants: regular, medium, heavy;
  $fontFamilyCond: (
    regular: $font-az-cond-regular-italic,
    medium: $font-az-cond-medium-italic,
    heavy: $font-az-cond-heavy-italic,
  );

  @each $fontClass in $classNames {
    $cnt: index($classNames, $fontClass);

    @each $variant in $variants {
      .az-#{$fontClass}-#{$variant}-italic {
        @extend .az-#{$fontClass}-#{$variant};

        @if $isTitle {
          font-family: map-get($fontFamilyCond, $variant);
          /* stylelint-disable-next-line */
        } @else {
          font-style: italic;
        }
      }
    }
  }
}

@mixin typography {
  .az-display-1-regular {
    $options: (
      fontFamily: $font-az-cond,
      fontSize: $font-size-xxl,
      letterSpacing: 0.021rem,
      lineHeight: 1,
      textTransform: uppercase,
    );

    @include fontStylesDontUseAnywhereElse($options...);
  }

  .az-display-1-medium {
    @extend .az-display-1-regular;

    font-family: $font-az-cond-medium;
  }

  .az-display-1-heavy {
    @extend .az-display-1-regular;

    font-family: $font-az-cond-heavy;
  }

  .az-title-1-regular {
    $options: (
      fontFamily: $font-az-cond,
      fontSize: $font-size-xl,
      letterSpacing: 0.023rem,
      lineHeight: 1,
      textTransform: uppercase,
    );

    @include fontStylesDontUseAnywhereElse($options...);
  }

  .az-title-1-medium {
    @extend .az-title-1-regular;

    font-family: $font-az-cond-medium;
  }

  .az-title-1-heavy {
    @extend .az-title-1-regular;

    font-family: $font-az-cond-heavy;
  }

  .az-title-2-regular {
    $options: (
      fontFamily: $font-az-cond,
      fontSize: $font-size-lg,
      letterSpacing: 0.02rem,
      lineHeight: 1.12,
      textTransform: uppercase,
    );

    @include fontStylesDontUseAnywhereElse($options...);
  }

  .az-title-2-medium {
    @extend .az-title-2-regular;

    font-family: $font-az-cond-medium;
  }

  .az-title-2-heavy {
    @extend .az-title-2-regular;

    font-family: $font-az-cond-heavy;
  }

  .az-title-3-regular {
    $options: (
      fontFamily: $font-az-cond,
      fontSize: $font-size-md,
      letterSpacing: 0.019rem,
      lineHeight: 1.25,
      textTransform: uppercase,
    );

    @include fontStylesDontUseAnywhereElse($options...);
  }

  .az-title-3-medium {
    @extend .az-title-3-regular;

    font-family: $font-az-cond-medium;
  }

  .az-title-3-heavy {
    @extend .az-title-3-regular;

    font-family: $font-az-cond-heavy;
  }

  .az-title-4-regular {
    $options: (
      fontFamily: $font-az-cond,
      fontSize: $font-size-sm,
      letterSpacing: 0.048rem,
      lineHeight: 1.25,
      textTransform: uppercase,
    );

    @include fontStylesDontUseAnywhereElse($options...);
  }

  .az-title-4-medium {
    @extend .az-title-4-regular;

    font-family: $font-az-cond-medium;
  }

  .az-title-4-heavy {
    @extend .az-title-4-regular;

    font-family: $font-az-cond-heavy;
  }

  .az-title-5-regular {
    $options: (
      fontFamily: $font-az-cond,
      fontSize: $font-size-base,
      letterSpacing: 0.038rem,
      lineHeight: 1.25,
      textTransform: uppercase,
    );

    @include fontStylesDontUseAnywhereElse($options...);
  }

  .az-title-5-medium {
    @extend .az-title-5-regular;

    font-family: $font-az-cond-medium;
  }

  .az-title-6-medium {
    $options: (
      fontFamily: $font-az-cond-medium,
      fontSize: $font-size-18,
      letterSpacing: 0.038rem,
      lineHeight: 1.25,
    );
    @include fontStylesDontUseAnywhereElse($options...);
  }

  .az-title-5-heavy {
    @extend .az-title-5-regular;

    font-family: $font-az-cond-heavy;
  }

  // Creates Italic rules for titles
  $classNamesSubTitles: display-1, title-1, title-2, title-3, title-4, title-5;

  @include createItlicRules($classNamesSubTitles, true);

  // Button text
  .az-button-text {
    $options: (
      fontFamily: $font-az-cond,
      fontSize: $font-size-16,
      fontWeight: 500,
      letterSpacing: normal,
      lineHeight: 1.13,
      textTransform: uppercase,
    );

    @include fontStylesDontUseAnywhereElse($options...);
  }

  // Button text super
  .az-button-text-super {
    $options: (
      fontFamily: $font-az-cond-heavy,
      fontSize: $font-size-19,
      letterSpacing: normal,
      lineHeight: 1.13,
      textTransform: uppercase,
    );

    @include fontStylesDontUseAnywhereElse($options...);
  }

  .az-subtitle-1-regular {
    $options: (
      fontFamily: $font-az-helvetica,
      fontSize: $font-size-md,
      letterSpacing: 0.031rem,
      lineHeight: 1.25,
    );

    @include fontStylesDontUseAnywhereElse($options...);
  }

  .az-subtitle-1-medium {
    @extend .az-subtitle-1-regular;

    font-family: $font-az-helvetica-medium;
    font-weight: 500;
  }

  .az-subtitle-1-heavy {
    @extend .az-subtitle-1-regular;

    font-family: $font-az-helvetica-heavy;
    font-weight: bold;
  }

  .az-subtitle-2-regular {
    $options: (
      fontFamily: $font-az-helvetica,
      fontSize: $font-size-sm,
      letterSpacing: 0.045rem,
      lineHeight: 1.25,
    );

    @include fontStylesDontUseAnywhereElse($options...);
  }

  .az-subtitle-2-medium {
    @extend .az-subtitle-2-regular;

    font-family: $font-az-helvetica-medium;
    font-weight: 500;
  }

  .az-subtitle-2-heavy {
    @extend .az-subtitle-2-regular;

    font-family: $font-az-helvetica-heavy;
    font-weight: bold;
  }

  .az-subtitle-3-regular {
    $options: (
      fontFamily: $font-az-helvetica,
      fontSize: $font-size-base,
      letterSpacing: 0.047rem,
      lineHeight: 1.25,
    );

    @include fontStylesDontUseAnywhereElse($options...);
  }

  .az-subtitle-3-medium {
    @extend .az-subtitle-3-regular;

    font-family: $font-az-helvetica-medium;
    font-weight: 500;
  }

  .az-subtitle-3-heavy {
    @extend .az-subtitle-3-regular;

    font-family: $font-az-helvetica-heavy;
    font-weight: bold;
  }

  // Creates Italic rules for subtitles
  $classNamesSubTitles: subtitle-1, subtitle-2, subtitle-3;

  @include createItlicRules($classNamesSubTitles, false);

  .az-body-1-regular {
    $options: (
      fontFamily: $font-az-helvetica,
      fontSize: $font-size-16,
      lineHeight: 1.5,
    );

    @include fontStylesDontUseAnywhereElse($options...);

    & a {
      color: $az-grey-5;
      text-decoration: underline;
    }
  }

  .az-body-1-heavy {
    @extend .az-body-1-regular;

    font-family: $font-az-helvetica-heavy;
    font-weight: bold;
  }

  ul.az-body-1-list {
    @extend .az-body-1-regular;
  }

  .az-spacing-16-left {
    margin-left: $font-size-16;
  }

  a.az-body-1-link {
    @extend .az-body-1-regular;

    color: $az-grey-5;
    text-decoration: underline;

    &:active {
      color: $az-blue;
    }
  }

  .az-body-2-regular {
    $options: (
      fontFamily: $font-az-helvetica,
      fontSize: $font-size-14,
      lineHeight: 1.5,
      color: $az-grey-6,
    );

    @include fontStylesDontUseAnywhereElse($options...);

    & a {
      color: $az-grey-5;
      text-decoration: underline;
    }
  }

  .az-body-2-lighter {
    @extend .az-body-2-regular;
    font-weight: 400;
  }

  .az-body-2-heavy {
    @extend .az-body-2-regular;

    font-weight: bold;
  }

  ul.az-body-2-list {
    @extend .az-body-2-regular;
  }

  .az-body-2-link {
    @extend .az-body-2-regular;

    color: $az-grey-5;
    text-decoration: underline;

    &:active {
      color: $az-blue;
    }
  }

  .az-body-3-regular {
    $options: (
      fontFamily: $font-az-helvetica,
      fontSize: $font-size-10,
      lineHeight: 1.5,
      color: $az-grey-6,
    );

    @include fontStylesDontUseAnywhereElse($options...);

    & a {
      color: $az-grey-5;
      text-decoration: underline;
    }
  }

  .az-body-3-heavy {
    @extend .az-body-3-regular;

    font-weight: bold;
  }

  ul.az-body-3-list {
    @extend .az-body-3-regular;
  }

  a.az-body-3-link {
    @extend .az-body-3-regular;

    color: $az-grey-5;
    text-decoration: underline;

    &:active {
      color: $az-blue;
    }
  }

  // Captions
  .az-caption {
    $options: (
      fontFamily: $font-az-helvetica,
      fontSize: $font-size-12,
      letterSpacing: 0.4px,
      lineHeight: 1.333,
    );

    @include fontStylesDontUseAnywhereElse($options...);

    & a {
      color: inherit;
      text-decoration: underline;
    }
  }

  // Bold
  .az-bold {
    font-weight: bold;
  }

  // Semi-bold
  .az-semi-bold {
    font-weight: 500;
  }

  // Overline
  .az-overline {
    $options: (
      fontFamily: $font-az-helvetica,
      fontSize: $font-size-12,
      letterSpacing: 1.5px,
      lineHeight: 1.333,
      textTransform: uppercase,
    );

    @include fontStylesDontUseAnywhereElse($options...);
  }

  .az-overline-nav {
    $options: (
      fontFamily: $font-az-helvetica,
      fontSize: $font-size-16,
      letterSpacing: 0.75px,
      lineHeight: 1.5,
      fontWeight: 900,
      color: $az-black,
    );

    @include fontStylesDontUseAnywhereElse($options...);
  }
}

@font-face {
  font-family: 'AutoZoneCond';
  src: url('/fonts/AutoZoneCond.eot');
  src: url('/fonts/AutoZoneCond.eot?iefix') format('embedded-opentype'),
    url('/fonts/AutoZoneCond.woff2') format('woff2'), url('/fonts/AutoZoneCond.woff') format('woff'),
    url('/fonts/AutoZoneCond.ttf') format('truetype'),
    url('/fonts/AutoZoneCond.svg#AutoZoneCond') format('svg');
  font-weight: 300 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'AutoZoneCond-Heavy';
  src: url('/fonts/AutoZoneCond-Heavy.eot');
  src: url('/fonts/AutoZoneCond-Heavy.eot?iefix') format('embedded-opentype'),
    url('/fonts/AutoZoneCond-Heavy.woff2') format('woff2'),
    url('/fonts/AutoZoneCond-Heavy.woff') format('woff'),
    url('/fonts/AutoZoneCond-Heavy.ttf') format('truetype'),
    url('/fonts/AutoZoneCond-Heavy.svg#AutoZoneCond-Heavy') format('svg');
  font-weight: 700 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'AutoZoneCond-HeavyIt';
  src: url('/fonts/AutoZoneCond-HeavyIt.eot');
  src: url('/fonts/AutoZoneCond-HeavyIt.eot?iefix') format('embedded-opentype'),
    url('/fonts/AutoZoneCond-HeavyIt.woff2') format('woff2'),
    url('/fonts/AutoZoneCond-HeavyIt.woff') format('woff'),
    url('/fonts/AutoZoneCond-HeavyIt.ttf') format('truetype'),
    url('/fonts/AutoZoneCond-HeavyIt.svg#AutoZoneCond-HeavyIt') format('svg');
  font-weight: 700 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'AutoZoneCond-Italic';
  src: url('/fonts/AutoZoneCond-Italic.eot');
  src: url('/fonts/AutoZoneCond-Italic.eot?iefix') format('embedded-opentype'),
    url('/fonts/AutoZoneCond-Italic.woff2') format('woff2'),
    url('/fonts/AutoZoneCond-Italic.woff') format('woff'),
    url('/fonts/AutoZoneCond-Italic.ttf') format('truetype'),
    url('/fonts/AutoZoneCond-Italic.svg#AutoZoneCond-Italic') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'AutoZoneCond-Medium';
  src: url('/fonts/AutoZoneCond-Medium.eot');
  src: url('/fonts/AutoZoneCond-Medium.eot?iefix') format('embedded-opentype'),
    url('/fonts/AutoZoneCond-Medium.woff2') format('woff2'),
    url('/fonts/AutoZoneCond-Medium.woff') format('woff'),
    url('/fonts/AutoZoneCond-Medium.ttf') format('truetype'),
    url('/fonts/AutoZoneCond-Medium.svg#AutoZoneCond-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'AutoZoneCond-MediumIt';
  src: url('/fonts/AutoZoneCond-MediumIt.eot');
  src: url('/fonts/AutoZoneCond-MediumIt.eot?iefix') format('embedded-opentype'),
    url('/fonts/AutoZoneCond-MediumIt.woff2') format('woff2'),
    url('/fonts/AutoZoneCond-MediumIt.woff') format('woff'),
    url('/fonts/AutoZoneCond-MediumIt.ttf') format('truetype'),
    url('/fonts/AutoZoneCond-MediumIt.svg#AutoZoneCond-MediumIt') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: AutoZoneCondensed;
  src: url('/fonts/AutoZoneCond.eot');
  src: url('/fonts/AutoZoneCond.eot?iefix') format('embedded-opentype'),
    url('/fonts/AutoZoneCond.woff2') format('woff2'), url('/fonts/AutoZoneCond.woff') format('woff'),
    url('/fonts/AutoZoneCond.ttf') format('truetype'),
    url('/fonts/AutoZoneCond.svg#AutoZoneCond') format('svg');
  font-weight: 300 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: AutoZoneCondensed;
  src: url('/fonts/AutoZoneCond-Italic.eot');
  src: url('/fonts/AutoZoneCond-Italic.eot?iefix') format('embedded-opentype'),
    url('/fonts/AutoZoneCond-Italic.woff2') format('woff2'),
    url('/fonts/AutoZoneCond-Italic.woff') format('woff'),
    url('/fonts/AutoZoneCond-Italic.ttf') format('truetype'),
    url('/fonts/AutoZoneCond-Italic.svg#AutoZoneCond-Italic') format('svg');
  font-weight: 300 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: AutoZoneCondensed;
  src: url('/fonts/AutoZoneCond-Medium.eot');
  src: url('/fonts/AutoZoneCond-Medium.eot?iefix') format('embedded-opentype'),
    url('/fonts/AutoZoneCond-Medium.woff2') format('woff2'),
    url('/fonts/AutoZoneCond-Medium.woff') format('woff'),
    url('/fonts/AutoZoneCond-Medium.ttf') format('truetype'),
    url('/fonts/AutoZoneCond-Medium.svg#AutoZoneCond-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: AutoZoneCondensed;
  src: url('/fonts/AutoZoneCond-MediumIt.eot');
  src: url('/fonts/AutoZoneCond-MediumIt.eot?iefix') format('embedded-opentype'),
    url('/fonts/AutoZoneCond-MediumIt.woff2') format('woff2'),
    url('/fonts/AutoZoneCond-MediumIt.woff') format('woff'),
    url('/fonts/AutoZoneCond-MediumIt.ttf') format('truetype'),
    url('/fonts/AutoZoneCond-MediumIt.svg#AutoZoneCond-MediumIt') format('svg');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: AutoZoneCondensed;
  src: url('/fonts/AutoZoneCond-Heavy.eot');
  src: url('/fonts/AutoZoneCond-Heavy.eot?iefix') format('embedded-opentype'),
    url('/fonts/AutoZoneCond-Heavy.woff2') format('woff2'),
    url('/fonts/AutoZoneCond-Heavy.woff') format('woff'),
    url('/fonts/AutoZoneCond-Heavy.ttf') format('truetype'),
    url('/fonts/AutoZoneCond-Heavy.svg#AutoZoneCond-Heavy') format('svg');
  font-weight: 700 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: AutoZoneCondensed;
  src: url('/fonts/AutoZoneCond-HeavyIt.eot');
  src: url('/fonts/AutoZoneCond-HeavyIt.eot?iefix') format('embedded-opentype'),
    url('/fonts/AutoZoneCond-HeavyIt.woff2') format('woff2'),
    url('/fonts/AutoZoneCond-HeavyIt.woff') format('woff'),
    url('/fonts/AutoZoneCond-HeavyIt.ttf') format('truetype'),
    url('/fonts/AutoZoneCond-HeavyIt.svg#AutoZoneCond-HeavyIt') format('svg');
  font-weight: 700 800;
  font-style: italic;
  font-display: swap;
}
