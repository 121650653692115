.root {
  color: inherit;
  border: 0;
  cursor: pointer;
  margin: 0;
  display: inline-flex;
  padding: 0;
  position: relative;
  align-items: center;
  user-select: none;
  border-radius: 0;
  vertical-align: middle;
  -moz-appearance: none;
  justify-content: center;
  text-decoration: none;
  background-color: transparent;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;

  &::-moz-focus-inner {
    border-style: none;
  }

  @media print {
    -webkit-print-color-adjust: exact;
  }
}

.disabled {
  cursor: default;
  pointer-events: none;
}
