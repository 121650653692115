.pcaitem {
  border-bottom: 1px solid #e6e6e6 !important;
  background: #ffffff;
  padding: 12px 16px !important;
  font-family: 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-size: 14px;
  line-height: 20px !important;
}

.pca .pcaitem {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.pcaautocomplete.pcatext {
  border-width: 0px 1px 1px 1px !important;
}
