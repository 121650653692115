@import '../../theme/colors.module.scss';

.root {
  background-color: $az-white;
  color: $az-black;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

/* Styles applied to the root element if `square={false}`. */
.rounded {
  border-radius: 4px;
}

/* Styles applied to the root element if `variant="outlined"`. */
.outlined {
  border: 1px solid $az-grey-15;
}

.elevation0 {
  box-shadow: none;
}

.elevation1 {
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12);
}

.elevation2 {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.elevation6 {
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14),
    0 1px 18px 0 rgba(0, 0, 0, 0.12);
}
.elevation4 {
  box-shadow: 0px 3px 5px 0px rgba(32, 33, 36, 0.12), 0px 6px 10px 0 rgba(32, 33, 36, 0.14),
    0px 1px 18px 0px rgba(32, 33, 36, 0.2);
}
