@import '../../../theme/newVariables';

.chatSliderContainer {
  position: fixed;
  top: $az-percent-65;
  right: 38px;
  z-index: 1;
  width: $az-spacing-1;
  height: $az-spacing-1;

  @media #{$extra-large-and-down} {
    display: none;
  }

  @media screen and (max-height: 900px) {
    top: $az-percent-75;
  }
}

.chatSliderImgContent {
  top: $az-spacing-1;
  position: absolute;
  z-index: 1;
  width: $az-spacing-45;
  height: $az-spacing-151;
  background-color: transparent;
  border-width: $az-spacing-1;
  border-color: $az-black;
  border-style: solid;
  border: none;
  display: block;
  cursor: pointer;
}

.liveChat {
  position: absolute;
  width: $az-spacing-45;
  height: $az-spacing-151;
  overflow: hidden;
  display: block;

  @media print {
    display: none;
  }
}

.sr {
  position: absolute;
  width: $az-spacing-1;
  height: $az-spacing-1;
  overflow: hidden;
  top: auto;
}

.chatBtn {
  width: $az-spacing-37;
}
