@import '../../theme/newVariables';

.hide {
  display: none;
  border-bottom-right-radius: $az-spacing-4xs;
  border-bottom-left-radius: $az-spacing-4xs;
}

.searchSuggestions {
  text-transform: uppercase;
  font-size: $font-size-12;
  text-align: $ldirection;
  font-weight: 500;
  margin: $az-spacing-4xs $az-spacing-zero $az-spacing-xxs;
  color: $az-grey-14;
  letter-spacing: 1.5px;
}

.fitsYourVehicle {
  text-transform: uppercase;
  font-size: $font-size-12;
  text-align: $ldirection;
  font-weight: 500;
  margin: $az-spacing-xxs $az-spacing-zero;
  color: $az-grey-14;
  letter-spacing: 1.5px;

  @media #{$small-and-down} {
    margin: 0 0 $az-spacing-4xs;
  }
}

.withoutTopMargin {
  margin-top: 0;
}

.productSuggestions {
  margin-left: -1px;
  .productImage {
    width: $az-spacing-47;
    font-size: 0;
    display: flex;
    align-items: center;
  }
  .productSuggestion {
    @media #{$small-and-down} {
      padding: 0;
    }
    margin-bottom: $az-spacing-xxs;
    height: auto;
  }
}

.suggestionListDiv {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  font-family: $azFallbackFonts;
  font-size: $font-size-12;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: $az-spacing-xxs;
  letter-spacing: 1.5px;
  padding: $az-spacing-zero $az-spacing-xxs $az-spacing-zero;
  gap: $az-spacing-10;
  @media #{$small-and-down} {
    padding-bottom: 0;
  }
}
.popularSearchesContainer {
  @media #{$medium-and-up} {
    padding-left: $az-spacing-4xs;
    padding-right: $az-spacing-5xs;
  }
}
.suggestionsList {
  margin: $az-spacing-zero;

  li {
    padding: $az-spacing-zero $az-spacing-4xs;
    @media #{$small-and-down} {
      padding: $az-spacing-zero $az-spacing-xxs;
    }
  }
  &.suggestionsListMobile {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow: scroll hidden;
    column-gap: $az-spacing-10;
    padding-top: $az-spacing-xxxs;
    padding-bottom: $az-spacing-xxxs;
    &.hiddenOverflow {
      overflow-y: hidden;
    }
  }
  &.withMostPopularSuggestions {
    padding-left: $az-spacing-xxs;
    padding-top: $az-spacing-5xs;
    li {
      width: $az-spacing-282;
      height: $az-spacing-71;
      margin: auto;
    }
  }
}

.infoIcon {
  @media #{$extra-large-and-down} {
    margin-left: $az-spacing-5xs;
  }
}

.activeItem {
  background-color: $az-grey-1;
}

.suggestionItem {
  display: flex;
  align-items: $cdirection;
  cursor: pointer;
  font-size: $font-size-14;
  &:hover {
    background-color: $az-grey-1;
  }

  button,
  button:active {
    border: none;
    background-color: transparent;
    padding: $az-spacing-zero;
    width: $az-percent-100;
    text-align: $ldirection;
    color: $az-black;
  }

  .matchedText {
    font-weight: 400;
  }
}
.suggestionItemHeight {
  height: $az-spacing-44;
}

.active {
  padding: $az-spacing-4xs 0;
  border-top: $az-spacing-zero;
  z-index: 100;
  border: none;
  display: block;
  background-color: $az-white;
  width: $az-percent-100;
  border-radius: $az-spacing-4xs;

  @media #{$extra-large-and-down} {
    border: none;
    height: auto;
  }

  @media #{$medium-only} {
    padding-bottom: $az-spacing-xxs;
  }
}
