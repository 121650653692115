@import '../../../theme/newVariables';

.footerMiddleContainer {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: $az-spacing-s;
  width: $az-percent-100;

  @media #{$small-and-down} {
    margin: $az-spacing-zero;

    hr {
      width: $az-percent-100;
      margin-top: $az-spacing-s;
    }
  }
}

.columnsContainer {
  margin-top: $az-spacing-s;
  margin-right: $az-spacing-4xl;
}

.footerMiddleLinksColumnContainer {
  display: flex;
  width: $az-percent-100;
  justify-content: space-between;
  margin: $az-spacing-4xs;

  &:lang(pt) {
    justify-content: flex-start;
  }

  @media #{$medium-and-down} {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  @media #{$small-and-down} {
    display: none;
  }
}

.footerMiddleLinksColumnContainerBottom {
  display: flex;
  width: $az-percent-100;
  justify-content: space-between;
}

.linkColumnContainer {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  min-width: auto;

  @media #{$medium-and-down} {
    margin-top: $az-spacing-s;
  }

  @media #{$small-and-down} {
    margin-right: $az-spacing-zero;
  }

  .mobileLinkColumnHr {
    margin: $az-spacing-s $az-spacing-zero $az-spacing-zero $az-spacing-zero;
  }
}

.otherAutoZoneSitesContainer {
  display: flex;
  flex-direction: row;
  align-self: center;
  justify-self: center;
  text-align: center;
  margin-top: $az-spacing-s;
  margin-right: $az-spacing-zero;

  @media #{$small-and-down} {
    display: none;
  }
}

.linkColumTitle {
  font-family: $font-az-cond-heavy;
  font-size: $font-size-base;
  font-weight: 900;
  line-height: $font-size-sm;
  letter-spacing: $az-spacing-three-fourths;
  color: $az-white !important;

  @media #{$small-and-down} {
    display: block;
    font-size: $font-size-19;
  }
}

.otherAutoZoneSitesLinkColumTitle {
  @extend .linkColumTitle;

  margin-right: $az-spacing-zero;
  margin-bottom: $az-spacing-14;
}

.linkItem {
  font-family: $font-az-helvetica;
  font-size: $font-size-xxs;
  font-weight: 500;
  line-height: $az-spacing-s;
  color: $az-grey-3;
  margin-top: $az-spacing-14;

  @media #{$small-and-down} {
    font-size: $font-size-18;
    margin-top: $az-spacing-s;
  }
}

.otherAutoZoneSitesLinkItem {
  @extend .linkItem;
  margin: $az-spacing-zero $az-spacing-xs;
}

.otherAutoZoneSitesLinkItemsContainer {
  display: flex;
  justify-items: center;
  align-items: center;
  width: $az-percent-100;
  padding-right: $az-spacing-s;
  flex-flow: row wrap;
  margin-bottom: $az-spacing-zero;

  .stick {
    height: $az-spacing-xxs;
    width: $az-spacing-2;
    background-color: $az-grey-5;
    margin-top: $az-spacing-5xs;
  }
}

.specialWidth {
  width: $az-percent-70;
}

.footerMiddleMobileLinksColumnContainer {
  display: none;

  @media #{$small-and-down} {
    display: flex;
    flex-direction: column;
    width: $az-percent-100;
    margin-top: $az-spacing-zero;
  }
}

.linksColumTitleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.linksContainer {
  display: flex;
  flex-direction: column;
  transition: all 1s;
  height: auto;
  max-height: $az-percent-100;
  margin: $az-spacing-zero;
}

.chevron {
  transition: transform 0.2s;
}

.chevronDown {
  transform: rotate(360deg);
}

.chevronUp {
  transform: rotate(180deg);
}

.showLinks {
  display: flex;
}

.hideLinks {
  max-height: $az-spacing-zero;
  display: none;
}

.otherSites {
  align-self: center;
}

.footerLinkDownArrow {
  padding: $az-spacing-zero;
}

.listItems {
  display: flex;
  flex-direction: row;
  align-self: center;
  justify-self: center;
  text-align: center;
}
