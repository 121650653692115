@import '../../theme/newVariables';

.root {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.gutters {
  padding-left: 16px;
  padding-right: 16px;
}
