@import '../../../theme/newVariables.scss';

.smartbanner {
  border-bottom: $az-spacing-2 solid $az-grey-2;
  display: flex;
  align-items: center;
  z-index: 1503;
  overflow-x: hidden;
  width: 100%;
  min-height: $az-spacing-90;
  background: $az-grey-10;
  font-family: Helvetica, sans, sans-serif;

  &--scrolled {
    position: fixed;
  }

  &__exit {
    display: block;
    margin-left: $az-spacing-9;
    border: $az-spacing-zero;
    background: unset;
    text-align: center;
    padding: 0;
  }

  &__icon {
    margin-left: $az-spacing-9;
    border-radius: $az-spacing-15;
  }

  &__info {
    margin-left: $az-spacing-xs;
    display: flex;
    width: 60%;
    min-height: $az-spacing-72;
    align-items: center;
    color: $az-dark-black;

    &__title {
      font-size: $az-spacing-14;
    }

    &__author,
    &__price {
      font-size: $az-spacing-xxxs;
    }
  }

  &__button {
    margin-right: $az-spacing-10;
    z-index: 1;
    display: block;
    padding: $az-spacing-zero $az-spacing-10;
    min-width: 10%;
    border-radius: $az-spacing-5;
    background: $az-grey-10;
    color: $az-blue-2;
    font-size: $az-spacing-18;
    text-align: center;
    text-decoration: none;

    &__label {
      text-align: center;
    }
  }

  @media #{$medium-and-up} {
    display: none;
  }

  /** Android styles **/
  &.smartbanner--android {
    background: $az-grey-13
      url('data:image/gif;base64,R0lGODlhCAAIAIABAFVVVf///yH5BAEHAAEALAAAAAAIAAgAAAINRG4XudroGJBRsYcxKAA7');
    box-shadow: inset $az-spacing-zero $az-spacing-5xs $az-spacing-zero $az-green;

    .smartbanner__exit {
      width: $az-spacing-xs;
      height: $az-spacing-xs;
      border-radius: $az-spacing-14;
      background: #1c1e21;
      box-shadow: $az-spacing-zero $az-spacing-1 $az-spacing-2 rgba(0, 0, 0, 0.8) inset,
        $az-spacing-zero $az-spacing-1 $az-spacing-1 rgba(255, 255, 255, 0.3);
      color: $az-grey-12;
      text-shadow: $az-spacing-zero $az-spacing-1 $az-spacing-1 $az-dark-black;
      padding: $az-spacing-1 $az-spacing-1 $az-spacing-zero $az-spacing-1;

      svg {
        font-size: $az-spacing-18;
      }

      &::before,
      &::after {
        width: $az-spacing-2;
        height: $az-spacing-11;
        background: $az-grey-12;
      }

      &:active,
      &:hover {
        color: $az-white-5;
      }
    }

    .smartbanner__info {
      color: $az-white-4;
      text-shadow: $az-spacing-zero $az-spacing-1 $az-spacing-2 $az-dark-black;
      margin-top: $az-spacing-5;

      &__title {
        color: $az-white;
        font-weight: bold;
      }
    }

    .smartbanner__button {
      padding: $az-spacing-zero;
      min-width: 14%;
      border-radius: $az-spacing-zero;
      background: $az-blue-5;
      background: linear-gradient(to bottom, $az-blue-5, $az-blue-4);
      box-shadow: $az-spacing-zero $az-spacing-zero $az-spacing-zero $az-spacing-1 $az-black-2,
        $az-spacing-zero $az-spacing-zero $az-spacing-zero $az-spacing-2 $az-white-3;
      color: $az-white-2;
      font-size: $az-spacing-14;
      font-weight: bold;

      &:active,
      &:hover {
        background: $az-blue-3;
      }

      &__label {
        line-height: $az-spacing-s;
        text-align: center;
        text-shadow: none;
        text-transform: none;
      }
    }
  }
}
