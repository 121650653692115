@import '../../theme/newVariables';

@media #{$small-and-down} {
  .App {
    overflow-x: clip;
  }
}

.App,
.checkoutApp,
.checkoutAppV3 {
  position: relative;
  .appNavigation {
    .autoZoneLogo {
      width: 238px;
    }

    .storeContainer {
      width: 42px;
      font-family: $font-az-helvetica;
      font-size: 14px;

      img {
        width: 24px;
        vertical-align: top;
      }
    }

    .storeText {
      font-family: $font-az-helvetica;
      font-size: 14px;
      width: 146px;
      margin: 0 32px 0 6px;
    }

    .myAccount {
      font-family: $font-az-helvetica;
      font-size: 14px;
      color: $az-grey-5;
      width: 90px;
      margin-right: 18px;

      img {
        width: 24px;
        vertical-align: top;
      }
    }

    .contactUs {
      font-family: $font-az-helvetica;
      font-size: 14px;
      color: $az-grey-5;
      width: 85px;

      img {
        width: 24px;
        vertical-align: top;
      }
    }

    .search {
      display: flex;
      align-items: center;
      justify-content: center;

      input {
        width: 531px;
        height: 52px;
        border: solid 1px $az-grey-3;
        margin: auto;
        box-sizing: border-box;
        padding: 17px 32px;
      }

      input::placeholder {
        font-size: 16px;
        font-family: $font-az-helvetica;
        color: $az-grey-4;
      }
    }

    .logoItem {
      width: 15%;
    }

    .searchItem {
      width: 65%;
    }

    .cartContainer {
      cursor: pointer;
    }

    .cart {
      width: 60px;
      font-size: 14px;
      color: $az-grey-6;

      img {
        width: 30px;
      }
    }

    .mobileLogo {
      width: 59px;
    }

    .searchIcon {
      width: 100%;
      vertical-align: middle;
    }

    .searchDesktop {
      position: relative;
    }

    .topContainer {
      display: flex;
      justify-content: space-between;
    }

    .vehicleContainer {
      cursor: pointer;
    }
  }
}

.checkoutAppV3 {
  min-width: $az-spacing-240;
}

@media #{$extra-large-and-down} {
  .App,
  .checkoutApp,
  .checkoutAppV3 {
    .appNavigation {
      .myAccountContent,
      .autoZoneLogo,
      .searchDesktop,
      .addVehicleText,
      .navAutoZone {
        display: none;
      }

      .rowAuto {
        padding: 9px 16px;
      }

      .vehicleContainer {
        display: flex;
        flex-flow: column;
        align-items: center;
        cursor: pointer;
        height: 36px;
      }

      .cartContainer {
        display: flex;
        flex-flow: column;
        align-items: center;
        margin-left: 15px;
        cursor: pointer;
      }

      .vehicleText {
        font-size: 8px;
        margin-top: -4px;
      }

      .vehicleTextMob {
        margin-top: -4px;
        font-family: $font-az-helvetica;
      }

      .menuContainer {
        display: flex;
        flex-flow: column;
        align-items: center;
        margin-left: 0;
      }

      .storeText,
      .storeContainer {
        font-size: 11px;
      }

      .searchMobile {
        height: 0;
      }

      .search {
        input {
          height: 30px;
          border: solid 1px $az-grey-2;
          padding: 6px 2px;
          background-color: $az-white;
        }
      }

      .topContainer {
        width: 100%;
      }
    }
  }
}

@media #{$extra-large-and-up} {
  .App,
  .checkoutApp,
  .checkoutAppV3 {
    .appNavigation {
      .vehicleTextMob,
      .searchMobile,
      .menuContainer {
        display: none;
      }

      .addVehicleText {
        font-size: 12px;
      }
    }
  }
}

@media #{$medium-only} {
  .App {
    overflow-x: clip;
  }
}

.fullWidth {
  max-width: 100%;
}
